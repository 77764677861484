import { Doughnut } from "react-chartjs-2";

const TodaysVisits = ({ todaysCustomers, todaysNewCustomers }) => {
  const retutningCustomerLength =
    todaysCustomers.length - todaysNewCustomers.length;

  const dataPie = {
    labels: [
      `new: ${todaysNewCustomers.length}`,
      `returning: ${retutningCustomerLength}`,
    ],
    datasets: [
      {
        label: "My First Dataset",
        data: [todaysNewCustomers.length, retutningCustomerLength],
        backgroundColor: ["#7FC2C9", "#8498A5"],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <>
      <Doughnut data={dataPie} />
    </>
  );
};

export default TodaysVisits;
