import React, { useState } from "react";
import { API } from "aws-amplify";
import { ToastContainer, toast } from "react-toastify";
import styles from "../../styles/Forms.module.css";
import { createUser } from "../../graphql/mutations";
import preventEnterSubmit from "./util/preventEnterSubmit";
import { NumericFormat } from "react-number-format";

const UserForm = ({ userData, listOfPins }) => {
  //Admin Form States
  const [userPinAdmin, setUserPinAdmin] = useState("");
  const [userPinVerifyAdmin, setUserPinVerifyAdmin] = useState("");
  const [adminFormExpanded, setAdminFormExpanded] = useState(false);
  //Employee User Form States
  const [userName, setUserName] = useState("");
  const [userPin, setUserPin] = useState("");
  const [userPinVerify, setUserPinVerify] = useState("");
  const [adminSign, setAdminSign] = useState("");
  const [userformExpanded, setUserFormExpanded] = useState(false);

  const [allUsers, setAllUsers] = useState([]);
  fetch(userData).then(() => setAllUsers(userData));
  const adminNum = allUsers.map((e) => e.name).indexOf("Admin");
  const adminPin = adminNum > -1 ? allUsers[adminNum].pin : "";

  const adminFormToggler = () => {
    setUserPinAdmin("");
    setUserPinVerifyAdmin("");
    adminFormExpanded === true
      ? setAdminFormExpanded(false)
      : setAdminFormExpanded(true);
  };

  const userFormToggler = () => {
    setUserName("");
    setUserPin("");
    setUserPinVerify("");
    setAdminSign("");
    userformExpanded === true
      ? setUserFormExpanded(false)
      : setUserFormExpanded(true);
  };

  const onClearAdmin = () => {
    document.getElementById("registration-admin-user-form").reset();
    setUserPinVerifyAdmin("");
    setAdminFormExpanded("");
  };
  const onClearUser = () => {
    document.getElementById("registration-employee-user-form").reset();
    setUserName("");
    setUserPin("");
    setUserPinVerify("");
    setAdminSign("");
  };

  const digitCounter = (myNumber) => {
    const dightString = String(myNumber);
    return dightString.length;
  };

  const onSubmitAdmin = () => {
    if (digitCounter(userPinAdmin) < 4) {
      toast.error("Pin must have at least 4 digits");
    } else if (
      userPinAdmin === userPinVerifyAdmin &&
      digitCounter(userPinAdmin) >= 4
    ) {
      try {
        API.graphql({
          query: createUser,
          variables: {
            input: {
              name: "Admin",
              pin: userPinAdmin,
            },
          },
        });
        onClearAdmin();
        toast.success("Multi-User Mode Activated!");
      } catch (error) {
        console.error("error occured", error);
      }
    } else {
      toast.error("Pin does not match.");
    }
  };

  // console.log(listOfPins);

  const onSubmitUser = () => {
    if (allUsers.map((e) => e.name).indexOf(userName) !== -1) {
      toast.error("User Already Exist");
    } else if (userName === "" || userPin === "" || userPinVerify === "") {
      toast.error("Fill in all Input");
    } else if (userPin !== userPinVerify) {
      toast.error("Pin does not match");
    } else if (adminSign !== adminPin) {
      toast.error("Wrong Admin Pin");
    } else if (listOfPins.indexOf(userPin) !== -1) {
      toast.error("This pin already exist");
    } else if (
      listOfPins.indexOf(userPin) === -1 &&
      allUsers.map((e) => e.name).indexOf(userName) === -1 &&
      userName !== "" &&
      userPin !== "" &&
      userPin === userPinVerify &&
      adminSign === adminPin
    ) {
      try {
        API.graphql({
          query: createUser,
          variables: {
            input: {
              name: userName,
              pin: userPin,
            },
          },
        });
        onClearUser();
        setUserFormExpanded(false);
        toast.success("Employee User Added");
      } catch (error) {
        console.error("error occured", error);
      }
    } else {
      toast.error("Error");
    }
  };

  const onDelete = () => {};

  return (
    <>
      {/* FIRST TIME ADMIN FORM ACTIVATION SET UP */}
      {userData.length === 0 ? (
        <>
          <div class="contentContainer">
            <div className={styles.expand}>
              <div>
                <h3>User Form</h3>
                <div>Create pin for employees</div>
              </div>
              <div className="cursorPointer">
                <div className="iconPrimary" onClick={() => adminFormToggler()}>
                  {adminFormExpanded === true ? (
                    <i class="fa-solid fa-angle-down"></i>
                  ) : (
                    <i class="fa-solid fa-angle-right"></i>
                  )}
                </div>
              </div>
            </div>
            {adminFormExpanded === true ? (
              <>
                <form id="registration-admin-user-form">
                  <div>
                    <h4>Create Admin User</h4>
                    <div className={styles.textInputContainer}>
                      <div>Name</div>
                      <div>
                        <input
                          type="text"
                          value="Admin"
                          autocomplete="new-password"
                        />
                      </div>
                    </div>
                    <div className={styles.textInputContainer}>
                      <div>Pin (min 4, max 8 numbers)</div>
                      <div>
                        {/* <input
                          onKeyUp={(e) => preventEnterSubmit(e)}
                          type="password"
                          placeholder="****"
                          maxlength="4"
                          autocomplete="new-password"
                          onChange={(e) => setUserPinAdmin(e.target.value)}
                        /> */}
                        <NumericFormat
                          allowLeadingZeros
                          onKeyUp={(e) => preventEnterSubmit(e)}
                          type="password"
                          format="####"
                          mask="_"
                          placeholder="****"
                          allowEmptyFormatting
                          minlength="4"
                          maxlength="8"
                          autocomplete="new-password"
                          onChange={(e) => setUserPinAdmin(e.target.value)}
                          value={userPinAdmin}
                        />
                      </div>
                    </div>
                    <div className={styles.textInputContainer}>
                      <div>Repeat Pin</div>
                      <div>
                        {/* <input
                          onKeyUp={(e) => preventEnterSubmit(e)}
                          type="password"
                          placeholder="****"
                          maxlength="4"
                          autocomplete="new-password"
                          onChange={(e) =>
                            setUserPinVerifyAdmin(e.target.value)
                          }
                        /> */}
                        <NumericFormat
                          allowLeadingZeros
                          onKeyUp={(e) => preventEnterSubmit(e)}
                          type="password"
                          format="####"
                          mask="_"
                          placeholder="****"
                          allowEmptyFormatting
                          minlength="4"
                          maxlength="8"
                          autocomplete="new-password"
                          onChange={(e) =>
                            setUserPinVerifyAdmin(e.target.value)
                          }
                          value={userPinVerifyAdmin}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                {/* Submit Section */}
                <div className={styles.divider}></div>
                <div className={styles.formBtnContainer}>
                  <div
                    className={styles.formBtnReset}
                    onClick={() => onClearAdmin()}
                  >
                    Clear
                  </div>
                  <button
                    className={styles.formBtnSubmit}
                    onClick={() => onSubmitAdmin()}
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
      {/* LIST OF USERS AFTER THE ADMIN FORM HAS BEEN SUBMITTED */}
      {userData.length > 0 ? (
        <>
          <div class="contentContainer">
            <div className={styles.expand}>
              <div>
                <h3>User Form</h3>
                <div>Create pin for employees</div>
              </div>
              <div className="cursorPointer">
                <div className="iconPrimary" onClick={() => userFormToggler()}>
                  {userformExpanded === true ? (
                    <i class="fa-solid fa-angle-down"></i>
                  ) : (
                    <i class="fa-solid fa-angle-right"></i>
                  )}
                </div>
              </div>
            </div>
            {userformExpanded === true ? (
              <>
                <form id="registration-employee-user-form">
                  <div>
                    <h4>Create Employee User</h4>
                    <div className={styles.textInputContainer}>
                      <div>Name</div>
                      <div>
                        <input
                          onKeyUp={(e) => preventEnterSubmit(e)}
                          type="text"
                          placeholder="John"
                          onChange={(e) => setUserName(e.target.value)}
                          autocomplete="new-password"
                        />
                      </div>
                    </div>
                    <div className={styles.textInputContainer}>
                      <div>Pin min 4, max 8 numbers</div>
                      <div>
                        {/* <input
                          onKeyUp={(e) => preventEnterSubmit(e)}
                          type="password"
                          placeholder="****"
                          autocomplete="new-password"
                          onChange={(e) => setUserPin(e.target.value)}
                        /> */}
                        <NumericFormat
                          allowLeadingZeros
                          onKeyUp={(e) => preventEnterSubmit(e)}
                          type="password"
                          format="####"
                          mask="_"
                          placeholder="****"
                          allowEmptyFormatting
                          minlength="4"
                          maxlength="8"
                          autocomplete="new-password"
                          onChange={(e) => setUserPin(e.target.value)}
                          value={userPin}
                        />
                      </div>
                    </div>
                    <div className={styles.textInputContainer}>
                      <div>Repeat Pin</div>
                      <div>
                        {/* <input
                          onKeyUp={(e) => preventEnterSubmit(e)}
                          type="password"
                          placeholder="****"
                          autocomplete="new-password"
                          onChange={(e) => setUserPinVerify(e.target.value)}
                        /> */}
                        <NumericFormat
                          allowLeadingZeros
                          onKeyUp={(e) => preventEnterSubmit(e)}
                          type="password"
                          format="####"
                          mask="_"
                          placeholder="****"
                          allowEmptyFormatting
                          minlength="4"
                          maxlength="8"
                          autocomplete="new-password"
                          onChange={(e) => setUserPinVerify(e.target.value)}
                          value={userPinVerify}
                        />
                      </div>
                    </div>
                    {/* <div className={styles.textInputContainer}>
                    <div>Admin Pin (Required)</div>
                    <div>
                      <input
                        onKeyUp={(e) => preventEnterSubmit(e)}
                        type="password"
                        placeholder="****"
                        autocomplete="new-password"
                        onChange={(e) => setAdminSign(e.target.value)}
                      />
                    </div>
                  </div> */}

                    <div className={styles.divider}></div>
                    <div className={styles.pinInputContainer}>
                      <div>
                        <i class="fa-solid fa-key"></i> Admin Pin:
                      </div>
                      <div>
                        {/* <input
                          onChange={(e) => setAdminSign(e.target.value)}
                          onKeyUp={(e) => preventEnterSubmit(e)}
                          className={styles.pinInput}
                          placeholder="****"
                        /> */}
                        <NumericFormat
                          allowLeadingZeros
                          className={styles.pinInput}
                          onKeyUp={(e) => preventEnterSubmit(e)}
                          type="password"
                          format="####"
                          mask="_"
                          placeholder="****"
                          allowEmptyFormatting
                          minlength="4"
                          maxlength="8"
                          autocomplete="new-password"
                          onChange={(e) => setAdminSign(e.target.value)}
                          value={adminSign}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                {/* Submit Section */}
                <div className={styles.divider}></div>
                <div className={styles.formBtnContainer}>
                  <div
                    className={styles.formBtnReset}
                    onClick={() => onClearUser()}
                  >
                    clear
                  </div>
                  <button
                    className={styles.formBtnSubmit}
                    onClick={() => onSubmitUser()}
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
      <ToastContainer />
    </>
  );
};

export default UserForm;
