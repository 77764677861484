import { API, graphqlOperation } from "aws-amplify";
import { transactionByCustomerID } from "../graphql/queries";
import { toast } from "react-toastify";

export const getTransactionByCustomerID = async (
  customerID,
  token,
  items,
  pageLimit,
  maxPage,
  currentPage,
  setToken,
  setItems,
  setLoading,
  setMaxPage,
  setCurrentPage,
  searchType
) => {
  setLoading(true);
  if (customerID.length < 10) {
    toast.error(`Customer ID is too short`);
    setLoading(false);
    return; // Use return to exit the function
  }

  if (searchType === "loadMore" && items.length > 0 && token === null) {
    toast.info("No more data to fetch");
    return;
  }

  function resetPage() {
    setMaxPage(1);
    setCurrentPage(1);
  }

  try {
    const result = await API.graphql(
      graphqlOperation(transactionByCustomerID, {
        customerID: customerID, //"bf0e85f2-07bf-466c-aa7e-ded9294fd16c"
        sortDirection: "DESC",
        limit: pageLimit,
        // nextToken: token === "start" ? null : token,
        nextToken: searchType === "start" ? null : token,
      })
    );

    const newItems = result.data.transactionByCustomerID.items;
    setToken(result.data.transactionByCustomerID.nextToken);

    if (newItems.length === 0 && token === null) {
      toast.error(`Wrong Customer ID`);
      return;
    }

    if (searchType === "start") {
      //If user presses search for the first time.
      resetPage();
      setItems(newItems);
    } else if (searchType === "loadMore") {
      //If user wants to load more data
      setMaxPage(maxPage + 1);
      setItems((prevItems) => [...prevItems, ...newItems]);
      setCurrentPage(maxPage + 1);
    }
  } catch (error) {
    console.error("Error fetching transactions:", error);
    toast.error(`Error fetching Data`);
  } finally {
    setLoading(false);
  }
};
