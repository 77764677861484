import React, { useState, useEffect } from "react";
// import { API } from "aws-amplify";
// import { createStore } from "../../graphql/mutations";
import img from "../images/qr_test.png";
import UserForm from "./UserForm";
import exportToExcel from "./util/exportToExcel";
import styles from "../../styles/Forms.module.css";
import preventEnterSubmit from "./util/preventEnterSubmit";
import { API } from "aws-amplify";
import { ToastContainer, toast } from "react-toastify";
import { deleteUser, updateUser } from "../../graphql/mutations";
import { NumericFormat } from "react-number-format";

export const Account = ({
  storeData,
  // customerData,
  // rechargeData,
  // transactionData,
  userData,
  accEmail,
  // cognitoID,
  // stripeKey,
  // stripeCustomer,
  // stripeProducts,
  expender,
}) => {
  const [adminPinChangeToggle, setadminPinChangeToggle] = useState(false);
  const [adminPinInput, setAdminPinInput] = useState("");
  const [newAdminPinInput, setNewAdminPinInput] = useState("");
  const [confirmNewAdminPin, setConfirmNewAdminPin] = useState("");
  const [chosenEmployee, setChosenEmployee] = useState("");
  const [actionToggle, setActionToggle] = useState(false);
  const [deactivateToggle, setDeactivateToggle] = useState(false);
  // const [editName, setEditName] = useState(false);
  // const [editNumber, setEditNumber] = useState(false);
  // const [editEmail, setEditEmail] = useState(false);
  // const [editAddress, setEditAddress] = useState(false);
  const employeeListing = userData.filter((c) => c.name !== "Admin");
  const admin = userData.filter((c) => c.name === "Admin");
  const adminNum = userData.map((e) => e.name).indexOf("Admin");
  const adminPin = adminNum > -1 ? userData[adminNum].pin : "notFound";

  const pinExtractor = (input, field) => {
    let output = [];
    for (var i = 0; i < input.length; ++i) output.push(input[i][field]);
    return output;
  };

  const listOfPins = pinExtractor(userData, "pin");

  const employeeChooser = (data) => {
    setChosenEmployee(data);
    setAdminPinInput("");
    actionToggle === false ? setActionToggle(true) : setActionToggle(false);
  };

  const adminPinChangeToggler = () => {
    setAdminPinInput("");
    setNewAdminPinInput("");
    setConfirmNewAdminPin("");
    adminPinChangeToggle === true
      ? setadminPinChangeToggle(false)
      : setadminPinChangeToggle(true);
  };

  const deactivateMultiUserToggler = () => {
    setAdminPinInput("");
    deactivateToggle === true
      ? setDeactivateToggle(false)
      : setDeactivateToggle(true);
  };

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  // const toggle = (state, setState) => {
  //   state === true ? setState(false) : setState(true);
  //   let arr = [editName, editNumber, editEmail, editAddress];
  //   // for (let i = 0; i < arr.length; i++) {
  //   //   if (state !== arr[i]) {
  //   //     setEditName(false);
  //   //   }
  //   // }
  //   if (state !== editName) {
  //     setEditName(false);
  //   }
  //   if (state !== editNumber) {
  //     setEditNumber(false);
  //   }
  //   if (state !== editEmail) {
  //     setEditEmail(false);
  //   }
  //   if (state !== editAddress) {
  //     setEditAddress(false);
  //   }
  // };

  const tableActionBtnStyles = (data) => {
    if (actionToggle === true && chosenEmployee.name === data.name) {
      return "tableActionActive";
    } else {
      return "tableAction";
    }
  };

  const handleExport = (data, label) => {
    const d = new Date();
    const date = d.toLocaleDateString("en-US", options);
    exportToExcel(data, `${label}, ${date}`);
  };

  const onDeletionSubmit = () => {
    if (userData.length > 0 && adminPinInput !== adminPin) {
      toast.error("Wrong Admin Pin");
    } else if (userData.length > 0 && adminPinInput === adminPin) {
      try {
        API.graphql({
          query: deleteUser,
          variables: {
            input: {
              id: chosenEmployee.id,
            },
          },
        });
        setChosenEmployee(false);
        setActionToggle(false);
        toast.success("User Deleted");
      } catch (error) {
        console.error("error occured", error);
      }
    } else if (userData.length === 0) {
      try {
        API.graphql({
          query: deleteUser,
          variables: {
            input: {
              id: chosenEmployee.id,
            },
          },
        });
        setChosenEmployee(false);
        setActionToggle(false);
        toast.success("User Deleted");
      } catch (error) {
        console.error("error occured", error);
      }
    } else {
      toast.error("Error");
    }
  };

  const deactivateMultiUser = () => {
    if (
      admin.length > 0 &&
      userData.length === 1 &&
      adminPinInput === adminPin
    ) {
      try {
        API.graphql({
          query: deleteUser,
          variables: {
            input: {
              id: admin[0].id,
            },
          },
        });
        setAdminPinInput("");
        toast.success("Multi-User Mode Deactivated");
      } catch (error) {
        console.error("error occured", error);
      }
    } else {
      toast.error("Wrong Admin Pin");
    }
  };

  const changeAdminPinClear = () => {
    document.getElementById("admin-pin-change-form").reset();
    setAdminPinInput("");
    setNewAdminPinInput("");
    setConfirmNewAdminPin("");
  };

  const changeAdminPin = () => {
    if (adminPinInput !== adminPin) {
      toast.error("Wrong Current Admin Pin");
    } else if (
      newAdminPinInput !== "" &&
      confirmNewAdminPin !== newAdminPinInput
    ) {
      toast.error("New Pin does not match");
    } else if (newAdminPinInput === adminPin) {
      toast.error("Please choose another new pin number");
    } else if (listOfPins.indexOf(newAdminPinInput) !== -1) {
      toast.error("This pin already exist");
    } else if (
      admin.length > 0 &&
      adminPinInput === adminPin &&
      listOfPins.indexOf(newAdminPinInput) === -1 &&
      newAdminPinInput !== adminPin &&
      newAdminPinInput !== "" &&
      confirmNewAdminPin === newAdminPinInput
    ) {
      try {
        API.graphql({
          query: updateUser,
          variables: {
            input: {
              id: admin[0].id,
              pin: newAdminPinInput,
            },
          },
        });
        setAdminPinInput("");
        setNewAdminPinInput("");
        setConfirmNewAdminPin("");
        setadminPinChangeToggle(false);
        toast.success("Admin Pin Changed");
      } catch (error) {
        console.error("error occured", error);
      }
    } else {
      toast.error("error");
    }
  };

  return (
    <div class={expender ? "contentWithSideBarThin" : "contentWithSideBar"}>
      <div class="pageTitle">
        <h1>Account Setting</h1>
      </div>
      <div class="pageTitle">
        <div class="titleWithIcon">
          <h2 class="iconInTitle">
            <i class="fa-solid fa-briefcase"></i>
          </h2>
          <h2>Business Information</h2>
        </div>
      </div>
      <div class="contentContainer">
        {/* <div>Account: "{cognitoID}"</div> */}
        <div>Email: {accEmail}</div>
        {/* <div>
          Subscription status: "put either (trial period), (active), or
          (inactive)
        </div> */}
        <div>
          User Mode:{" "}
          {userData.length > 0 ? "Multi-user Mode" : "Single-user Mode"}
        </div>
        <div>Store Name: {storeData[0].storeName}</div>
        <div>
          Store Address:{" "}
          {storeData[0].storeAddyLine1 +
            storeData[0].storeAddyLine2 +
            storeData[0].storeAddyCity +
            storeData[0].storeAddyState +
            storeData[0].storeAddyZipCode}
        </div>
        <div>Store Phone Number: {storeData[0].storePhoneNumber}</div>
      </div>
      <div class="pageTitle">
        <div class="titleWithIcon">
          <h2 class="iconInTitle">
            <i class="fa-solid fa-database"></i>
          </h2>
          <h2>Data</h2>
        </div>
      </div>
      <div class="contentContainer">
        <div>
          We recommend having backup of your data when using any online
          services. Click to download.
        </div>
        {/* <div class="badgeContainer">
          <div
            class="badgeGray"
            onClick={() => handleExport(customerData, "customerData")}
          >
            <i class="fa-solid fa-download"></i> Customer Data
          </div>
        </div> */}
        {/* <div class="badgeContainer">
          <div
            class="badgeGray"
            onClick={() => handleExport(rechargeData, "rechargeData")}
          >
            <i class="fa-solid fa-download"></i> Recharge Log Data
          </div>
        </div> */}
        {/* <div class="badgeContainer">
          <div
            class="badgeGray"
            onClick={() => handleExport(transactionData, "transactionData")}
          >
            <i class="fa-solid fa-download"></i> Transaction Log Data
          </div>
        </div> */}
      </div>
      <div class="pageTitle">
        <div class="titleWithIcon">
          <h2 class="iconInTitle">
            <i class="fa-solid fa-user-group"></i>
          </h2>
          <h2>User Group</h2>
        </div>
      </div>
      {userData.length > 0 ? (
        <div class="contentContainerColumns">
          <div class="contentColumn">
            <h4>User List (employees)</h4>
            <div className={styles.userTable}>
              <table>
                <tr class="tableRow">
                  <th>
                    <div className={styles.userTableHead}>User Name</div>
                  </th>
                  <th>
                    <div>
                      <i class="fa-solid fa-grip"></i>
                    </div>
                  </th>
                </tr>
                {employeeListing.map((data) => {
                  return (
                    <tr class="tableRow">
                      <td>{data.name}</td>
                      <td>
                        <div class="relative">
                          <div>
                            <div class="cardTableLine">
                              <div
                                class={tableActionBtnStyles(data)}
                                onClick={() => employeeChooser(data)}
                              >
                                <i class="fa-solid fa-trash"></i>
                              </div>
                            </div>
                            {actionToggle &&
                            chosenEmployee.name === data.name ? (
                              <div class="deleteTab">
                                {userData.length > 0 ? (
                                  <div class="pinInputContainer">
                                    <div>
                                      <i class="fa-solid fa-key"></i> Pin:
                                    </div>
                                    <div>
                                      {/* <input
                                        onChange={(e) =>
                                          setAdminPinInput(e.target.value)
                                        }
                                        onKeyUp={(e) => preventEnterSubmit(e)}
                                        class="pinInput"
                                        placeholder="****"
                                      /> */}
                                      <NumericFormat
                                        allowLeadingZeros
                                        className="pinInput"
                                        onKeyUp={(e) => preventEnterSubmit(e)}
                                        type="password"
                                        format="####"
                                        mask="_"
                                        placeholder="****"
                                        allowEmptyFormatting
                                        minlength="4"
                                        maxlength="8"
                                        autocomplete="new-password"
                                        onChange={(e) =>
                                          setAdminPinInput(e.target.value)
                                        }
                                        value={adminPinInput}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div
                                  class="deleteBtn"
                                  onClick={() => onDeletionSubmit()}
                                >
                                  Delete
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
          <div class="contentColumn">
            <h4>Admin User</h4>
            {/* TODO: Make Update function for admin pin. */}
            {/* TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO: */}
            {/* TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO: */}
            {/* TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO: */}
            {/* TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO: */}
            {/* TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO: */}
            {/* TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO: */}
            {/* TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO: */}
            {/* TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO:TODO: */}
            <div class="relative">
              <div
                className={adminPinChangeToggle ? "primaryBtn" : "secondaryBtn"}
                onClick={() => adminPinChangeToggler()}
              >
                <div class="titleWithIcon">
                  <div class="iconInTitle">
                    <i class="fa-solid fa-lock"></i>
                  </div>
                  <div>Change Admin Pin</div>
                </div>
              </div>
              {adminPinChangeToggle === true ? (
                <div class="deleteTab">
                  {userData.length > 0 ? (
                    <>
                      <form id="admin-pin-change-form">
                        <div class="pinChangeInputContainer">
                          <div>Current Admin Pin:</div>
                          <div>
                            {/* <input
                              onChange={(e) => setAdminPinInput(e.target.value)}
                              onKeyUp={(e) => preventEnterSubmit(e)}
                              class="pinInput"
                              placeholder="****"
                            /> */}
                            <NumericFormat
                              allowLeadingZeros
                              className="pinInput"
                              onKeyUp={(e) => preventEnterSubmit(e)}
                              type="password"
                              format="####"
                              mask="_"
                              placeholder="****"
                              allowEmptyFormatting
                              minlength="4"
                              maxlength="8"
                              autocomplete="new-password"
                              onChange={(e) => setAdminPinInput(e.target.value)}
                              value={adminPinInput}
                            />
                          </div>
                        </div>
                        <div class="pinChangeInputContainer">
                          <div>New Admin Pin:</div>
                          <div>
                            {/* <input
                              onChange={(e) =>
                                setNewAdminPinInput(e.target.value)
                              }
                              onKeyUp={(e) => preventEnterSubmit(e)}
                              class="pinInput"
                              placeholder="****"
                            /> */}
                            <NumericFormat
                              allowLeadingZeros
                              className="pinInput"
                              onKeyUp={(e) => preventEnterSubmit(e)}
                              type="password"
                              format="####"
                              mask="_"
                              placeholder="****"
                              allowEmptyFormatting
                              minlength="4"
                              maxlength="8"
                              autocomplete="new-password"
                              onChange={(e) =>
                                setNewAdminPinInput(e.target.value)
                              }
                              value={newAdminPinInput}
                            />
                          </div>
                        </div>
                        <div class="pinChangeInputContainer">
                          <div>Confirm New Admin Pin:</div>
                          <div>
                            {/* <input
                              onChange={(e) =>
                                setConfirmNewAdminPin(e.target.value)
                              }
                              onKeyUp={(e) => preventEnterSubmit(e)}
                              class="pinInput"
                              placeholder="****"
                            /> */}
                            <NumericFormat
                              allowLeadingZeros
                              className="pinInput"
                              onKeyUp={(e) => preventEnterSubmit(e)}
                              type="password"
                              format="####"
                              mask="_"
                              placeholder="****"
                              allowEmptyFormatting
                              minlength="4"
                              maxlength="8"
                              autocomplete="new-password"
                              onChange={(e) =>
                                setConfirmNewAdminPin(e.target.value)
                              }
                              value={confirmNewAdminPin}
                            />
                          </div>
                        </div>
                      </form>
                    </>
                  ) : (
                    ""
                  )}
                  <div class="resetBtn" onClick={() => changeAdminPinClear()}>
                    Clear
                  </div>
                  <div class="primaryBtn" onClick={() => changeAdminPin()}>
                    Change Pin
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {userData.length === 1 ? (
              <div class="relative">
                <div
                  className={deactivateToggle ? "deleteBtn" : "secondaryBtn"}
                  onClick={() => deactivateMultiUserToggler()}
                >
                  <div class="titleWithIcon">
                    <div class="iconInTitle">
                      <i class="fa-solid fa-users-slash"></i>
                    </div>
                    <div>Deactivate multi user mode</div>
                  </div>
                </div>
                {deactivateToggle ? (
                  <div class="deleteTab">
                    {userData.length > 0 ? (
                      <div class="pinInputContainer">
                        <div>
                          <i class="fa-solid fa-key"></i> Pin:
                        </div>
                        {/* <div>
                          <input
                            onChange={(e) => setAdminPinInput(e.target.value)}
                            onKeyUp={(e) => preventEnterSubmit(e)}
                            class="pinInput"
                            placeholder="****"
                          />
                        </div> */}
                        <NumericFormat
                          allowLeadingZeros
                          className="pinInput"
                          onKeyUp={(e) => preventEnterSubmit(e)}
                          type="password"
                          format="####"
                          mask="_"
                          placeholder="****"
                          allowEmptyFormatting
                          minlength="4"
                          maxlength="8"
                          autocomplete="new-password"
                          onChange={(e) => setAdminPinInput(e.target.value)}
                          value={adminPinInput}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      class="deleteBtn"
                      onClick={() => deactivateMultiUser()}
                    >
                      Deactivate
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div class="relative">
                <div className="disabledBtn">
                  <div class="titleWithIcon">
                    <div class="iconInTitle">
                      <i class="fa-solid fa-users-slash"></i>
                    </div>
                    <div>Deactivate multi user mode</div>
                  </div>
                </div>
              </div>
            )}
            <div>
              In order to deactivate the Multi-User mode, you must delete all
              users first. Deactivate button will be activated after.
            </div>
            <div>
              Deactivation will cause "cashier" column to dissapear in history
              charts but data will remain under downloadables
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <UserForm userData={userData} listOfPins={listOfPins} />
      {userData.length === 0 ? (
        <div class="contentContainer">
          <div>
            If your store employs individuals who will also utilize the app, the
            Multi-User setup is a perfect choice. Begin by establishing an Admin
            user, and from there, you can configure user accounts for your
            employees.
          </div>
          <br />
          <div>
            Employees have the ability to register, modify, and make adjustments
            to customer balances, but they do not possess the capability to
            delete customers. Furthermore, they lack the authorization to alter
            the product pricing list or modify any store settings.
          </div>
          <br />
          <div>
            Once you activate the Multi-User feature, each user will be required
            to input their unique PIN to execute changes or transactions. This
            provides a clear record of who performed which actions within the
            app, contributing to the safeguarding of your customers' balances
            and store data.
          </div>
          <br />
          <div>
            If an employee happens to forget their PIN number, an admin has the
            option to recreate the user. In the event that the Admin's PIN
            number is forgotten, please reach out to hjldevelopment@gmail.com
            for assistance in retrieving the PIN.
          </div>
        </div>
      ) : (
        ""
      )}
      {/* <div class="pageTitle">
        <h1>Subscription</h1>
      </div>
      <div class="contentContainer">
        {stripeProducts
          ? stripeProducts.map((product) => {
              return (
                <div>
                  <div>{product.name}</div>
                  <div>{product.images}</div>
                  <div>{product.default_price}</div>
                </div>
              );
            })
          : ""}
      </div> */}
      <ToastContainer />
    </div>
  );
};
