import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination";
import { paginate } from "./util/paginate";
import { API, Auth } from "aws-amplify";
import SearchBar from "./SearchBar";
import _ from "lodash";
import PageSizeSelector from "./PageSizeSelector";
import styles from "../../styles/CustomerList.module.css";
import RechargeUI from "./RechargeUI";
import TransactionUI from "./TransactionUI";
import toggle from "./util/toggle";
import EditCustomerUI from "./EditCustomerUI";
import { ToastContainer, toast } from "react-toastify";
import { deleteCustomer } from "../../graphql/mutations";
import { NumericFormat } from "react-number-format";
import preventEnterSubmit from "./util/preventEnterSubmit";
import { listCustomers } from "../../graphql/queries";
import { graphqlOperation } from "aws-amplify";

export const CustomerList = ({
  customerData,
  pricingData,
  userData,
  expender,
}) => {
  const [currentPage, setCurrentPage] = useState(1); //default 1
  const [query, setQuery] = useState(""); //Search Feature
  const [pageSize, setPageSize] = useState(5); //set able 5, 10, 30
  const [sortColumn_path, setSortColumn_path] = useState("createdAt");
  const [sortColumn_order, setSortColumn_order] = useState("desc");
  const [rechargeButtonUIActive, setRechargeButtonUIActive] = useState(false);
  const [payButtonUIActive, setPayButtonUIActive] = useState(false);
  const [editButtonUIActive, setEditButtonUIActive] = useState(false);
  const [deleteButtonUIActive, setDeleteButtonUIActive] = useState(false);
  const [adminPinInput, setAdminPinInput] = useState("");
  const [employeePinInput, setEmployeePinInput] = useState("");
  const [isLoadingDeleteCustomer, setIsLoadingDeleteCustomer] = useState(false);
  const [chosenCustomer, setChosenCustomer] = useState("");

  const [filteredCustomerData, setFilteredCustomerData] =
    useState(customerData);
  const [serverSideSearchParam, setServerSideSearchParam] = useState("");

  const fetchCustomers = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(listCustomers, {
          limit: 10000,
          nextToken: null,
          filter: {
            or: [
              { firstName: { contains: serverSideSearchParam } },
              { lastName: { contains: serverSideSearchParam } },
              { fullName: { contains: serverSideSearchParam } },
              { phoneNumber: { contains: serverSideSearchParam } },
            ],
          },
        })
      );
      setFilteredCustomerData(result.data.listCustomers.items);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const navigate = useNavigate();
  const customerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      payButtonUIActive === false &&
      editButtonUIActive === false &&
      rechargeButtonUIActive === false &&
      deleteButtonUIActive === false &&
      customerRef.current &&
      !customerRef.current.contains(event.target)
    ) {
      setChosenCustomer("");
      navigate("/customer");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    payButtonUIActive,
    editButtonUIActive,
    rechargeButtonUIActive,
    deleteButtonUIActive,
  ]);

  useEffect(() => {
    if (chosenCustomer) {
      navigate(`/customer#${chosenCustomer.id}`);
    }
  }, [chosenCustomer, navigate]);

  const adminNum = userData.map((e) => e.name).indexOf("Admin");
  const adminPin = adminNum > -1 ? userData[adminNum].pin : "notFound";

  //Reqired States for the Table, Pagination, Filtering and Sorting functionality
  const columns = [
    { path: "firstName", label: "First Name" },
    { path: "lastName", label: "Last Name" },
    { path: "createdAt", label: "Customer Since" },
    { path: "updatedAt", label: "Last Visited" },
  ];

  //Search -> Sort -> Paginate
  // const searched = customerData.filter((data) => {
  const searched = filteredCustomerData.filter((data) => {
    if (query === "") {
      return data;
    } else if (
      data.fullName.toLowerCase().includes(query.toLowerCase()) ||
      data.firstName.toLowerCase().includes(query.toLowerCase()) ||
      data.lastName.toLowerCase().includes(query.toLowerCase()) ||
      data.id.includes(query) ||
      data.phoneNumber.includes(query)
    ) {
      return data;
    }
  });
  const sorted = _.orderBy(searched, [sortColumn_path], [sortColumn_order]);
  const paginated = paginate(sorted, currentPage, pageSize);

  useEffect(() => {
    const url = window.location.href;
    const customerID = url.split("#")[1];
    setQuery(customerID || ""); // Set the customer ID as the default state
  }, []);

  const handleSearchChange = (event) => {
    setQuery(event.target.value);
    setCurrentPage(1);
  };
  const handleSort = (path) => {
    if (path === sortColumn_path) {
      setSortColumn_order(sortColumn_order === "asc" ? "desc" : "asc");
      setCurrentPage(1);
    } else {
      setSortColumn_path(path);
      setSortColumn_order("asc");
      renderSortIcon(path);
      setCurrentPage(1);
    }
  };
  const renderSortIcon = (column) => {
    if (column === sortColumn_path && sortColumn_order === "desc") {
      return <i className="fa-solid fa-caret-up"></i>;
    } else if (column === sortColumn_path && sortColumn_order === "asc") {
      return <i className="fa-solid fa-caret-down"></i>;
    } else {
      return <i className="fa-solid fa-sort"></i>;
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      return setCurrentPage(currentPage - 1);
    }
  };
  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= paginated.length) {
      setCurrentPage(nextPage);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //API delete
  const onDelete = async () => {
    if (
      userData.length > 0 &&
      chosenCustomer.ice === 0 &&
      chosenCustomer.water === 0 &&
      chosenCustomer.alkaline === 0 &&
      adminPinInput !== adminPin
    ) {
      toast.error("Wrong Admin Pin");
    } else if (
      userData.length > 0 &&
      chosenCustomer.ice === 0 &&
      chosenCustomer.water === 0 &&
      chosenCustomer.alkaline === 0 &&
      adminPinInput === adminPin &&
      isLoadingDeleteCustomer === false
    ) {
      try {
        setIsLoadingDeleteCustomer(true);
        await API.graphql({
          query: deleteCustomer,
          variables: {
            input: {
              id: chosenCustomer.id,
            },
          },
        });
        setDeleteButtonUIActive(false);
        setIsLoadingDeleteCustomer(false);
        toast.success("Customer Deleted");
      } catch (error) {
        console.error("error occured", error);
      }
    } else if (
      userData.length === 0 &&
      chosenCustomer.ice === 0 &&
      chosenCustomer.water === 0 &&
      chosenCustomer.alkaline === 0 &&
      isLoadingDeleteCustomer === false
    ) {
      try {
        setIsLoadingDeleteCustomer(true);
        await API.graphql({
          query: deleteCustomer,
          variables: {
            input: {
              id: chosenCustomer.id,
            },
          },
        });
        setDeleteButtonUIActive(false);
        setIsLoadingDeleteCustomer(false);
        toast.success("Customer Deleted");
      } catch (error) {
        console.error("error occured", error);
      }
    } else {
      toast.error("error");
    }
  };

  //Styles
  const tableActionBtnStyles = (data) => {
    if (
      // actionToggle === true &&
      chosenCustomer.phoneNumber === data.phoneNumber
    ) {
      return "tableActionActive";
    } else {
      return "tableAction";
    }
  };
  const deleteChoiceStyle = () => {
    return deleteButtonUIActive === true
      ? styles.actionChoiceDeleteActive
      : styles.actionChoiceDelete;
  };
  const fontStyle = (data) => {
    return chosenCustomer && data.id === chosenCustomer.id ? "bold" : "";
  };

  const getRowClassName = (index, chosenCustomer) => {
    if (chosenCustomer && paginated[index].id === chosenCustomer.id) {
      return "tableRowActive"; // Apply active row style
    } else if (index % 2 === 0) {
      return "tableRowEven"; // Apply even row style
    } else {
      return "tableRowOdd"; // Apply odd row style
    }
  };

  const copyToClipboard = (id) => {
    navigator.clipboard.writeText(id);
    toast.success("Customer ID Copied");
  };

  const onServerSideSearch = () => {
    fetchCustomers();
    setCurrentPage(1);
  };

  return (
    <div>
      <div
        className={expender ? "contentWithSideBarThin" : "contentWithSideBar"}
      >
        <div className="pageTitle">
          <h1>Customer Listing</h1>
        </div>
        <div className="contentContainer">
          <div className="tableContainer">
            <div>
              Update: Search button press is now required for searching
              customers
            </div>
            <div>Note: Name search query is CASE SENSITIVE (for now)</div>
            <div className="flex">
              <div className={styles.searchBarContainer}>
                <input
                  className={styles.searchInput}
                  onChange={(e) => setServerSideSearchParam(e.target.value)}
                  value={serverSideSearchParam}
                  placeholder="Search Name or Phone Number"
                />
              </div>
              <div
                className={styles.searchBtn}
                onClick={() => onServerSideSearch()}
              >
                Search
              </div>
              <div
                className={styles.searchBtn}
                onClick={() => setServerSideSearchParam("")}
              >
                Clear
              </div>
            </div>
            <div className="searchBarAndPageSize">
              <div>
                {/* <SearchBar
                  type="text"
                  placeholder="Search name or number"
                  setChosenCustomer={setChosenCustomer}
                  onSearchChange={handleSearchChange}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  setQuery={setQuery}
                  query={query}
                /> */}
              </div>
              <div>
                <PageSizeSelector
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
            <table ref={customerRef}>
              <tr className="tableRow">
                <th onClick={() => handleSort(columns[0].path)}>
                  <div className="tableHead">
                    <div>
                      <div>First</div>
                      <div>Name</div>
                    </div>
                    <div className="sortIcon">
                      {renderSortIcon(columns[0].path)}
                    </div>
                  </div>
                </th>
                <th onClick={() => handleSort(columns[1].path)}>
                  <div className="tableHead">
                    <div>
                      <div>Last</div>
                      <div>Name</div>
                    </div>
                    <div className="sortIcon">
                      {renderSortIcon(columns[1].path)}
                    </div>
                  </div>
                </th>
                <th className="hiddenForMobile">Number</th>
                <th
                  onClick={() => handleSort(columns[2].path)}
                  className="hiddenForMobile"
                >
                  Customer Since {renderSortIcon(columns[2].path)}
                </th>
                <th
                  onClick={() => handleSort(columns[3].path)}
                  className="hiddenForMobile"
                >
                  Last Visited {renderSortIcon(columns[3].path)}
                </th>
                <th>
                  <div className="tableHead">Prepaid Balance</div>
                  <div className="badgeContainer">
                    {/* TODO: getting rid of this because of redo of ice pricings into bags and block */}
                    {/* <div className="badgeIce">Ice</div> */}
                    <div className="badgeWater">Water</div>
                    <div className="badgeAlkaline">Alkaline</div>
                  </div>
                </th>
                <th>Action</th>
              </tr>
              {paginated.map((data, index) => {
                return (
                  <tr
                    className={getRowClassName(index, chosenCustomer)}
                    onClick={() => setChosenCustomer(data)}
                  >
                    <td className="wordBreak">
                      <div className={fontStyle(data)}>{data.firstName}</div>
                    </td>
                    <td className="wordBreak">
                      <div className={fontStyle(data)}>{data.lastName}</div>
                    </td>
                    <td className="hiddenForMobile">
                      <div className={fontStyle(data)}>
                        {`(${data.phoneNumber.slice(
                          0,
                          3
                        )}) ${data.phoneNumber.slice(
                          3,
                          6
                        )}-${data.phoneNumber.slice(6)}`}
                      </div>
                    </td>
                    <td className="hiddenForMobile">
                      <div className={fontStyle(data)}>
                        {new Date(data.createdAt).toLocaleDateString()}
                      </div>
                    </td>
                    <td className="hiddenForMobile">
                      <div className={fontStyle(data)}>
                        {new Date(data.updatedAt).toLocaleDateString()}
                      </div>
                    </td>
                    <td>
                      <div className="badgeColumn">
                        {data.ice ? (
                          <div className="badgeIce">{data.ice}</div>
                        ) : (
                          ""
                        )}{" "}
                        {data.water ? (
                          <div className="badgeWater">{data.water}</div>
                        ) : (
                          ""
                        )}{" "}
                        {data.alkaline ? (
                          <div className="badgeAlkaline">{data.alkaline}</div>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="tableActionContainer">
                        <div>
                          <div className={tableActionBtnStyles(data)}>
                            <i className="fa-solid fa-ellipsis"></i>
                          </div>
                          {/* {actionToggle && */}
                          {chosenCustomer.firstName === data.firstName &&
                          chosenCustomer.lastName === data.lastName &&
                          chosenCustomer.phoneNumber === data.phoneNumber ? (
                            <div className="relative">
                              <div className="actionContainer">
                                <div>
                                  <div
                                    className={styles.actionChoice}
                                    onClick={() =>
                                      toggle(
                                        rechargeButtonUIActive,
                                        setRechargeButtonUIActive
                                      )
                                    }
                                  >
                                    <div className={styles.actionIcon}>
                                      <i className="fa-solid fa-plus"></i>
                                    </div>
                                    <div className={styles.actionLabel}>
                                      Add Balance
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div
                                    className={styles.actionChoice}
                                    onClick={() =>
                                      toggle(
                                        payButtonUIActive,
                                        setPayButtonUIActive
                                      )
                                    }
                                  >
                                    <div className={styles.actionIcon}>
                                      <i className="fa-solid fa-minus"></i>
                                    </div>
                                    <div className={styles.actionLabel}>
                                      Subtract Balance
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div
                                    className={styles.actionChoice}
                                    onClick={() =>
                                      toggle(
                                        editButtonUIActive,
                                        setEditButtonUIActive
                                      )
                                    }
                                  >
                                    <div className={styles.actionIcon}>
                                      <i className="fa-solid fa-pen-to-square"></i>
                                    </div>
                                    <div className={styles.actionLabel}>
                                      Edit Customer
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div
                                    className={styles.actionChoice}
                                    onClick={() => {
                                      copyToClipboard(chosenCustomer.id);
                                    }}
                                  >
                                    <div className={styles.actionIcon}>
                                      <i className="fa-solid fa-copy"></i>
                                    </div>
                                    <div className={styles.actionLabel}>
                                      Copy ID
                                    </div>
                                  </div>
                                </div>
                                {/* delete customer funciton */}
                                {chosenCustomer.ice === 0 &&
                                chosenCustomer.water === 0 &&
                                chosenCustomer.alkaline === 0 ? (
                                  <div
                                    className={deleteChoiceStyle()}
                                    onClick={() =>
                                      deleteButtonUIActive === true
                                        ? setDeleteButtonUIActive(false)
                                        : setDeleteButtonUIActive(true)
                                    }
                                  >
                                    <div className={styles.actionIcon}>
                                      <i className="fa-solid fa-trash"></i>
                                    </div>
                                    <div className={styles.actionLabel}>
                                      Delete
                                    </div>
                                  </div>
                                ) : (
                                  // when customer has balance
                                  <>
                                    <div
                                      className={styles.deleteChoiceDisabled}
                                    >
                                      <div className={styles.actionIcon}>
                                        <i className="fa-solid fa-trash"></i>
                                      </div>
                                      <div className={styles.actionLabel}>
                                        Delete
                                      </div>
                                    </div>
                                    <div className={styles.explain}>
                                      <i className="fa-solid fa-circle-info"></i>{" "}
                                      has remaining balance
                                    </div>
                                  </>
                                )}
                                {/* If Delete Button is Pressed ========================================== */}
                                {deleteButtonUIActive ? (
                                  <>
                                    {userData.length > 0 ? (
                                      <div className={styles.pinInputContainer}>
                                        <div>Pin</div>
                                        <div>
                                          {/* <input
                                          className={styles.pinInput}
                                          // type="password"
                                          placeholder="****"
                                          onChange={(e) =>
                                            setAdminPinInput(e.target.value)
                                          }
                                        /> */}
                                          <NumericFormat
                                            className={styles.pinInput}
                                            allowLeadingZeros
                                            onKeyUp={(e) =>
                                              preventEnterSubmit(e)
                                            }
                                            type="password"
                                            format="####"
                                            mask="_"
                                            placeholder="****"
                                            allowEmptyFormatting
                                            minLength="4"
                                            maxLength="8"
                                            autocomplete="new-password"
                                            onChange={(e) =>
                                              setAdminPinInput(e.target.value)
                                            }
                                            value={adminPinInput}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {isLoadingDeleteCustomer ? (
                                      <div className={styles.deleteBtn}>
                                        <div className="loaderDelete"></div>
                                      </div>
                                    ) : (
                                      <div
                                        className={styles.deleteBtn}
                                        onClick={() => onDelete()}
                                      >
                                        Delete
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
          {searched.length > 0 ? (
            <div className="paginationPosition">
              <Pagination
                totalNumOfClient={searched.length}
                onNextPage={handleNextPage}
                onPrevPage={handlePrevPage}
                onPageChange={handlePageChange}
                currentPage={currentPage}
                pageSize={pageSize}
              />
            </div>
          ) : (
            ""
          )}
          {query.length > 0 && searched.length === 0 ? (
            <div className="customerListSearchNotFound">
              <div className="rightMargin">
                <i class="fa-regular fa-face-frown"></i>
              </div>
              <div>Sorry, Customer not found</div>
            </div>
          ) : (
            ""
          )}
          {customerData.length === 0 ? (
            <div className="customerListNoCustomer">
              <div className="bold">No Existing Customers yet</div>
              <div className="customerListGuideContainer">
                <div>Head to the</div>
                <div className="customerListGuide">
                  <div className="rightMargin">
                    <i class="fa-solid fa-address-card"></i>
                  </div>
                  <div className="bold">Register Customer</div>
                </div>
                <div>page to add your first customer</div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* If Recharge Button is Pressed ====================================== */}
        {editButtonUIActive ? (
          <div
            className={
              expender
                ? styles.transactionUIContainerThin
                : styles.transactionUIContainer
            }
          >
            <EditCustomerUI
              chosenCustomer={chosenCustomer}
              setChosenCustomer={setChosenCustomer}
              editButtonUIActive={editButtonUIActive}
              setEditButtonUIActive={setEditButtonUIActive}
              userData={userData}
            />
          </div>
        ) : (
          ""
        )}
        {/* =================================================================== */}
        {/* If Recharge Button is Pressed ====================================== */}
        {rechargeButtonUIActive ? (
          <div
            className={
              expender
                ? styles.transactionUIContainerThin
                : styles.transactionUIContainer
            }
          >
            <RechargeUI
              pricingData={pricingData}
              chosenCustomer={chosenCustomer}
              setChosenCustomer={setChosenCustomer}
              rechargeButtonUIActive={rechargeButtonUIActive}
              setRechargeButtonUIActive={setRechargeButtonUIActive}
              userData={userData}
              fetchCustomers={fetchCustomers}
            />
          </div>
        ) : (
          ""
        )}
        {/* =================================================================== */}
        {/* If Pay Button is Pressed ========================================== */}
        {payButtonUIActive ? (
          <div
            className={
              expender
                ? styles.transactionUIContainerThin
                : styles.transactionUIContainer
            }
          >
            <TransactionUI
              chosenCustomer={chosenCustomer}
              setChosenCustomer={setChosenCustomer}
              payButtonUIActive={payButtonUIActive}
              setPayButtonUIActive={setPayButtonUIActive}
              userData={userData}
              fetchCustomers={fetchCustomers}
            />
          </div>
        ) : (
          ""
        )}
        {/* ==================================================================== */}
        <ToastContainer />
      </div>
    </div>
  );
};
