import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listCustomers } from '../graphql/queries';
import { onCreateCustomer, onUpdateCustomer, onDeleteCustomer } from '../graphql/subscriptions';

const useCustomerData = (route, searchParamKeyword) => {
  const [customerData, setCustomerData] = useState([]);

  useEffect(() => {
    const subscriptionFilter = { filter: {} };

    const fetchCustomers = async () => {
      try {
        const result = await API.graphql(
          graphqlOperation(listCustomers, {
            limit: 10000,
            nextToken: null,
            filter: {
              or: [
                { firstName: { contains: searchParamKeyword } },
                { lastName: { contains: searchParamKeyword } },
                { fullName: { contains: searchParamKeyword } },
                { phoneNumber: { contains: searchParamKeyword } },
              ],
            },
          })
        );
        setCustomerData(result.data.listCustomers.items);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();

    const createCustomerSub = API.graphql(
      graphqlOperation(onCreateCustomer, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setCustomerData((prevCustomerData) => [
          ...prevCustomerData,
          value.data.onCreateCustomer,
        ]);
      },
    });

    const updateCustomerSub = API.graphql(
      graphqlOperation(onUpdateCustomer, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setCustomerData((prevCustomerData) => {
          const toUpdateIndex = prevCustomerData.findIndex(
            (item) => item.id === value.data.onUpdateCustomer.id
          );
          if (toUpdateIndex === -1) {
            return [...prevCustomerData, value.data.onUpdateCustomer];
          }
          return [
            ...prevCustomerData.slice(0, toUpdateIndex),
            value.data.onUpdateCustomer,
            ...prevCustomerData.slice(toUpdateIndex + 1),
          ];
        });
      },
    });

    const deleteCustomerSub = API.graphql(
      graphqlOperation(onDeleteCustomer, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setCustomerData((prevCustomerData) => {
          const toDeleteIndex = prevCustomerData.findIndex(
            (item) => item.id === value.data.onDeleteCustomer.id
          );
          return [
            ...prevCustomerData.slice(0, toDeleteIndex),
            ...prevCustomerData.slice(toDeleteIndex + 1),
          ];
        });
      },
    });

    return () => {
      createCustomerSub.unsubscribe();
      updateCustomerSub.unsubscribe();
      deleteCustomerSub.unsubscribe();
    };
  }, [route, searchParamKeyword]);

  return customerData;
};

export default useCustomerData;