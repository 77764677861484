import React from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";

export const Support = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const hasSidebar = () => {
    return route === "authenticated" ? 'contentWithSideBar' : '';
  }

  return (
    <div class={hasSidebar()}>
      <div>Support Content</div>
      <div>Work In Progress</div>
  </div>
  );
}