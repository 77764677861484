import React, { useState, useEffect } from "react";
import RegisterExisting from "./RegisterExisting";
import { API, graphqlOperation } from "aws-amplify";
import RegisterNew from "./RegisterNew";
import styles from "../../styles/Forms.module.css";
import preventEnterSubmit from "./util/preventEnterSubmit";
import { PatternFormat } from "react-number-format";
import { createCustomer } from "../../graphql/mutations";
import { createRecharge } from "../../graphql/mutations";
import { updateCustomer } from "../../graphql/mutations";
import { ToastContainer, toast } from "react-toastify";
import { getCustomer } from "../../graphql/queries";
import { NumericFormat } from "react-number-format";

const RegisterExample = ({ pricingData, userData, expender, customerData }) => {
  //=====================================================================
  const [registerFirstName, setRegisterFirstName] = useState("");
  const [registerLastName, setRegisterLastName] = useState("");
  const [registerPhoneNumber, setRegisterPhoneNumber] = useState("");
  const [registerType, setRegisterType] = useState("");
  const [registeredCustomer, setRegisteredCustomer] = useState("");
  console.log(registeredCustomer);
  console.log(registeredCustomer ? " registered" : "noRegistered");
  //======================================================================
  const [newIceAmount, setNewIceAmount] = useState(0);
  const [newWaterAmount, setNewWaterAmount] = useState(0);
  const [newAlkalineAmount, setNewAlkalineAmount] = useState(0);
  //======================================================================
  const [existingIceAmount, setExistingIceAmount] = useState(0);
  const [existingWaterAmount, setExistingWaterAmount] = useState(0);
  const [existingAlkalineAmount, setExistingAlkalineAmount] = useState(0);
  //======================================================================
  const [cashierPin, setCashierPin] = useState("");
  const [isLoadingCustomerSubmit, setIsLoadingCustomerSubmit] = useState(false);
  const [isLoadingBalanceSubmit, setIsLoadingBalanceSubmit] = useState(false);

  //Twilio Testing +16237032153 is the only verified phone number for testing as of now=====================================================
  // const [verifiedPhoneNumber, setVerifiedPhoneNumber] =
  //   useState("+16237032153");
  // const [isSMSSent, setIsSMSSent] = useState(false);

  // const sendSMS = async (phoneNumber, name, id) => {
  //   try {
  //     const response = await fetch(
  //       `https://hbuxqyjwb8.execute-api.us-west-1.amazonaws.com/dev/myresource?phoneNumber=${phoneNumber}&name=${name}&id=${id}`
  //     );
  //     const jsonData = await response.json();
  //     console.log(jsonData);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   if (registeredCustomer !== "" && !isSMSSent) {
  //     sendSMS(
  //       verifiedPhoneNumber,
  //       registeredCustomer.firstName,
  //       registeredCustomer.id
  //     );
  //     setIsSMSSent(true);
  //   }
  // }, [registeredCustomer, isSMSSent]);

  //non categorized functions ===============================================================================================================
  const phonenumberChecker = customerData.filter(
    (c) => c.phoneNumber === registerPhoneNumber
  );
  const priceOptionMaker = (iceOrWater) => {
    const prices = pricingData.filter((price) => price.type === iceOrWater);
    let arr = [];
    prices.map((option) => {
      arr.push(option.amount);
    });
    return arr.sort(function (a, b) {
      return a - b;
    });
  };
  const icePriceOption = priceOptionMaker("ice");
  const waterPriceOption = priceOptionMaker("water");
  const alkalinePriceOption = priceOptionMaker("alkaline");
  const priceCalculator = (iceOrWater, chosenAmount) => {
    const amount = Number(chosenAmount);
    const yoyo = pricingData.filter((price) => price.type === iceOrWater);
    if (amount !== 0) {
      for (let i = 0; i < yoyo.length; i++) {
        if (yoyo[i].amount === amount) {
          return yoyo[i].price;
        }
      }
    } else {
      return 0;
    }
  };
  const totalCost =
    priceCalculator("ice", newIceAmount) +
    priceCalculator("water", newWaterAmount) +
    priceCalculator("alkaline", newAlkalineAmount);
  const cashierNum = userData.map((e) => e.pin).indexOf(cashierPin);
  const inputedCashier = cashierNum > -1 ? userData[cashierNum].name : "";

  const handlePhoneChange = ({ value }) => {
    setRegisterPhoneNumber(value);
  };

  //Clear functions ========================================================================================================================
  const onCustomerInfoClear = () => {
    document.getElementById("customer-info-form").reset();
    setRegisterFirstName("");
    setRegisterLastName("");
    setRegisterPhoneNumber("");
  };
  const newCustomerFormClear = () => {
    document.getElementById("new-customer-form").reset();
    setCashierPin("");
    setNewIceAmount(0);
    setNewWaterAmount(0);
    setNewAlkalineAmount(0);
  };
  const existingCustomerFormClear = () => {
    setCashierPin("");
    document.getElementById("existing-customer-form").reset();
    setExistingIceAmount(0);
    setExistingWaterAmount(0);
    setExistingAlkalineAmount(0);
  };
  //Submit functions =========================================================================================================================
  const onSubmitCustomerInfo = async () => {
    if (
      registerFirstName === "" ||
      registerLastName === "" ||
      registerPhoneNumber === "" ||
      registerType === ""
    ) {
      toast.error("Please fill in all fields");
    } else if (phonenumberChecker.length > 0) {
      toast.error("This phone number is associated with an existing account.");
    } else if (
      phonenumberChecker.length === 0 &&
      registerFirstName !== "" &&
      registerLastName !== "" &&
      registerPhoneNumber !== "" &&
      registerType !== "" &&
      isLoadingCustomerSubmit === false
    ) {
      try {
        setIsLoadingCustomerSubmit(true);
        const response = await API.graphql(
          graphqlOperation(createCustomer, {
            input: {
              firstName: registerFirstName,
              lastName: registerLastName,
              phoneNumber: registerPhoneNumber,
              fullName: `${registerFirstName} ${registerLastName}`,
              ice: 0,
              water: 0,
              alkaline: 0,
            },
          })
        );
        const newCustomer = response.data.createCustomer;
        const fetchedCustomer = await API.graphql(
          graphqlOperation(getCustomer, { id: newCustomer.id })
        );
        setRegisteredCustomer(fetchedCustomer.data.getCustomer);
        if (registeredCustomer.id !== "") {
          onCustomerInfoClear();
          setIsLoadingCustomerSubmit(false);
          toast.success("New Customer Registered!");
        }
        // toast.success("New Customer Registered!");
      } catch (error) {
        alert("error occured", error);
      }
    } else {
      toast.error("Error");
    }
  };
  //===============================================
  const onSubmitNewCustomer = async () => {
    if (userData.length > 0 && cashierNum < 0) {
      toast.error("No matching Pin");
    } else if (
      newIceAmount === "" &&
      newWaterAmount === "" &&
      newAlkalineAmount === ""
    ) {
      toast.error("Select At least one Plan");
    } else if (
      // if there is single user
      (newIceAmount !== "" ||
        newWaterAmount !== "" ||
        newAlkalineAmount !== "") &&
      registeredCustomer.length !== 0 &&
      userData.length === 0 &&
      isLoadingBalanceSubmit === false
    ) {
      try {
        setIsLoadingBalanceSubmit(true);
        await API.graphql({
          query: createRecharge,
          variables: {
            input: {
              customerID: registeredCustomer.id,
              customerFirstName: registeredCustomer.firstName,
              customerLastName: registeredCustomer.lastName,
              customerPhoneNumber: registeredCustomer.phoneNumber,
              iceAmount: Number(newIceAmount),
              waterAmount: Number(newWaterAmount),
              alkalineAmount: Number(newAlkalineAmount),
            },
          },
        });
        await API.graphql({
          query: updateCustomer,
          variables: {
            input: {
              id: registeredCustomer.id,
              ice: Number(newIceAmount),
              water: Number(newWaterAmount),
              alkaline: Number(newAlkalineAmount),
            },
          },
        });
        setRegisteredCustomer([]);
        newCustomerFormClear();
        setCashierPin("");
        setIsLoadingBalanceSubmit(false);
        toast.success("Transaction successful");
      } catch (error) {
        console.error("error occured", error);
      }
    } else if (
      // if there are multi users
      (newIceAmount !== "" ||
        newWaterAmount !== "" ||
        newAlkalineAmount !== "") &&
      registeredCustomer.length !== 0 &&
      userData.length > 0 &&
      cashierNum > -1 &&
      isLoadingBalanceSubmit === false
    ) {
      try {
        setIsLoadingBalanceSubmit(true);
        await API.graphql({
          query: createRecharge,
          variables: {
            input: {
              customerID: registeredCustomer.id,
              customerFirstName: registeredCustomer.firstName,
              customerLastName: registeredCustomer.lastName,
              customerPhoneNumber: registeredCustomer.phoneNumber,
              iceAmount: Number(newIceAmount),
              waterAmount: Number(newWaterAmount),
              alkalineAmount: Number(newAlkalineAmount),
              cashier: inputedCashier,
            },
          },
        });
        await API.graphql({
          query: updateCustomer,
          variables: {
            input: {
              id: registeredCustomer.id,
              ice: Number(newIceAmount),
              water: Number(newWaterAmount),
              alkaline: Number(newAlkalineAmount),
            },
          },
        });
        setRegisteredCustomer([]);
        newCustomerFormClear();
        setCashierPin("");
        setIsLoadingBalanceSubmit(false);
        toast.success("Transaction successful");
      } catch (error) {
        console.error("error occured", error);
      }
    } else {
      toast.error("Error");
    }
  };
  //====================================================================
  const onSubmitExistingCustomer = async () => {
    if (userData.length > 0 && cashierNum < 0) {
      toast.error("No matching Pin");
    } else if (
      existingIceAmount === "" &&
      existingWaterAmount === "" &&
      existingAlkalineAmount === ""
    ) {
      toast.error("No Balance to Add");
    } else if (
      // For single users
      (existingIceAmount !== "" ||
        existingWaterAmount !== "" ||
        existingAlkalineAmount !== "") &&
      registeredCustomer.length !== 0 &&
      userData.length === 0 &&
      isLoadingBalanceSubmit === false
    ) {
      try {
        setIsLoadingBalanceSubmit(true);
        await API.graphql({
          query: createRecharge,
          variables: {
            input: {
              customerID: registeredCustomer.id,
              customerFirstName: registeredCustomer.firstName,
              customerLastName: registeredCustomer.lastName,
              customerPhoneNumber: registeredCustomer.phoneNumber,
              iceAmount: Number(existingIceAmount),
              waterAmount: Number(existingWaterAmount),
              alkalineAmount: Number(existingAlkalineAmount),
            },
          },
        });
        await API.graphql({
          query: updateCustomer,
          variables: {
            input: {
              id: registeredCustomer.id,
              ice: Number(existingIceAmount),
              water: Number(existingWaterAmount),
              alkaline: Number(existingAlkalineAmount),
            },
          },
        });
        existingCustomerFormClear();
        setRegisteredCustomer([]);
        setCashierPin("");
        setIsLoadingBalanceSubmit(false);
        toast.success("Balance transfer successful");
      } catch (error) {
        console.error("error occured", error);
      }
    } else if (
      //for multi users
      (existingIceAmount !== "" ||
        existingWaterAmount !== "" ||
        existingAlkalineAmount !== "") &&
      registeredCustomer.length !== 0 &&
      userData.length > 0 &&
      cashierNum > -1 &&
      isLoadingBalanceSubmit === false
    ) {
      try {
        setIsLoadingBalanceSubmit(true);
        API.graphql({
          query: createRecharge,
          variables: {
            input: {
              customerID: registeredCustomer.id,
              customerFirstName: registeredCustomer.firstName,
              customerLastName: registeredCustomer.lastName,
              customerPhoneNumber: registeredCustomer.phoneNumber,
              iceAmount: Number(existingIceAmount),
              waterAmount: Number(existingWaterAmount),
              alkalineAmount: Number(existingAlkalineAmount),
              cashier: inputedCashier,
            },
          },
        });
        API.graphql({
          query: updateCustomer,
          variables: {
            input: {
              id: registeredCustomer.id,
              ice: Number(existingIceAmount),
              water: Number(existingWaterAmount),
              alkaline: Number(existingAlkalineAmount),
            },
          },
        });
        existingCustomerFormClear();
        setRegisteredCustomer([]);
        setCashierPin("");
        setIsLoadingBalanceSubmit(false);
        toast.success("Balance transfer successful");
      } catch (error) {
        console.error("error occured", error);
      }
    } else {
      toast.error("Error");
    }
  };
  //=========================================================================================================================================
  return (
    <div class={expender ? "contentWithSideBarThin" : "contentWithSideBar"}>
      <div class="pageTitle">
        <h1>Customer Registration</h1>
      </div>
      {/* <div class="pageTitle">
        <div class="titleWithIcon">
          <h2 class="iconInTitle">
            <i class="fa-solid fa-file-circle-plus"></i>
          </h2>
          <h2>Customer Forms</h2>
        </div>
      </div> */}
      {/* registration content start here */}
      <div class="contentContainer">
        <div>
          <h3>New/Existing Customer Registration</h3>
          <div>
            Sign up customer with prepaid card or transfer balance from other
            forms.
          </div>
          <br />
          <div>
            Select the "New" type when registering a customer with a prepaid
            price plan and no existing balance.
          </div>
          <div>
            Select "Existing" type when transferring an existing balance.
            Recharge any additional balance from the "Customers List" tab if
            needed.
          </div>
        </div>
        {registeredCustomer.length === 0 ? (
          <>
            <form id="customer-info-form">
              <div className={styles.divider}></div>
              {/* Customer Information Input Section */}
              <div>
                <h4>Customer Info</h4>
                <div className={styles.customerTypeSelect}>
                  <div className="rightMargin">Type: </div>
                  <select onChange={(e) => setRegisterType(e.target.value)}>
                    <option value="" selected>
                      Select type
                    </option>
                    <option value="new">New</option>
                    <option value="existing">Existing</option>
                  </select>
                </div>
                <div className={styles.textInputContainer}>
                  <div>First Name</div>
                  <div>
                    <input
                      onKeyUp={(e) => preventEnterSubmit(e)}
                      placeholder="John"
                      onChange={(e) => setRegisterFirstName(e.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.textInputContainer}>
                  <div>Last Name</div>
                  <div>
                    <input
                      onKeyUp={(e) => preventEnterSubmit(e)}
                      placeholder="Doe"
                      onChange={(e) => setRegisterLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.textInputContainer}>
                  <div>Phone Number</div>
                  {/* TODO: */}
                  <PatternFormat
                    onKeyUp={(e) => preventEnterSubmit(e)}
                    placeholder="(123) 456-7890"
                    format="(%%%) %%%-%%%%"
                    patternChar="%"
                    onValueChange={handlePhoneChange}
                  />
                </div>
              </div>
            </form>
            <div className={styles.formBtnContainer}>
              {isLoadingCustomerSubmit ? (
                ""
              ) : (
                <div
                  className={styles.formBtnReset}
                  onClick={() => onCustomerInfoClear()}
                >
                  Clear
                </div>
              )}
              {isLoadingCustomerSubmit ? (
                <div className={styles.formBtnSubmitLoading}>
                  <div class="loader"></div>
                </div>
              ) : (
                <div
                  className={styles.formBtnSubmit}
                  onClick={() => onSubmitCustomerInfo()}
                >
                  Submit
                </div>
              )}
            </div>
          </>
        ) : (
          ""
        )}
        {/* New Customer Form ==================================================================== */}
        {registeredCustomer.length !== 0 && registerType === "new" ? (
          <>
            <div className={styles.divider}></div>
            <h1>
              {registeredCustomer.firstName} {registeredCustomer.lastName}
            </h1>
            <form id="new-customer-form">
              <h4>Select Plan(s)</h4>
              {/* Ice */}
              {icePriceOption.length > 0 ? (
                <div className={styles.InputContainer}>
                  <div className={styles.labelContainer}>
                    <div className="iconIce">
                      <i class="fa-solid fa-dice-d6"></i>
                    </div>
                    <div className={styles.iconDesc}>Ice</div>
                    <div class="hiddenForDesktop">
                      <div className={styles.iconDesc}>(lb)</div>
                    </div>
                  </div>
                  <div className={styles.planSelect}>
                    <select onChange={(e) => setNewIceAmount(e.target.value)}>
                      <option value="" selected>
                        Select Plan
                      </option>
                      {icePriceOption.map((option) => {
                        return <option value={option}>{option} lb</option>;
                      })}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* Water  */}
              {waterPriceOption.length > 0 ? (
                <div className={styles.InputContainer}>
                  <div className={styles.labelContainer}>
                    <div className="iconWater">
                      <i class="fa-solid fa-droplet"></i>
                    </div>
                    <div className={styles.iconDesc}>Water</div>
                    <div class="hiddenForDesktop">
                      <div className={styles.iconDesc}>(gal)</div>
                    </div>
                  </div>
                  <div className={styles.planSelect}>
                    <select onChange={(e) => setNewWaterAmount(e.target.value)}>
                      <option value="" selected>
                        Select Plan
                      </option>
                      {waterPriceOption.map((option) => {
                        return <option value={option}>{option} gal</option>;
                      })}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* Alkaline */}
              {alkalinePriceOption.length > 0 ? (
                <div className={styles.InputContainer}>
                  <div className={styles.labelContainer}>
                    <div className="iconAlkaline">
                      <i class="fa-solid fa-droplet"></i>
                    </div>
                    <div className={styles.iconDesc}>Alkaline</div>
                    <div class="hiddenForDesktop">
                      <div className={styles.iconDesc}>(gal)</div>
                    </div>
                  </div>
                  <div className={styles.planSelect}>
                    <select
                      onChange={(e) => setNewAlkalineAmount(e.target.value)}
                    >
                      <option value="" selected>
                        Select Plan
                      </option>
                      {alkalinePriceOption.map((option) => {
                        return <option value={option}>{option} gal</option>;
                      })}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
            </form>
            {/* Summary Section */}
            <div className={styles.divider}></div>
            <h4>Summary</h4>
            <div className={styles.rechargeSummaryContainer}>
              <div>
                {registeredCustomer.firstName} {registeredCustomer.lastName}
              </div>
              <div className={styles.summaryDivider}></div>
              {newIceAmount > 0 ? (
                <div className={styles.rechargeSummary}>
                  <div className={styles.costType}>
                    <div class="badgeIce">Ice: {newIceAmount}</div>
                    <div>Cost: $</div>
                  </div>
                  <div className={styles.costContainer}>
                    {priceCalculator("ice", newIceAmount)}.00
                  </div>
                </div>
              ) : (
                ""
              )}
              {newWaterAmount > 0 ? (
                <div className={styles.rechargeSummary}>
                  <div className={styles.costType}>
                    <div class="badgeWater">Water: {newWaterAmount}</div>
                    <div>Cost: $</div>
                  </div>
                  <div className={styles.costContainer}>
                    {priceCalculator("water", newWaterAmount)}.00
                  </div>
                </div>
              ) : (
                ""
              )}
              {newAlkalineAmount > 0 ? (
                <div className={styles.rechargeSummary}>
                  <div className={styles.costType}>
                    <div class="badgeAlkaline">
                      Alkaline: {newAlkalineAmount}
                    </div>
                    <div>Cost: $</div>
                  </div>
                  <div className={styles.costContainer}>
                    {priceCalculator("alkaline", newAlkalineAmount)}
                    .00
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className={styles.summaryDivider}></div>
              <div className={styles.rechargeSummary}>
                <div className={styles.costType}>Total Cost: $</div>
                <div className={styles.costContainer}>{totalCost}.00</div>
              </div>
            </div>
            {userData.length > 0 ? (
              <>
                <div className={styles.divider}></div>
                <div className={styles.pinInputContainer}>
                  <div>
                    <i class="fa-solid fa-key"></i> Pin:
                  </div>
                  <div>
                    {/* <input
                      onChange={(e) => setCashierPin(e.target.value)}
                      onKeyUp={(e) => preventEnterSubmit(e)}
                      className={styles.pinInput}
                      placeholder="****"
                    /> */}
                    <NumericFormat
                      className={styles.pinInput}
                      allowLeadingZeros
                      onKeyUp={(e) => preventEnterSubmit(e)}
                      type="password"
                      format="####"
                      mask="_"
                      placeholder="****"
                      allowEmptyFormatting
                      minlength="4"
                      maxlength="8"
                      autocomplete="new-password"
                      onChange={(e) => setCashierPin(e.target.value)}
                      value={cashierPin}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {/* Submit Section */}
            <div className={styles.divider}></div>
            <div className={styles.formBtnContainer}>
              {isLoadingBalanceSubmit ? (
                ""
              ) : (
                <div
                  className={styles.formBtnReset}
                  onClick={() => setRegisteredCustomer([])}
                >
                  Skip (No Plan)
                </div>
              )}
              {isLoadingBalanceSubmit ? (
                ""
              ) : (
                <div
                  className={styles.formBtnReset}
                  onClick={() => newCustomerFormClear()}
                >
                  Clear
                </div>
              )}
              {isLoadingBalanceSubmit ? (
                <div className={styles.formBtnSubmitLoading}>
                  <div class="loader"></div>
                </div>
              ) : (
                <div
                  className={styles.formBtnSubmit}
                  onClick={() => onSubmitNewCustomer()}
                >
                  Add Balance
                </div>
              )}
            </div>
          </>
        ) : (
          ""
        )}
        {/* Existing Customer Form =============================================================== */}
        {registeredCustomer.length !== 0 && registerType === "existing" ? (
          <>
            <div className={styles.divider}></div>
            <h1>
              {registeredCustomer.firstName} {registeredCustomer.lastName}
            </h1>
            <h4>Input Existing Balance</h4>
            <form id="existing-customer-form">
              {/* Ice ===================================*/}
              <div className={styles.InputContainer}>
                <div className={styles.labelContainer}>
                  <div className="iconIce">
                    <i class="fa-solid fa-dice-d6"></i>
                  </div>
                  <div className={styles.iconDesc}>Ice</div>
                  <div class="hiddenForDesktop">
                    <div className={styles.iconDesc}>(lb)</div>
                  </div>
                </div>
                <div>
                  <div className={styles.numberInput}>
                    <div class="hiddenForMobile">Add in lb</div>
                    <div
                      className="iconPlusMinus"
                      onClick={() =>
                        existingIceAmount > 0
                          ? setExistingIceAmount(existingIceAmount - 1)
                          : setExistingIceAmount(0)
                      }
                    >
                      <i class="fa-solid fa-minus"></i>
                    </div>
                    {/* TODO: use this below*/}
                    <input
                      type="number"
                      onKeyUp={(e) => preventEnterSubmit(e)}
                      value={existingIceAmount === 0 ? "" : existingIceAmount}
                      placeholder="0"
                      onChange={(e) => {
                        setExistingIceAmount(e.target.value);
                      }}
                    />
                    <div
                      className="iconPlusMinus"
                      onClick={() =>
                        setExistingIceAmount(existingIceAmount + 1)
                      }
                    >
                      <i class="fa-solid fa-plus"></i>
                    </div>
                  </div>
                </div>
              </div>
              {/* Water ===================================*/}
              <div className={styles.InputContainer}>
                <div className={styles.labelContainer}>
                  <div className="iconWater">
                    <i class="fa-solid fa-droplet"></i>
                  </div>
                  <div className={styles.iconDesc}>Water</div>
                  <div class="hiddenForDesktop">
                    <div className={styles.iconDesc}>(gal)</div>
                  </div>
                </div>
                <div>
                  <div className={styles.numberInput}>
                    <div class="hiddenForMobile">Add in gal</div>
                    <div
                      className="iconPlusMinus"
                      onClick={() =>
                        existingWaterAmount > 0
                          ? setExistingWaterAmount(existingWaterAmount - 1)
                          : setExistingWaterAmount(0)
                      }
                    >
                      <i class="fa-solid fa-minus"></i>
                    </div>
                    <input
                      type="number"
                      onKeyUp={(e) => preventEnterSubmit(e)}
                      value={
                        existingWaterAmount === 0 ? "" : existingWaterAmount
                      }
                      placeholder="0"
                      onChange={(e) => {
                        setExistingWaterAmount(e.target.value);
                      }}
                    />
                    <div
                      className="iconPlusMinus"
                      onClick={() =>
                        setExistingWaterAmount(existingWaterAmount + 1)
                      }
                    >
                      <i class="fa-solid fa-plus"></i>
                    </div>
                  </div>
                </div>
              </div>
              {/* Alkaline ===================================*/}
              <div className={styles.InputContainer}>
                <div className={styles.labelContainer}>
                  <div className="iconAlkaline">
                    <i class="fa-solid fa-droplet"></i>
                  </div>
                  <div className={styles.iconDesc}>Alkaline</div>
                  <div class="hiddenForDesktop">
                    <div className={styles.iconDesc}>(gal)</div>
                  </div>
                </div>
                <div>
                  <div className={styles.numberInput}>
                    <div class="hiddenForMobile">Add in gal</div>
                    <div
                      className="iconPlusMinus"
                      onClick={() =>
                        existingAlkalineAmount > 0
                          ? setExistingAlkalineAmount(
                              existingAlkalineAmount - 1
                            )
                          : setExistingAlkalineAmount(0)
                      }
                    >
                      <i class="fa-solid fa-minus"></i>
                    </div>
                    <input
                      type="number"
                      onKeyUp={(e) => preventEnterSubmit(e)}
                      value={
                        existingAlkalineAmount === 0
                          ? ""
                          : existingAlkalineAmount
                      }
                      placeholder="0"
                      onChange={(e) => {
                        setExistingAlkalineAmount(e.target.value);
                      }}
                    />
                    <div
                      className="iconPlusMinus"
                      onClick={() =>
                        setExistingAlkalineAmount(existingAlkalineAmount + 1)
                      }
                    >
                      <i class="fa-solid fa-plus"></i>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* Summary Section */}
            <div className={styles.divider}></div>
            <h4>Summary</h4>
            <div className={styles.rechargeSummaryContainer}>
              <div>
                {registerFirstName} {registerLastName}
              </div>
              <div className={styles.summaryDivider}></div>
              {existingIceAmount > 0 ? (
                <div className={styles.rechargeSummary}>
                  <div className={styles.costType}>
                    <div class="badgeIce">Ice: {existingIceAmount} lb</div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {existingWaterAmount > 0 ? (
                <div className={styles.rechargeSummary}>
                  <div className={styles.costType}>
                    <div class="badgeWater">
                      Water: {existingWaterAmount} gal
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {existingAlkalineAmount > 0 ? (
                <div className={styles.rechargeSummary}>
                  <div className={styles.costType}>
                    <div class="badgeAlkaline">
                      Alkaline: {existingAlkalineAmount} gal
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {userData.length > 0 ? (
              <>
                <div className={styles.divider}></div>
                <div className={styles.pinInputContainer}>
                  <div>
                    <i class="fa-solid fa-key"></i> Pin:
                  </div>
                  <div>
                    {/* <input
                      onChange={(e) => setCashierPin(e.target.value)}
                      onKeyUp={(e) => preventEnterSubmit(e)}
                      className={styles.pinInput}
                      placeholder="****"
                    /> */}
                    <NumericFormat
                      className={styles.pinInput}
                      allowLeadingZeros
                      onKeyUp={(e) => preventEnterSubmit(e)}
                      type="password"
                      format="####"
                      mask="_"
                      placeholder="****"
                      allowEmptyFormatting
                      minlength="4"
                      maxlength="8"
                      autocomplete="new-password"
                      onChange={(e) => setCashierPin(e.target.value)}
                      value={cashierPin}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {/* Submit Section */}
            <div className={styles.divider}></div>
            <div className={styles.formBtnContainer}>
              {isLoadingBalanceSubmit ? (
                ""
              ) : (
                <div
                  className={styles.formBtnReset}
                  onClick={() => setRegisteredCustomer([])}
                >
                  Skip (No Plan)
                </div>
              )}
              {isLoadingBalanceSubmit ? (
                ""
              ) : (
                <div
                  className={styles.formBtnReset}
                  onClick={() => existingCustomerFormClear()}
                >
                  Clear
                </div>
              )}
              {isLoadingBalanceSubmit ? (
                <div className={styles.formBtnSubmitLoading}>
                  <div class="loader"></div>
                </div>
              ) : (
                <div
                  className={styles.formBtnSubmit}
                  onClick={() => onSubmitExistingCustomer()}
                >
                  Add Balance
                </div>
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      {/* <RegisterNew
        pricingData={pricingData}
        userData={userData}
        customerData={customerData}
      />
      <RegisterExisting
        pricingData={pricingData}
        userData={userData}
        customerData={customerData}
      /> */}
      <ToastContainer />
    </div>
  );
};

export default RegisterExample;
