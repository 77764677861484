import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { createTransaction } from "../../graphql/mutations";
import { updateCustomer } from "../../graphql/mutations";
import { ToastContainer, toast } from "react-toastify";
import toggle from "./util/toggle";
import "react-toastify/dist/ReactToastify.css";
import styles from "../../styles/Forms.module.css";
import preventEnterSubmit from "./util/preventEnterSubmit";
import { NumericFormat } from "react-number-format";
import { PatternFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";

const TransactionUI = ({
  chosenCustomer,
  setChosenCustomer,
  payButtonUIActive,
  setPayButtonUIActive,
  userData,
  fetchCustomers,
}) => {
  const [iceDeductionAmount, setIceDeductionAmount] = useState(0);
  const [waterDeductionAmount, setWaterDeductionAmount] = useState(0);
  const [alkalineDeductionAmount, setAlkalineDeductionAmount] = useState(0);
  const [cashierPin, setCashierPin] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const customerID = chosenCustomer.id;
  const firstName = chosenCustomer.firstName;
  const lastName = chosenCustomer.lastName;
  const phoneNumber = chosenCustomer.phoneNumber;
  const remainingIce = chosenCustomer.ice - iceDeductionAmount;
  const remainingWater = chosenCustomer.water - waterDeductionAmount;
  const remainingAlkaline = chosenCustomer.alkaline - alkalineDeductionAmount;
  const navigate = useNavigate();

  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAllUsers(userData);
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };
    fetchData();
  }, []);

  const cashierNum = allUsers.map((e) => e.pin).indexOf(cashierPin);
  const inputedCashier =
    cashierNum > -1 ? allUsers[cashierNum].name : "notFound";

  const selectedSummary = (amount) => {
    return amount > 0 ? styles.red : styles.notRed;
  };

  const onTransactionClear = () => {
    document.getElementById("transaction-form").reset();
    setCashierPin("");
    setIceDeductionAmount(0);
    setWaterDeductionAmount(0);
    setAlkalineDeductionAmount(0);
  };

  const onCancel = () => {
    toggle(payButtonUIActive, setPayButtonUIActive);
    setChosenCustomer("");
    navigate("/customer");
  };
  // =============================================================
  const onSubmitTransaction = async () => {
    if (
      iceDeductionAmount > chosenCustomer.ice ||
      waterDeductionAmount > chosenCustomer.water ||
      alkalineDeductionAmount > chosenCustomer.alkaline
    ) {
      toast.error("insufficient balance");
    } else if (
      (iceDeductionAmount > 0 ||
        waterDeductionAmount > 0 ||
        alkalineDeductionAmount > 0) &&
      userData.length > 0 &&
      inputedCashier === "notFound"
    ) {
      toast.error("No Existing User Pin");
    } else if (
      (iceDeductionAmount > 0 ||
        waterDeductionAmount > 0 ||
        alkalineDeductionAmount > 0) &&
      inputedCashier === "notFound" &&
      userData.length === 0 &&
      isLoading === false
    ) {
      try {
        setIsLoading(true);
        await API.graphql({
          query: createTransaction,
          variables: {
            input: {
              customerID: customerID,
              customerFirstName: firstName,
              customerLastName: lastName,
              customerPhoneNumber: phoneNumber,
              iceAmount: iceDeductionAmount,
              waterAmount: waterDeductionAmount,
              alkalineAmount: alkalineDeductionAmount,
            },
          },
        });
        await API.graphql({
          query: updateCustomer,
          variables: {
            input: {
              id: customerID,
              ice: remainingIce,
              water: remainingWater,
              alkaline: remainingAlkaline,
            },
          },
        });
        setPayButtonUIActive(false);
        setIsLoading(false);
        toast.success("Transaction successful");
        await fetchCustomers();
      } catch (error) {
        console.error("error occured", error);
      }
    } else if (
      (iceDeductionAmount > 0 ||
        waterDeductionAmount > 0 ||
        alkalineDeductionAmount > 0) &&
      inputedCashier !== "notFound" &&
      isLoading === false
    ) {
      try {
        setIsLoading(true);
        await API.graphql({
          query: createTransaction,
          variables: {
            input: {
              customerID: customerID,
              customerFirstName: firstName,
              customerLastName: lastName,
              customerPhoneNumber: phoneNumber,
              iceAmount: iceDeductionAmount,
              waterAmount: waterDeductionAmount,
              alkalineAmount: alkalineDeductionAmount,
              cashier: inputedCashier,
            },
          },
        });
        await API.graphql({
          query: updateCustomer,
          variables: {
            input: {
              id: customerID,
              ice: remainingIce,
              water: remainingWater,
              alkaline: remainingAlkaline,
            },
          },
        });
        setPayButtonUIActive(false);
        setIsLoading(false);
        toast.success("Transaction successful");
        await fetchCustomers();
      } catch (error) {
        console.error("error occured", error);
      }
    } else {
      toast.error("No deduct amount found.");
    }
  };
  // =============================================================
  return (
    <>
      <div className={styles.heading}>
        <h3>Subtract Balance</h3>
        <h3 class="button" onClick={() => onCancel()}>
          <i class="fa-solid fa-x"></i>
        </h3>
      </div>
      <form id="transaction-form">
        {/* customer Info ======================================*/}
        <div className={styles.customerInfo}>
          <div className={styles.label}>Customer</div>
          <div className={styles.label}>
            <div class="hiddenForMobile">Balance</div>
          </div>
        </div>
        <div className={styles.customerInfo}>
          <div>
            <h1>
              {chosenCustomer.firstName} {chosenCustomer.lastName}
            </h1>
          </div>
          <div className={styles.badgeHolder}>
            {/* <div class="badgeIce">Ice: {chosenCustomer.ice}</div> */}
            <div class="badgeWater">Water: {chosenCustomer.water}</div>
            <div class="badgeAlkaline">Alkaline: {chosenCustomer.alkaline}</div>
          </div>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.customerInfo}>
          <div className={styles.label}>Select Usage Amount</div>
          <input class="hidden" />
          <div className={styles.label}></div>
        </div>
        {/* ice number input ===================================*/}
        {/* <div className={styles.InputContainer}>
          <div className={styles.labelContainer}>
            <div className="iconIce">
              <i class="fa-solid fa-dice-d6"></i>
            </div>
            <div className={styles.iconDesc}>Ice</div>
            <div class="hiddenForDesktop">
              <div className={styles.iconDesc}>(lb)</div>
            </div>
          </div>
          {chosenCustomer.ice > 0 ? (
            <>
              <div>
                <div className={styles.numberInput}>
                  <div class="hiddenForMobile">Deduct in lb</div>
                  <div
                    className="iconPlusMinus"
                    onClick={() =>
                      setIceDeductionAmount(
                        iceDeductionAmount > 0 ? iceDeductionAmount - 1 : 0
                      )
                    }
                  >
                    <i class="fa-solid fa-minus"></i>
                  </div>
                  <input
                    type="number"
                    min="0"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    title="Non-negative integral number"
                    onKeyUp={(e) => preventEnterSubmit(e)}
                    value={iceDeductionAmount === 0 ? "" : iceDeductionAmount}
                    placeholder="0"
                    onChange={(e) =>
                      Number(e.target.value) > chosenCustomer.ice
                        ? setIceDeductionAmount(chosenCustomer.ice)
                        : setIceDeductionAmount(Number(e.target.value))
                    }
                  />
                  <div
                    className="iconPlusMinus"
                    onClick={() =>
                      iceDeductionAmount < chosenCustomer.ice
                        ? setIceDeductionAmount(iceDeductionAmount + 1)
                        : setIceDeductionAmount(iceDeductionAmount)
                    }
                  >
                    <i class="fa-solid fa-plus"></i>
                  </div>
                </div>
                <div className={styles.max}>Max: {chosenCustomer.ice}</div>
              </div>
            </>
          ) : (
            <div>No Current Balance</div>
          )}
        </div> */}
        {/* water number input ===================================*/}
        <div className={styles.InputContainer}>
          <div className={styles.labelContainer}>
            <div className="iconWater">
              <i class="fa-solid fa-droplet"></i>
            </div>
            <div className={styles.iconDesc}>Water</div>
            <div class="hiddenForDesktop">
              <div className={styles.iconDesc}>(gal)</div>
            </div>
          </div>
          {chosenCustomer.water > 0 ? (
            <>
              <div>
                <div className={styles.numberInput}>
                  <div class="hiddenForMobile">Deduct amount in gal</div>
                  <div
                    className="iconPlusMinus"
                    onClick={() =>
                      setWaterDeductionAmount(
                        waterDeductionAmount > 0 ? waterDeductionAmount - 1 : 0
                      )
                    }
                  >
                    <i class="fa-solid fa-minus"></i>
                  </div>
                  <div>
                    <input
                      type="number"
                      min="0"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      title="Non-negative integral number"
                      onKeyUp={(e) => preventEnterSubmit(e)}
                      value={
                        waterDeductionAmount === 0 ? "" : waterDeductionAmount
                      }
                      placeholder="0"
                      onChange={
                        (e) => setWaterDeductionAmount(Number(e.target.value))
                        // Number(e.target.value) > chosenCustomer.water
                        //   ? setWaterDeductionAmount(chosenCustomer.water)
                        //   : setWaterDeductionAmount(Number(e.target.value))
                      }
                    />
                    <div className={styles.max}>
                      Max: {chosenCustomer.water}
                    </div>
                  </div>
                  <div
                    className="iconPlusMinus"
                    onClick={() =>
                      waterDeductionAmount < chosenCustomer.water
                        ? setWaterDeductionAmount(waterDeductionAmount + 1)
                        : setWaterDeductionAmount(waterDeductionAmount)
                    }
                  >
                    <i class="fa-solid fa-plus"></i> 1
                  </div>
                  <div
                    className="iconPlusMinus"
                    onClick={() =>
                      waterDeductionAmount < chosenCustomer.water
                        ? setWaterDeductionAmount(waterDeductionAmount + 5)
                        : setWaterDeductionAmount(waterDeductionAmount)
                    }
                  >
                    <i class="fa-solid fa-plus"></i> 5
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>No Current Balance</div>
          )}
        </div>
        {/* alkaine number input ===================================*/}
        <div className={styles.InputContainer}>
          <div className={styles.labelContainer}>
            <div className="iconAlkaline">
              <i class="fa-solid fa-droplet"></i>
            </div>
            <div className={styles.iconDesc}>Alkaline</div>
            <div class="hiddenForDesktop">
              <div className={styles.iconDesc}>(gal)</div>
            </div>
          </div>
          {chosenCustomer.alkaline > 0 ? (
            <>
              <div>
                <div className={styles.numberInput}>
                  <div class="hiddenForMobile">Deduct amount in gal</div>
                  <div
                    className="iconPlusMinus"
                    onClick={() =>
                      setAlkalineDeductionAmount(
                        alkalineDeductionAmount > 0
                          ? alkalineDeductionAmount - 1
                          : 0
                      )
                    }
                  >
                    <i class="fa-solid fa-minus"></i>
                  </div>
                  <div>
                    <input
                      type="number"
                      min="0"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      title="Non-negative integral number"
                      onKeyUp={(e) => preventEnterSubmit(e)}
                      value={
                        alkalineDeductionAmount === 0
                          ? ""
                          : alkalineDeductionAmount
                      }
                      placeholder="0"
                      onChange={
                        (e) =>
                          setAlkalineDeductionAmount(Number(e.target.value))
                        // Number(e.target.value) > chosenCustomer.alkaline
                        //   ? setAlkalineDeductionAmount(chosenCustomer.alkaline)
                        //   : setAlkalineDeductionAmount(Number(e.target.value))
                      }
                    />
                    <div className={styles.max}>
                      Max: {chosenCustomer.alkaline}
                    </div>
                  </div>
                  <div
                    className="iconPlusMinus"
                    onClick={() =>
                      alkalineDeductionAmount < chosenCustomer.alkaline
                        ? setAlkalineDeductionAmount(
                            alkalineDeductionAmount + 1
                          )
                        : setAlkalineDeductionAmount(alkalineDeductionAmount)
                    }
                  >
                    <i class="fa-solid fa-plus"></i> 1
                  </div>
                  <div
                    className="iconPlusMinus"
                    onClick={() =>
                      alkalineDeductionAmount < chosenCustomer.alkaline
                        ? setAlkalineDeductionAmount(
                            alkalineDeductionAmount + 5
                          )
                        : setAlkalineDeductionAmount(alkalineDeductionAmount)
                    }
                  >
                    <i class="fa-solid fa-plus"></i> 5
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>No Current Balance</div>
          )}
        </div>
        <div className={styles.divider}></div>
        {/* Summary ==============================================*/}
        {/* <div className={styles.customerInfo}>
          <div className={styles.label}>Summary</div>
          <div className={styles.label}></div>
        </div>
        <div className={styles.summary}>
          <div>Summary</div>
          <div>Selected</div>
          <div>Remaining</div>
        </div>
        <div className={styles.summary}>
          <div>
            <div>Ice</div>
            <div>Water</div>
            <div>Alkaline</div>
          </div>
          <div>
            <div className={selectedSummary(iceDeductionAmount)}>
              {iceDeductionAmount > 0
                ? `-${iceDeductionAmount} lb`
                : `${iceDeductionAmount} lb`}
            </div>
            <div className={selectedSummary(waterDeductionAmount)}>
              {waterDeductionAmount > 0
                ? `-${waterDeductionAmount} gal`
                : `${waterDeductionAmount} gal`}
            </div>
            <div className={selectedSummary(alkalineDeductionAmount)}>
              {alkalineDeductionAmount > 0
                ? `-${alkalineDeductionAmount} gal`
                : `${alkalineDeductionAmount} gal`}
            </div>
          </div>
          <div>
            <div>{remainingIce} lb</div>
            <div>{remainingWater} gal</div>
            <div>{remainingAlkaline} gal</div>
          </div>
        </div> */}
        {/* ========================================================= */}
        <div className={styles.rechargeSummaryContainer}>
          {/* {iceDeductionAmount > 0 ? (
            <div className={styles.rechargeSummary}>
              <div className={styles.costType}>
                <div class="badgeIce">Ice</div>
              </div>
              <div className={styles.costContainer}>
                <div className={styles.red}>- {iceDeductionAmount} lb</div>
              </div>
            </div>
          ) : (
            ""
          )} */}
          {waterDeductionAmount > 0 ? (
            <div className={styles.rechargeSummary}>
              <div className={styles.costType}>
                <div class="badgeWater">Water</div>
              </div>
              <div className={styles.costContainer}>
                <div className={styles.red}>- {waterDeductionAmount} gal</div>
              </div>
            </div>
          ) : (
            ""
          )}
          {alkalineDeductionAmount > 0 ? (
            <div className={styles.rechargeSummary}>
              <div className={styles.costType}>
                <div class="badgeAlkaline">Alkaline</div>
              </div>
              <div className={styles.costContainer}>
                <div className={styles.red}>
                  - {alkalineDeductionAmount} gal
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* ========================================================= */}
        {userData.length > 0 ? (
          <>
            {/* <div className={styles.divider}></div> */}
            <div className={styles.pinInputContainer}>
              <div>
                <i class="fa-solid fa-key"></i> Pin:
              </div>
              <div>
                {/* <input
                  onChange={(e) => setCashierPin(e.target.value)}
                  onKeyUp={(e) => preventEnterSubmit(e)}
                  className={styles.pinInput}
                  placeholder="****"
                /> */}
                <NumericFormat
                  className={styles.pinInput}
                  allowLeadingZeros
                  onKeyUp={(e) => preventEnterSubmit(e)}
                  type="password"
                  format="####"
                  mask="_"
                  placeholder="****"
                  allowEmptyFormatting
                  minlength="4"
                  maxlength="8"
                  autocomplete="new-password"
                  onChange={(e) => setCashierPin(e.target.value)}
                  value={cashierPin}
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className={styles.divider}></div>
      </form>
      {/* Reset and Clear ==============================================*/}
      <div className={styles.formBtnContainer}>
        {isLoading ? (
          ""
        ) : (
          <div className={styles.formBtnReset} onClick={() => onCancel()}>
            Cancel
          </div>
        )}
        {isLoading ? (
          ""
        ) : (
          <div
            className={styles.formBtnReset}
            onClick={() => onTransactionClear()}
          >
            Clear
          </div>
        )}
        {isLoading ? (
          <div className={styles.formBtnSubmitLoading}>
            <div class="loader"></div>
          </div>
        ) : (
          <div
            className={styles.formBtnSubmit}
            onClick={() => onSubmitTransaction()}
          >
            Submit
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default TransactionUI;
