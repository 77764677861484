/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer(
    $filter: ModelSubscriptionCustomerFilterInput
    $owner: String
  ) {
    onCreateCustomer(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      ice
      water
      alkaline
      iceBagType1
      iceBagType2
      iceBagType3
      iceBlockType1
      iceBlockType2
      iceBlockType3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer(
    $filter: ModelSubscriptionCustomerFilterInput
    $owner: String
  ) {
    onUpdateCustomer(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      ice
      water
      alkaline
      iceBagType1
      iceBagType2
      iceBagType3
      iceBlockType1
      iceBlockType2
      iceBlockType3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer(
    $filter: ModelSubscriptionCustomerFilterInput
    $owner: String
  ) {
    onDeleteCustomer(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      ice
      water
      alkaline
      iceBagType1
      iceBagType2
      iceBagType3
      iceBlockType1
      iceBlockType2
      iceBlockType3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateCustomerTest = /* GraphQL */ `
  subscription OnCreateCustomerTest(
    $filter: ModelSubscriptionCustomerTestFilterInput
    $owner: String
  ) {
    onCreateCustomerTest(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      water
      alkaline
      iceBagType1
      iceBagType2
      iceBagType3
      iceBlockType1
      iceBlockType2
      iceBlockType3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateCustomerTest = /* GraphQL */ `
  subscription OnUpdateCustomerTest(
    $filter: ModelSubscriptionCustomerTestFilterInput
    $owner: String
  ) {
    onUpdateCustomerTest(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      water
      alkaline
      iceBagType1
      iceBagType2
      iceBagType3
      iceBlockType1
      iceBlockType2
      iceBlockType3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteCustomerTest = /* GraphQL */ `
  subscription OnDeleteCustomerTest(
    $filter: ModelSubscriptionCustomerTestFilterInput
    $owner: String
  ) {
    onDeleteCustomerTest(filter: $filter, owner: $owner) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      water
      alkaline
      iceBagType1
      iceBagType2
      iceBagType3
      iceBlockType1
      iceBlockType2
      iceBlockType3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreatePricing = /* GraphQL */ `
  subscription OnCreatePricing(
    $filter: ModelSubscriptionPricingFilterInput
    $owner: String
  ) {
    onCreatePricing(filter: $filter, owner: $owner) {
      id
      type
      amount
      price
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdatePricing = /* GraphQL */ `
  subscription OnUpdatePricing(
    $filter: ModelSubscriptionPricingFilterInput
    $owner: String
  ) {
    onUpdatePricing(filter: $filter, owner: $owner) {
      id
      type
      amount
      price
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeletePricing = /* GraphQL */ `
  subscription OnDeletePricing(
    $filter: ModelSubscriptionPricingFilterInput
    $owner: String
  ) {
    onDeletePricing(filter: $filter, owner: $owner) {
      id
      type
      amount
      price
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateIceProduct = /* GraphQL */ `
  subscription OnCreateIceProduct(
    $filter: ModelSubscriptionIceProductFilterInput
    $owner: String
  ) {
    onCreateIceProduct(filter: $filter, owner: $owner) {
      id
      iceBagSize1
      iceBagSize2
      iceBagSize3
      iceBlockSize1
      iceBlockSize2
      iceBlockSize3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateIceProduct = /* GraphQL */ `
  subscription OnUpdateIceProduct(
    $filter: ModelSubscriptionIceProductFilterInput
    $owner: String
  ) {
    onUpdateIceProduct(filter: $filter, owner: $owner) {
      id
      iceBagSize1
      iceBagSize2
      iceBagSize3
      iceBlockSize1
      iceBlockSize2
      iceBlockSize3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteIceProduct = /* GraphQL */ `
  subscription OnDeleteIceProduct(
    $filter: ModelSubscriptionIceProductFilterInput
    $owner: String
  ) {
    onDeleteIceProduct(filter: $filter, owner: $owner) {
      id
      iceBagSize1
      iceBagSize2
      iceBagSize3
      iceBlockSize1
      iceBlockSize2
      iceBlockSize3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateIcePricing = /* GraphQL */ `
  subscription OnCreateIcePricing(
    $filter: ModelSubscriptionIcePricingFilterInput
    $owner: String
  ) {
    onCreateIcePricing(filter: $filter, owner: $owner) {
      id
      type
      weight
      amount
      price
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateIcePricing = /* GraphQL */ `
  subscription OnUpdateIcePricing(
    $filter: ModelSubscriptionIcePricingFilterInput
    $owner: String
  ) {
    onUpdateIcePricing(filter: $filter, owner: $owner) {
      id
      type
      weight
      amount
      price
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteIcePricing = /* GraphQL */ `
  subscription OnDeleteIcePricing(
    $filter: ModelSubscriptionIcePricingFilterInput
    $owner: String
  ) {
    onDeleteIcePricing(filter: $filter, owner: $owner) {
      id
      type
      weight
      amount
      price
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateRecharge = /* GraphQL */ `
  subscription OnCreateRecharge(
    $filter: ModelSubscriptionRechargeFilterInput
    $owner: String
  ) {
    onCreateRecharge(filter: $filter, owner: $owner) {
      id
      customerID
      customerFirstName
      customerLastName
      customerPhoneNumber
      iceAmount
      waterAmount
      alkalineAmount
      cashier
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateRecharge = /* GraphQL */ `
  subscription OnUpdateRecharge(
    $filter: ModelSubscriptionRechargeFilterInput
    $owner: String
  ) {
    onUpdateRecharge(filter: $filter, owner: $owner) {
      id
      customerID
      customerFirstName
      customerLastName
      customerPhoneNumber
      iceAmount
      waterAmount
      alkalineAmount
      cashier
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteRecharge = /* GraphQL */ `
  subscription OnDeleteRecharge(
    $filter: ModelSubscriptionRechargeFilterInput
    $owner: String
  ) {
    onDeleteRecharge(filter: $filter, owner: $owner) {
      id
      customerID
      customerFirstName
      customerLastName
      customerPhoneNumber
      iceAmount
      waterAmount
      alkalineAmount
      cashier
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateTransaction = /* GraphQL */ `
  subscription OnCreateTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
    $owner: String
  ) {
    onCreateTransaction(filter: $filter, owner: $owner) {
      id
      customerID
      customerFirstName
      customerLastName
      customerPhoneNumber
      iceAmount
      waterAmount
      alkalineAmount
      cashier
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateTransaction = /* GraphQL */ `
  subscription OnUpdateTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
    $owner: String
  ) {
    onUpdateTransaction(filter: $filter, owner: $owner) {
      id
      customerID
      customerFirstName
      customerLastName
      customerPhoneNumber
      iceAmount
      waterAmount
      alkalineAmount
      cashier
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteTransaction = /* GraphQL */ `
  subscription OnDeleteTransaction(
    $filter: ModelSubscriptionTransactionFilterInput
    $owner: String
  ) {
    onDeleteTransaction(filter: $filter, owner: $owner) {
      id
      customerID
      customerFirstName
      customerLastName
      customerPhoneNumber
      iceAmount
      waterAmount
      alkalineAmount
      cashier
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateStore = /* GraphQL */ `
  subscription OnCreateStore(
    $filter: ModelSubscriptionStoreFilterInput
    $owner: String
  ) {
    onCreateStore(filter: $filter, owner: $owner) {
      id
      ownerFirstName
      ownerLastName
      storeName
      storePhoneNumber
      storeEmail
      storeAddyLine1
      storeAddyLine2
      storeAddyCity
      storeAddyState
      storeAddyZipCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateStore = /* GraphQL */ `
  subscription OnUpdateStore(
    $filter: ModelSubscriptionStoreFilterInput
    $owner: String
  ) {
    onUpdateStore(filter: $filter, owner: $owner) {
      id
      ownerFirstName
      ownerLastName
      storeName
      storePhoneNumber
      storeEmail
      storeAddyLine1
      storeAddyLine2
      storeAddyCity
      storeAddyState
      storeAddyZipCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteStore = /* GraphQL */ `
  subscription OnDeleteStore(
    $filter: ModelSubscriptionStoreFilterInput
    $owner: String
  ) {
    onDeleteStore(filter: $filter, owner: $owner) {
      id
      ownerFirstName
      ownerLastName
      storeName
      storePhoneNumber
      storeEmail
      storeAddyLine1
      storeAddyLine2
      storeAddyCity
      storeAddyState
      storeAddyZipCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onCreateUser(filter: $filter, owner: $owner) {
      id
      name
      pin
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onUpdateUser(filter: $filter, owner: $owner) {
      id
      name
      pin
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onDeleteUser(filter: $filter, owner: $owner) {
      id
      name
      pin
      createdAt
      updatedAt
      owner
    }
  }
`;
