import React, { Component, useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import {
  Authenticator,
  useAuthenticator,
  AuthState,
  // onAuthUIStateChange,
} from "@aws-amplify/ui-react";
import { RequireAuth } from "./RequireAuth";
import awsExports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";

import { API, graphqlOperation } from "aws-amplify";
import {
  listCustomers,
  listPricings,
  listTransactions,
  listRecharges,
  listStores,
  listUsers,
} from "./graphql/queries";

import {
  onCreateCustomer,
  onUpdateCustomer,
  onDeleteCustomer,
  onCreatePricing,
  onUpdatePricing,
  onDeletePricing,
  onCreateRecharge,
  onUpdateRecharge,
  onDeleteRecharge,
  onCreateTransaction,
  onUpdateTransaction,
  onDeleteTransaction,
  onCreateStore,
  onUpdateStore,
  onDeleteStore,
  onCreateUser,
  onUpdateUser,
  onDeleteUser,
} from "./graphql/subscriptions";

import { Navbar } from "./components/NavBar";
import { Home } from "./components/Home";
import { Feature } from "./components/Feature";
import { SubscriptionPricing } from "./components/SubscriptionPricing";
import { Support } from "./components/Support";
import Login from "./components/Login";

import { DashboardExample } from "./components/common/DashboardExample";
import { CustomerList } from "./components/common/CustomerList";
import { PricingExample } from "./components/common/PricingExample";
import RegisterExample from "./components/common/RegisterExample";
import { RechargeHistory } from "./components/common/RechargeHistory";
import { TransactionHistory } from "./components/common/TransactionHistory";
import { Account } from "./components/common/Account";
import Setup from "./components/Setup";
import { QrCode } from "./components/QrCode";
import useCustomerData from "./hooks/customerHooks";
import useRechargeData from "./hooks/rechargeHooks";
import useTransactionData from "./hooks/transactionHooks";

Amplify.configure(awsExports);

//ROUTES=======================================================================
const MyRoutes = ({
  customerData,
  storeData,
  pricingData,
  // rechargeData,
  // transactionData,
  userData,
  accEmail,
  cognitoID,
  route,
  storeDataLoaded,
  stripeKey,
  stripeCustomer,
  stripeProducts,
  fetchStripeCustomer,
  stripePrices,
  nameFilter,
  phoneNumberFilter,
  setNameFilter,
  setPhoneNumberFilter,
  searchParamKeyword,
  setSearchParamKeyword,
}) => {
  const [expender, setExpender] = useState(false);
  const location = useLocation();
  const hideNavbar = location.pathname === "/qrcode";

  return (
    <>
      {/* <BrowserRouter> */}
      {!hideNavbar && (
        <Navbar
          storeData={storeData}
          stripeCustomer={stripeCustomer}
          stripePrices={stripePrices}
          stripeKey={stripeKey}
          expender={expender}
          setExpender={setExpender}
        />
      )}
      {/* <Navbar
          storeData={storeData}
          stripeCustomer={stripeCustomer}
          stripePrices={stripePrices}
          stripeKey={stripeKey}
          expender={expender}
          setExpender={setExpender}
        /> */}
      <Routes>
        {/* Route For Public  */}
        <Route path="/home" element={<Home expender={expender} />} />
        <Route path="/feature" element={<Feature />} />
        <Route path="/subscriptionpricing" element={<SubscriptionPricing />} />
        <Route path="/support" element={<Support />} />
        <Route path="/qrcode" element={<QrCode />} />

        {storeDataLoaded &&
        route === "authenticated" &&
        storeData.length > 0 ? (
          <Route path="/" element={<Navigate to="/dashboard" />} />
        ) : storeDataLoaded &&
          route === "authenticated" &&
          storeData.length === 0 ? (
          <Route path="/" element={<Navigate to="/setup" />} />
        ) : route !== "authenticated" ? (
          <Route path="/" element={<Navigate to="/home" />} />
        ) : null}

        {route === "authenticated" && storeData.length === 0 ? (
          <>
            <Route
              path="/setup"
              element={
                <RequireAuth>
                  <Setup
                    accEmail={accEmail}
                    cognitoID={cognitoID}
                    stripeKey={stripeKey}
                    stripeCustomer={stripeCustomer}
                    stripeProducts={stripeProducts}
                    fetchStripeCustomer={fetchStripeCustomer}
                    stripePrices={stripePrices}
                  />
                </RequireAuth>
              }
            />
          </>
        ) : (
          ""
        )}
        {route === "authenticated" && storeData.length > 0 ? (
          <>
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <DashboardExample
                    customerData={customerData}
                    // transactionData={transactionData}
                    // rechargeData={rechargeData}
                    storeData={storeData}
                    expender={expender}
                    route={route}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/customer"
              element={
                <RequireAuth>
                  <CustomerList
                    customerData={customerData}
                    pricingData={pricingData}
                    userData={userData}
                    expender={expender}
                    nameFilter={nameFilter}
                    phoneNumberFilter={phoneNumberFilter}
                    setNameFilter={setNameFilter}
                    setPhoneNumberFilter={setPhoneNumberFilter}
                    searchParamKeyword={searchParamKeyword}
                    setSearchParamKeyword={setSearchParamKeyword}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/register"
              element={
                <RequireAuth>
                  <RegisterExample
                    customerData={customerData}
                    pricingData={pricingData}
                    userData={userData}
                    expender={expender}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/productpricing"
              element={
                <RequireAuth>
                  <PricingExample
                    pricingData={pricingData}
                    userData={userData}
                    expender={expender}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/rechargehistory"
              element={
                <RequireAuth>
                  <RechargeHistory
                    customerData={customerData}
                    // rechargeData={rechargeData}
                    userData={userData}
                    expender={expender}
                    route={route}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/transactionhistory"
              element={
                <RequireAuth>
                  <TransactionHistory
                    customerData={customerData}
                    // transactionData={transactionData}
                    userData={userData}
                    expender={expender}
                    route={route}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/account"
              element={
                <RequireAuth>
                  <Account
                    storeData={storeData}
                    // customerData={customerData}
                    // rechargeData={rechargeData}
                    // transactionData={transactionData}
                    userData={userData}
                    accEmail={accEmail}
                    cognitoID={cognitoID}
                    stripeKey={stripeKey}
                    stripeCustomer={stripeCustomer}
                    stripeProducts={stripeProducts}
                    expender={expender}
                  />
                </RequireAuth>
              }
            />
          </>
        ) : (
          ""
        )}
        <Route path="/login" element={<Login />} />
      </Routes>
      {/* </BrowserRouter> */}
    </>
  );
};

//APP=======================================================================
function App() {
  // const [customerData, setCustomerData] = useState([]); //read,write,edit,
  const [storeData, setStoreData] = useState([]); //read,write,edit
  const [pricingData, setPricingData] = useState([]); //read,write,edit,delete
  // const [rechargeData, setRechargeData] = useState([]); // read,write
  // const [transactionData, setTransactionData] = useState([]); //read,write,
  const [userData, setUserData] = useState([]); //read,write,
  const [accEmail, setAccEmail] = useState("");
  const [cognitoID, setcognitoID] = useState("");
  const [storeDataLoaded, setStoreDataLoaded] = useState(false);
  const [searchParamKeyword, setSearchParamKeyword] = useState("");

  // const stripeKey = process.env.REACT_APP_STRIPE_SECRET_KEY;
  const { route } = useAuthenticator((context) => [context.route]);

  //TODO: Stripe Data ====================================================================================================================================
  // const [stripeCustomer, setStripeCustomer] = useState([]);
  // const [stripeProducts, setStripeProducts] = useState([]);
  // const [stripePrices, setStripePrices] = useState([]);
  // const [stripeSubscriptions, setStripeSubscriptions] = useState([]);
  // const [stripeCustomerSubscription, setStripeCustomerSubscription] = useState(
  //   []
  // );

  // const fetchStripeCustomerSubscription = () => {
  //   const subscriptions = stripeSubscriptions;
  //   if (stripeCustomer && subscriptions) {
  //     subscriptions.data.filter((c) => c.customer === stripeCustomer.id);
  //   }
  //   setStripeCustomerSubscription(subscriptions);
  // };

  // const stripe = require("stripe")(stripeKey);

  // const fetchStripeCustomer = async () => {
  //   const customers = await stripe.customers.list({
  //     limit: 3,
  //   });
  //   const customer = customers.data.filter((c) => c.email === accEmail);
  //   setStripeCustomer(customer[0]);
  // };
  // const fetchStripeProducts = async () => {
  //   const products = await stripe.products.list({
  //     limit: 3,
  //   });
  //   setStripeProducts(products.data);
  // };
  // const fetchStripePrices = async () => {
  //   const prices = await stripe.prices.list({
  //     limit: 3,
  //   });
  //   setStripePrices(prices.data);
  // };
  // const fetchStripeSubscriptions = async () => {
  //   const subscriptions = await stripe.subscriptions.list({
  //     limit: 3,
  //   });
  //   setStripeSubscriptions(subscriptions);
  // };

  // useEffect(() => {
  //   fetchStripeCustomer();
  //   fetchStripeProducts();
  //   fetchStripePrices();
  //   fetchStripeSubscriptions();
  // }, [route]);

  //UseEffect for AccountData (Cognito)========================================================================================
  useEffect(() => {
    const fetchAcc = async () => {
      const account = await Auth.currentAuthenticatedUser();
      const accEmail = account.attributes.email;
      const cognitoID = account.attributes.sub;
      setAccEmail(accEmail);
      setcognitoID(cognitoID);
    };
    fetchAcc();
  }, [route]);

  // UseEffect for Pricing ==============================================================================================================
  useEffect(() => {
    const subscriptionFilter = { filter: {} };
    const fetchPricings = async () => {
      const result = await API.graphql(graphqlOperation(listPricings));
      setPricingData(result.data.listPricings.items);
    };
    fetchPricings();
    const createPricingSub = API.graphql(
      graphqlOperation(onCreatePricing, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setPricingData((PricingData) => [
          ...PricingData,
          value.data.onCreatePricing,
        ]);
      },
    });
    const updatePricingSub = API.graphql(
      graphqlOperation(onUpdatePricing, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setPricingData((PricingData) => {
          const toUpdateIndex = PricingData.findIndex(
            (item) => item.id === value.data.onUpdatePricing.id
          );
          if (toUpdateIndex === -1) {
            // If the todo doesn't exist, treat it like an "add"
            return [...PricingData, value.data.onUpdatePricing];
          }
          return [
            ...PricingData.slice(0, toUpdateIndex),
            value.data.onUpdatePricing,
            ...PricingData.slice(toUpdateIndex + 1),
          ];
        });
      },
    });
    const deletePricingSub = API.graphql(
      graphqlOperation(onDeletePricing, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setPricingData((PricingData) => {
          const toDeleteIndex = PricingData.findIndex(
            (item) => item.id === value.data.onDeletePricing.id
          );
          return [
            ...PricingData.slice(0, toDeleteIndex),
            ...PricingData.slice(toDeleteIndex + 1),
          ];
        });
      },
    });
    return () => {
      createPricingSub.unsubscribe();
      updatePricingSub.unsubscribe();
      deletePricingSub.unsubscribe();
    };
  }, [route]);

  // TEST UseEffect for Store ==============================================================================================================
  useEffect(() => {
    const subscriptionFilter = { filter: {} };

    const fetchStores = async () => {
      const result = await API.graphql(graphqlOperation(listStores));
      setStoreData(result.data.listStores.items);
      setStoreDataLoaded(true);
    };
    fetchStores();
    const createStoreSub = API.graphql(
      graphqlOperation(onCreateStore, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setStoreData((StoreData) => [...StoreData, value.data.onCreateStore]);
      },
    });
    const updateStoreSub = API.graphql(
      graphqlOperation(onUpdateStore, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setStoreData((StoreData) => {
          const toUpdateIndex = StoreData.findIndex(
            (item) => item.id === value.data.onUpdateStore.id
          );
          if (toUpdateIndex === -1) {
            // If the todo doesn't exist, treat it like an "add"
            return [...StoreData, value.data.onUpdateStore];
          }
          return [
            ...StoreData.slice(0, toUpdateIndex),
            value.data.onUpdateStore,
            ...StoreData.slice(toUpdateIndex + 1),
          ];
        });
      },
    });
    const deleteStoreSub = API.graphql(
      graphqlOperation(onDeleteStore, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setStoreData((StoreData) => {
          const toDeleteIndex = StoreData.findIndex(
            (item) => item.id === value.data.onDeleteStore.id
          );
          return [
            ...StoreData.slice(0, toDeleteIndex),
            ...StoreData.slice(toDeleteIndex + 1),
          ];
        });
      },
    });
    return () => {
      createStoreSub.unsubscribe();
      updateStoreSub.unsubscribe();
      deleteStoreSub.unsubscribe();
    };
  }, [route]);

  // UseEffect for User ==============================================================================================================
  useEffect(() => {
    const subscriptionFilter = { filter: {} };
    const fetchUsers = async () => {
      const result = await API.graphql(graphqlOperation(listUsers));
      setUserData(result.data.listUsers.items);
    };
    fetchUsers();
    const createUserSub = API.graphql(
      graphqlOperation(onCreateUser, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setUserData((UserData) => [...UserData, value.data.onCreateUser]);
      },
    });
    const updateUserSub = API.graphql(
      graphqlOperation(onUpdateUser, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setUserData((UserData) => {
          const toUpdateIndex = UserData.findIndex(
            (item) => item.id === value.data.onUpdateUser.id
          );
          if (toUpdateIndex === -1) {
            // If the todo doesn't exist, treat it like an "add"
            return [...UserData, value.data.onUpdateUser];
          }
          return [
            ...UserData.slice(0, toUpdateIndex),
            value.data.onUpdateUser,
            ...UserData.slice(toUpdateIndex + 1),
          ];
        });
      },
    });
    const deleteUserSub = API.graphql(
      graphqlOperation(onDeleteUser, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setUserData((UserData) => {
          const toDeleteIndex = UserData.findIndex(
            (item) => item.id === value.data.onDeleteUser.id
          );
          return [
            ...UserData.slice(0, toDeleteIndex),
            ...UserData.slice(toDeleteIndex + 1),
          ];
        });
      },
    });
    return () => {
      createUserSub.unsubscribe();
      updateUserSub.unsubscribe();
      deleteUserSub.unsubscribe();
    };
  }, [route]);

  // FIXME: UseEffect for Transaction ==============================================================================================================
  // const transactionData = useTransactionData(route);

  // FIXME: UseEffect for Recharge ==============================================================================================================
  // const rechargeData = useRechargeData(route);

  // FIXME: UseEffect for customerData ========================================================================================
  const customerData = useCustomerData(route, searchParamKeyword);

  return (
    <>
      <BrowserRouter>
        <MyRoutes
          customerData={customerData}
          storeData={storeData}
          pricingData={pricingData}
          // rechargeData={rechargeData}
          // transactionData={transactionData}
          userData={userData}
          accEmail={accEmail}
          cognitoID={cognitoID}
          route={route}
          storeDataLoaded={storeDataLoaded}
          searchParamKeyword={searchParamKeyword}
          setSearchParamKeyword={setSearchParamKeyword}
          // nameFilter={nameFilter}
          // phoneNumberFilter={phoneNumberFilter}
          // setNameFilter={setNameFilter}
          // setPhoneNumberFilter={setPhoneNumberFilter}
          // stripeKey={stripeKey}
          // stripeCustomer={stripeCustomer}
          // stripeProducts={stripeProducts}
          // stripePrices={stripePrices}
          // fetchStripeCustomer={fetchStripeCustomer}
        />
      </BrowserRouter>
    </>
  );
}

export default App;
