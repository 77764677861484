const addedHour = (timeString, hourAmount) => {
  // Parse the time string into a Date object
  const date = new Date(timeString);
  
  // Add given amount of hour to the Date object
  date.setHours(date.getHours() + hourAmount);
  
  // Convert the updated Date object back to a string in the original format
  const updatedTimeString = date.toISOString();
  
  return updatedTimeString;

}
 
export default addedHour;