import Animation from "../Animation";
import styles from "../../../styles/Home.module.css";
import devices from "../../../asset/devices.svg";

const HomeAbout = () => {
  return (
    <>
      <div className={styles.aboutContainer}>
        <div>
          <div>
            <div className={styles.aboutTitle}>Who we are and What we do</div>
            <div className={styles.aboutText}>
              HydroCard is a prepaid balance management software solution
              specifically designed for managing water and ice store's
              customers. Our platform not only keep track of customer's prepaid
              balance, but its also a POS (point of sale) system. Our platform
              streamlines the workflow for store owners, actions made available
              on any devices, making it easier to manage, read, predict, and
              secure customer data on the go. Our product stands out from other
              management software in the market because we have tailored our
              features to meet the unique needs of water and ice store owners.
            </div>
            <div className={styles.aboutText}>
              At HydroCard, we understand the importance of keeping track of
              your customers and your business. That's why we have developed a
              seamless and digitized solution that allows store owners to keep
              track and understand their important business data, generating
              insightful reports that provide actionable insights into their
              business operations. You can now save time by not guessing or
              manually keeping track of your weekly or monthly reports of usage
              or sales.
            </div>
          </div>
          <div>
            <div className={styles.aboutTitle}>Our Mission</div>
            <div className={styles.aboutText}>
              Our team is dedicated to providing the highest level of service to
              our clients. We have combined our technical expertise and industry
              experience to create a product that truly meets the needs of water
              and ice store owners. Scroll down to learn more about our top
              features!
            </div>
          </div>
        </div>
        <div className={styles.heroPic}>
          <img src={devices} alt="" />
        </div>
      </div>
    </>
  );
};

export default HomeAbout;
