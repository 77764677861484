import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listTransactions } from '../graphql/queries';
import { onCreateTransaction, onUpdateTransaction, onDeleteTransaction } from '../graphql/subscriptions';

const useTransactionData = (route) => {
  const [transactionData, setTransactionData] = useState([]);

  useEffect(() => {
    const subscriptionFilter = { filter: {} };

    const fetchTransactions = async () => {
      try {
        const result = await API.graphql(
          graphqlOperation(listTransactions, { limit: 2000 })
        );
        setTransactionData(result.data.listTransactions.items);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();

    const createTransactionSub = API.graphql(
      graphqlOperation(onCreateTransaction, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setTransactionData((prevTransactionData) => [
          ...prevTransactionData,
          value.data.onCreateTransaction,
        ]);
      },
    });

    const updateTransactionSub = API.graphql(
      graphqlOperation(onUpdateTransaction, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setTransactionData((prevTransactionData) => {
          const toUpdateIndex = prevTransactionData.findIndex(
            (item) => item.id === value.data.onUpdateTransaction.id
          );
          if (toUpdateIndex === -1) {
            return [...prevTransactionData, value.data.onUpdateTransaction];
          }
          return [
            ...prevTransactionData.slice(0, toUpdateIndex),
            value.data.onUpdateTransaction,
            ...prevTransactionData.slice(toUpdateIndex + 1),
          ];
        });
      },
    });

    const deleteTransactionSub = API.graphql(
      graphqlOperation(onDeleteTransaction, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setTransactionData((prevTransactionData) => {
          const toDeleteIndex = prevTransactionData.findIndex(
            (item) => item.id === value.data.onDeleteTransaction.id
          );
          return [
            ...prevTransactionData.slice(0, toDeleteIndex),
            ...prevTransactionData.slice(toDeleteIndex + 1),
          ];
        });
      },
    });

    return () => {
      createTransactionSub.unsubscribe();
      updateTransactionSub.unsubscribe();
      deleteTransactionSub.unsubscribe();
    };
  }, [route]);

  return transactionData;
};

export default useTransactionData;