import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { createRecharge } from "../../graphql/mutations";
import { updateCustomer } from "../../graphql/mutations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../../styles/Forms.module.css";
import toggle from "./util/toggle";
import preventEnterSubmit from "./util/preventEnterSubmit";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";

const RechargeUI = ({
  chosenCustomer,
  setChosenCustomer,
  pricingData,
  rechargeButtonUIActive,
  setRechargeButtonUIActive,
  userData,
  fetchCustomers,
}) => {
  const [rechargeIceAmount, setRechargeIceAmount] = useState("");
  const [rechargeWaterAmount, setRechargeWaterAmount] = useState("");
  const [rechargeAlkalineAmount, setRechargeAlkalineAmount] = useState("");
  const [cashierPin, setCashierPin] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const numberFormIceAmount = Number(rechargeIceAmount);
  const numberFormWaterAmount = Number(rechargeWaterAmount);
  const numberFormAlkalineAmount = Number(rechargeAlkalineAmount);
  const customerID = chosenCustomer.id;
  const firstName = chosenCustomer.firstName;
  const lastName = chosenCustomer.lastName;
  const phoneNumber = chosenCustomer.phoneNumber;
  const sumOfIce = chosenCustomer.ice + numberFormIceAmount;
  const sumOfWater = chosenCustomer.water + numberFormWaterAmount;
  const sumOfAlkaline = chosenCustomer.alkaline + numberFormAlkalineAmount;
  const navigate = useNavigate();

  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAllUsers(userData);
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };
    fetchData();
  }, []);

  const cashierNum = allUsers.map((e) => e.pin).indexOf(cashierPin);
  const inputedCashier =
    cashierNum > -1 ? allUsers[cashierNum].name : "notFound";

  const priceOptionMaker = (iceOrWater) => {
    const prices = pricingData.filter((price) => price.type === iceOrWater);
    let arr = [];
    prices.map((option) => {
      arr.push(option.amount);
    });
    return arr.sort(function (a, b) {
      return a - b;
    });
  };
  const icePriceOption = priceOptionMaker("ice");
  const waterPriceOption = priceOptionMaker("water");
  const alkalinePriceOption = priceOptionMaker("alkaline");

  const priceCalculator = (iceOrWater, chosenAmount) => {
    const amount = Number(chosenAmount);
    const yoyo = pricingData.filter((price) => price.type === iceOrWater);
    if (amount !== 0) {
      for (let i = 0; i < yoyo.length; i++) {
        if (yoyo[i].amount === amount) {
          return yoyo[i].price;
        }
      }
    } else {
      return 0;
    }
  };
  const totalCost =
    priceCalculator("ice", rechargeIceAmount) +
    priceCalculator("water", rechargeWaterAmount) +
    priceCalculator("alkaline", rechargeAlkalineAmount);

  const onRechargeClear = () => {
    document.getElementById("recharge-form").reset();
    setCashierPin("");
    setRechargeIceAmount("");
    setRechargeWaterAmount("");
    setRechargeAlkalineAmount("");
  };

  const onSubmitRecharge = async () => {
    if (
      (rechargeIceAmount > 0 ||
        rechargeWaterAmount > 0 ||
        rechargeAlkalineAmount > 0) &&
      userData.length > 0 &&
      inputedCashier === "notFound"
    ) {
      toast.error("No Existing User Pin");
    } else if (
      //for single users
      (rechargeIceAmount > 0 ||
        rechargeWaterAmount > 0 ||
        rechargeAlkalineAmount > 0) &&
      userData.length === 0 &&
      inputedCashier === "notFound" &&
      isLoading === false
    ) {
      try {
        setIsLoading(true);
        await API.graphql({
          query: createRecharge,
          variables: {
            input: {
              customerID: customerID,
              customerFirstName: firstName,
              customerLastName: lastName,
              customerPhoneNumber: phoneNumber,
              iceAmount: numberFormIceAmount,
              waterAmount: numberFormWaterAmount,
              alkalineAmount: numberFormAlkalineAmount,
            },
          },
        });
        await API.graphql({
          query: updateCustomer,
          variables: {
            input: {
              id: customerID,
              ice: sumOfIce,
              water: sumOfWater,
              alkaline: sumOfAlkaline,
            },
          },
        });
        setRechargeButtonUIActive(false);
        setIsLoading(false);
        toast.success("Balance Added successfully!");
        await fetchCustomers();
      } catch (error) {
        console.error("error occured", error);
      }
    } else if (
      //for multi users
      (rechargeIceAmount > 0 ||
        rechargeWaterAmount > 0 ||
        rechargeAlkalineAmount > 0) &&
      inputedCashier !== "notFound" &&
      isLoading === false
    ) {
      try {
        setIsLoading(true);
        await API.graphql({
          query: createRecharge,
          variables: {
            input: {
              customerID: customerID,
              customerFirstName: firstName,
              customerLastName: lastName,
              customerPhoneNumber: phoneNumber,
              iceAmount: numberFormIceAmount,
              waterAmount: numberFormWaterAmount,
              alkalineAmount: numberFormAlkalineAmount,
              cashier: inputedCashier,
            },
          },
        });
        await API.graphql({
          query: updateCustomer,
          variables: {
            input: {
              id: customerID,
              ice: sumOfIce,
              water: sumOfWater,
              alkaline: sumOfAlkaline,
            },
          },
        });
        setRechargeButtonUIActive(false);
        setIsLoading(false);
        toast.success("Recharge successful");
        await fetchCustomers();
      } catch (error) {
        console.error("error occured", error);
      }
    }
  };

  const onCancel = () => {
    toggle(rechargeButtonUIActive, setRechargeButtonUIActive);
    setChosenCustomer("");
    navigate("/customer");
  };

  return (
    <>
      <div className={styles.heading}>
        <h3>Add Balance</h3>
        <h3 class="button" onClick={() => onCancel()}>
          <i class="fa-solid fa-x"></i>
        </h3>
      </div>
      <form id="recharge-form">
        <div className={styles.customerInfo}>
          <div className={styles.label}>Customer</div>
          <div className={styles.label}>
            <div class="hiddenForMobile">Balance</div>
          </div>
        </div>
        <div className={styles.customerInfo}>
          <div>
            <h1>
              {chosenCustomer.firstName} {chosenCustomer.lastName}
            </h1>
          </div>
          <div className={styles.badgeHolder}>
            {/* <div class="badgeIce">Ice: {chosenCustomer.ice}</div> */}
            <div class="badgeWater">Water: {chosenCustomer.water}</div>
            <div class="badgeAlkaline">Alkaline: {chosenCustomer.alkaline}</div>
          </div>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.customerInfo}>
          <div className={styles.label}>Select Recharge Amount</div>
          <div className={styles.label}></div>
        </div>
        {/* water selector ================================================================== */}
        <div className={styles.InputContainer}>
          <div className={styles.labelContainer}>
            <div className="iconWater">
              <i class="fa-solid fa-droplet"></i>
            </div>
            <div className={styles.iconDesc}>Water</div>
            <div class="hiddenForDesktop">
              <div className={styles.iconDesc}>(gal)</div>
            </div>
          </div>
          {waterPriceOption.length > 0 ? (
            <div className={styles.planSelect}>
              <select onChange={(e) => setRechargeWaterAmount(e.target.value)}>
                <option value="" selected>
                  Select Plan
                </option>
                {waterPriceOption.map((option) => {
                  return <option value={option}>{option} gal</option>;
                })}
              </select>
            </div>
          ) : (
            <div>No Prepaid Plans</div>
          )}
        </div>
        {/* alkaline selector ================================================================== */}
        <div className={styles.InputContainer}>
          <div className={styles.labelContainer}>
            <div className="iconAlkaline">
              <i class="fa-solid fa-droplet"></i>
            </div>
            <div className={styles.iconDesc}>Alkaline</div>
            <div class="hiddenForDesktop">
              <div className={styles.iconDesc}>(gal)</div>
            </div>
          </div>
          {alkalinePriceOption.length > 0 ? (
            <div className={styles.planSelect}>
              <select
                onChange={(e) => setRechargeAlkalineAmount(e.target.value)}
              >
                <option value="" selected>
                  Select Plan
                </option>
                {alkalinePriceOption.map((option) => {
                  return <option value={option}>{option} gal</option>;
                })}
              </select>
            </div>
          ) : (
            <div>No Prepaid Plans</div>
          )}
        </div>
        {/* Summary ================================================================== */}
        <div className={styles.rechargeSummaryContainer}>
          {rechargeWaterAmount ? (
            <div className={styles.rechargeSummary}>
              <div className={styles.costType}>Water Cost: </div>
              <div className={styles.costContainer}>
                $ {priceCalculator("water", rechargeWaterAmount)}.00
              </div>
            </div>
          ) : (
            ""
          )}
          {rechargeAlkalineAmount ? (
            <div className={styles.rechargeSummary}>
              <div className={styles.costType}>Alkaline Cost: </div>
              <div className={styles.costContainer}>
                $ {priceCalculator("alkaline", rechargeAlkalineAmount)}.00
              </div>
            </div>
          ) : (
            ""
          )}
          {rechargeIceAmount ||
          rechargeWaterAmount ||
          rechargeAlkalineAmount ? (
            <div>
              <div className={styles.summaryDivider}></div>
              <div className={styles.rechargeSummary}>
                <div className={styles.costType}>Total Cost: </div>
                <div className={styles.costContainerTotal}>
                  $ {totalCost}.00
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* TODO:TODO:TODO:TODO: */}
        {userData.length > 0 ? (
          <>
            <div className={styles.divider}></div>
            <div className={styles.pinInputContainer}>
              <div>
                <i class="fa-solid fa-key"></i> Pin:
              </div>
              <div>
                {/* <input
                  onChange={(e) => setCashierPin(e.target.value)}
                  onKeyUp={(e) => preventEnterSubmit(e)}
                  className={styles.pinInput}
                  placeholder="****"
                /> */}
                <NumericFormat
                  className={styles.pinInput}
                  allowLeadingZeros
                  onKeyUp={(e) => preventEnterSubmit(e)}
                  type="password"
                  format="####"
                  mask="_"
                  placeholder="****"
                  allowEmptyFormatting
                  minlength="4"
                  maxlength="8"
                  autocomplete="new-password"
                  onChange={(e) => setCashierPin(e.target.value)}
                  value={cashierPin}
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className={styles.divider}></div>
      </form>
      {/* Reset and Clear ==============================================*/}
      <div className={styles.formBtnContainer}>
        {isLoading ? (
          ""
        ) : (
          <div className={styles.formBtnReset} onClick={() => onCancel()}>
            Cancel
          </div>
        )}
        {isLoading ? (
          ""
        ) : (
          <div
            className={styles.formBtnReset}
            onClick={() => onRechargeClear()}
          >
            Clear
          </div>
        )}
        {isLoading ? (
          <div className={styles.formBtnSubmitLoading}>
            <div class="loader"></div>
          </div>
        ) : (
          <div
            className={styles.formBtnSubmit}
            onClick={() => onSubmitRecharge()}
          >
            Submit
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default RechargeUI;
