import React from "react";
import styles from "../../styles/Pagination.module.css";
import _ from "lodash";

const PaginationServerSide = ({
  currentPage,
  setCurrentPage,
  maxPage,
  token,
  items,
  handleSearch,
}) => {
  const pages = (num) => {
    return Array.from({ length: num }, (_, i) => i + 1);
  };

  return (
    <div className={styles.pageContainer}>
      <div className="flex">
        {pages(maxPage).map((num) => (
          <div
            className={num === currentPage ? styles.page_active : styles.page}
            onClick={() => setCurrentPage(num)}
          >
            {num}
          </div>
        ))}
      </div>
      {token === null ? (
        <>
          {items.length === 0 && <div className="disabledBtn">Load More</div>}
          {items.length > 0 && (
            <div className="disabledBtn">All Data Fetched</div>
          )}
        </>
      ) : (
        <div className="primaryBtn" onClick={() => handleSearch("loadMore")}>
          Load More
        </div>
      )}
    </div>
  );
};

export default PaginationServerSide;
