import React from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";
import {QRCodeSVG} from 'qrcode.react';

export const QrCode = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const hasSidebar = () => {
    return route === "authenticated" ? 'contentWithSideBar' : '';
  }

  const currentUrl = window.location.href;

  return (
    <div class={hasSidebar()}>
      <div className='qrcodeContainer'>
      <QRCodeSVG value={currentUrl} bgColor={"#f5f5f9"} className='qrcodeSVG'/>
      </div>
      <div className='qrcodeDesc'>Show this code to make balance adjustment</div>
  </div>
  );
}