import React, { useState, useEffect } from "react";
import totalProductSale from "./util/totalProductSale";
import { API } from "aws-amplify";
import { paginate } from "./util/paginate";
import Pagination from "./Pagination";
import SearchBar from "./SearchBar";
import _ from "lodash";
import PageSizeSelector from "./PageSizeSelector";
import addedHour from "./util/addedHour";
import styles from "../../styles/TransactionHistory.module.css";
import { updateCustomer } from "../../graphql/mutations";
import { deleteTransaction } from "../../graphql/mutations";
import { ToastContainer, toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import preventEnterSubmit from "./util/preventEnterSubmit";
import useTransactionData from "../../hooks/transactionHooks";
import SearchBarNew from "./SearchBarNew";
import { getTransactionByCustomerID } from "../../hooks/getTransactionByCustomerID";
import PaginationServerSide from "./PatinationServerSide";

export const TransactionHistory = ({
  customerData,
  // transactionData,
  userData,
  expender,
  route,
}) => {
  //=======================================
  const pageLimit = 10;
  const [searchInput, setSearchInput] = useState("");
  const [items, setItems] = useState([]);
  console.log("items:", items);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isUndoLoading, setIsUndoLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); //default 1
  console.log("currentPage:", currentPage);
  const [maxPage, setMaxPage] = useState(0); //default 0
  console.log("maxPage:", maxPage);
  const [undoComplete, setUndoComplete] = useState(false);

  const pages = (num) => {
    // let result = [];
    // for (let i = 1; i <= num; i++) {
    //     result.push(i);
    // }
    // return result;
    return Array.from({ length: num }, (_, i) => i + 1);
  };

  const handleSearch = (searchType) => {
    getTransactionByCustomerID(
      searchInput,
      token,
      items,
      pageLimit,
      maxPage,
      currentPage,
      setToken,
      setItems,
      setLoading,
      setMaxPage,
      setCurrentPage,
      searchType // start or loadMore
    );
  };

  const paginated = paginate(items, currentPage, pageLimit);

  const handlePaste = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setSearchInput(clipboardText);
    } catch (err) {
      // console.error('Failed to read clipboard contents: ', err);
      toast.error(`Failed to read clipboard contents`);
    }
  };
  //=======================================

  // const transactionData = useTransactionData(route);

  // const [currentPage, setCurrentPage] = useState(1); //default 1
  // const [query, setQuery] = useState(""); //Search Feature
  // const [pageSize, setPageSize] = useState(4); //set able 5, 10, 30

  // const [sortColumn_path, setSortColumn_path] = useState("createdAt");
  // const [sortColumn_order, setSortColumn_order] = useState("desc");
  const [cashierPin, setCashierPin] = useState("");
  // const [isLoading, setIsLoading] = useState(false);

  const [allUsers, setAllUsers] = useState([]);
  fetch(userData).then(() => setAllUsers(userData));
  const cashierNum = allUsers.map((e) => e.pin).indexOf(cashierPin);
  const inputedCashier =
    cashierNum > -1 ? allUsers[cashierNum].name : "notFound";

  // const columns = [
  //   { path: "customerFirstName", label: "First Name" },
  //   { path: "customerLastName", label: "Last Name" },
  //   { path: "createdAt", label: "Transaction At" },
  //   { path: "cashier", label: "Cashier" },
  // ];

  const undoTimeLimitInHour = 24; //TODO: Given amount of time to do UNDO action.
  const [undoChosenTransaction, setUndoChosenTransaction] = useState("");
  const [undoActive, setUndoActive] = useState(false);

  const customer = customerData.filter(
    (c) => c.id === undoChosenTransaction.customerID
  );

  const customerChoser = (data) => {
    undoActive ? setUndoActive(false) : setUndoActive(true);
    setUndoChosenTransaction(data);
  };

  const updatedIce =
    customer.length > 0
      ? customer[0].ice + undoChosenTransaction.iceAmount
      : "customer not found";
  const updatedWater =
    customer.length > 0
      ? customer[0].water + undoChosenTransaction.waterAmount
      : "customer not found";
  const updatedAlkaline =
    customer.length > 0
      ? customer[0].alkaline + undoChosenTransaction.alkalineAmount
      : "customer not found";

  //========================================================================================

  const undoSubmit = async () => {
    if (
      customer.length > 0 &&
      userData.length > 0 &&
      inputedCashier === "notFound"
    ) {
      toast.error("No Existing User Pin");
    } else if (
      //for single users
      customer.length > 0 &&
      userData.length === 0 &&
      inputedCashier === "notFound" &&
      isUndoLoading === false
    ) {
      try {
        setIsUndoLoading(true);
        API.graphql({
          query: updateCustomer,
          variables: {
            input: {
              id: customer[0].id,
              ice: updatedIce,
              water: updatedWater,
              alkaline: updatedAlkaline,
            },
          },
        });
        API.graphql({
          query: deleteTransaction,
          variables: {
            input: {
              id: undoChosenTransaction.id,
            },
          },
        });
        setIsUndoLoading(false);
        setUndoActive(false);
        setCashierPin("");
        setUndoChosenTransaction("");
        setUndoComplete(true);
        setItems([]);
        toast.success("Undo Action successful");
      } catch (error) {
        toast.error("error occured");
        console.error("error occured", error);
      }
    } else if (
      //for multi users
      customer.length > 0 &&
      inputedCashier !== "notFound" &&
      isUndoLoading === false
    ) {
      try {
        setIsUndoLoading(true);
        API.graphql({
          query: updateCustomer,
          variables: {
            input: {
              id: customer[0].id,
              ice: updatedIce,
              water: updatedWater,
              alkaline: updatedAlkaline,
            },
          },
        });
        API.graphql({
          query: deleteTransaction,
          variables: {
            input: {
              id: undoChosenTransaction.id,
            },
          },
        });
        setIsUndoLoading(false);
        setUndoActive(false);
        setCashierPin("");
        setUndoChosenTransaction("");
        setUndoComplete(true);
        setItems([]);
        toast.success("Undo Action successful");
      } catch (error) {
        setUndoActive(false);
        toast.error("error occured");
        console.error("error occured", error);
      }
    }
  };

  // TODO: attempt at trying to reload data once data is deleted. This is a bad method anyway, the schema should have a column called undo that is boolean instead of deleting data
  // useEffect(() => {
  //   if (undoComplete) {
  //     setItems([]);
  //     const timer = setTimeout(() => {
  //       handleSearch("start");
  //       setUndoComplete(false);
  //     }, 1000); // 1000 milliseconds = 1 second

  //     // Cleanup function to clear the timeout if the component unmounts
  //     return () => clearTimeout(timer);
  //   }
  // }, [undoComplete]);

  //========================================================================================

  return (
    <div class={expender ? "contentWithSideBarThin" : "contentWithSideBar"}>
      <div class="pageTitle">
        <h1>Subtraction History</h1>
      </div>
      <div class="contentContainer">
        <div class="tableContainer">
          {/* <SearchBar
            type="text"
            placeholder="Search name or number"
            onSearchChange={handleSearchChange}
            pageSize={pageSize}
            setPageSize={setPageSize}
            setQuery={setQuery}
          /> */}
          <div className="flex">
            <SearchBarNew
              state={searchInput}
              setState={setSearchInput}
              placeholder={"Paste Customer ID Here"}
            />
            <div className="primaryBtn" onClick={() => handlePaste()}>
              Paste
            </div>
            <div className="primaryBtn" onClick={() => handleSearch("start")}>
              Search
            </div>
          </div>
          {/* <div class="badgesAndPageSize">
            <div class="totalAmountOfSale">
              <div>Total Transaction Amount:</div>
              <div class="badgeIce">{`Ice: -${totalProductSale(
                "ice",
                sorted
              )}lb`}</div>
              <div class="badgeWater">{`Water: -${totalProductSale(
                "water",
                sorted
              )}gal`}</div>
              <div class="badgeAlkaline">{`Alkaline: -${totalProductSale(
                "alkaline",
                sorted
              )}gal`}</div>
            </div>
            <div>
              <PageSizeSelector pageSize={pageSize} setPageSize={setPageSize} />
            </div>
          </div> */}
          <table>
            <tr class="tableRow">
              <th
              // onClick={() => handleSort(columns[0].path)}
              >
                <div class="tableHead">
                  <div>
                    <div>First</div>
                    <div>Name</div>
                  </div>
                  {/* <div class="sortIcon">{renderSortIcon(columns[0].path)}</div> */}
                </div>
              </th>
              <th
              // onClick={() => handleSort(columns[1].path)}
              >
                <div class="tableHead">
                  <div>
                    <div>Last</div>
                    <div>Name</div>
                  </div>
                  {/* <div class="sortIcon">{renderSortIcon(columns[1].path)}</div> */}
                </div>
              </th>
              <th class="hiddenForMobile">
                <div class="tableHead">Phone Number</div>
              </th>
              <th>
                <div class="tableHead">Amount</div>
              </th>
              <th
                // onClick={() => handleSort(columns[2].path)}
                class="createdAtTableColumn"
              >
                <div class="tableHead">
                  <div>Time</div>
                  {/* <div class="sortIcon">{renderSortIcon(columns[2].path)}</div> */}
                </div>
              </th>
              {userData.length > 0 ? (
                <th
                  // onClick={() => handleSort(columns[3].path)}
                  class="createdAtTableColumn"
                >
                  <div class="tableHead">
                    <div>Cashier</div>
                    {/* <div class="sortIcon">
                      {renderSortIcon(columns[3].path)}
                    </div> */}
                  </div>
                </th>
              ) : (
                ""
              )}
              <th>
                <div class="tableHead">
                  <div>Undo</div>
                </div>
              </th>
            </tr>
            {/* TODO: input new items here */}
            {paginated.map((data) => {
              return (
                <tr class="tableRow">
                  <td class="wordBreak">{data.customerFirstName}</td>
                  <td class="wordBreak">{data.customerLastName}</td>
                  <td class="hiddenForMobile">{data.customerPhoneNumber}</td>
                  <td class="badgeColumn">
                    {data.iceAmount ? (
                      <div class="badgeIce">{`-${data.iceAmount}`}</div>
                    ) : (
                      ""
                    )}{" "}
                    {data.waterAmount ? (
                      <div class="badgeWater">{`-${data.waterAmount}`}</div>
                    ) : (
                      ""
                    )}{" "}
                    {data.alkalineAmount ? (
                      <div class="badgeAlkaline">{`-${data.alkalineAmount}`}</div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td class="createdAtTableColumn">
                    {new Date(data.createdAt).toLocaleString()}
                  </td>
                  {userData.length > 0 ? (
                    <td>
                      <div>{data.cashier}</div>
                    </td>
                  ) : (
                    ""
                  )}
                  <td>
                    {new Date().toISOString() >
                      addedHour(data.createdAt, undoTimeLimitInHour) ===
                    true ? (
                      ""
                    ) : (
                      <div>
                        <div
                          className={
                            undoActive === true &&
                            undoChosenTransaction.id === data.id
                              ? "badgePrimary"
                              : "badgeGray"
                          }
                          onClick={() => customerChoser(data)}
                        >
                          Undo
                        </div>
                        {undoActive === true &&
                        undoChosenTransaction.id === data.id ? (
                          <div className="relative">
                            <div className={styles.undoTransactionContainer}>
                              <div className={styles.undoTitle}>
                                Confirm Undo Action
                              </div>
                              <div>
                                Name: {data.customerFirstName}{" "}
                                {data.customerLastName}
                              </div>
                              <hr />
                              <div>Undo Amount:</div>
                              <div className="flex">
                                {data.iceAmount > 0 ? (
                                  <div className="flex">
                                    <div className="rightMargin">Ice:</div>
                                    <div className="badgeIce">
                                      {data.iceAmount}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {data.waterAmount > 0 ? (
                                  <div className="flex">
                                    <div className="rightMargin">Water:</div>
                                    <div className="badgeWater">
                                      {data.waterAmount}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {data.alkalineAmount > 0 ? (
                                  <div className="flex">
                                    <div className="rightMargin">Alkaline:</div>
                                    <div className="badgeAlkaline">
                                      {data.alkalineAmount}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className={styles.undoButtonContainer}>
                                {customer.length > 0 ? (
                                  <>
                                    {userData.length > 0 ? (
                                      <>
                                        <hr />
                                        <div
                                          className={styles.pinInputContainer}
                                        >
                                          <div>
                                            <i class="fa-solid fa-key"></i> Pin:
                                          </div>
                                          <div>
                                            <NumericFormat
                                              className={styles.pinInput}
                                              allowLeadingZeros
                                              onKeyUp={(e) =>
                                                preventEnterSubmit(e)
                                              }
                                              type="password"
                                              format="####"
                                              mask="_"
                                              placeholder="****"
                                              allowEmptyFormatting
                                              minlength="4"
                                              maxlength="8"
                                              autocomplete="new-password"
                                              onChange={(e) =>
                                                setCashierPin(e.target.value)
                                              }
                                              value={cashierPin}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <div
                                      className="deleteBtn"
                                      onClick={() => undoSubmit()}
                                    >
                                      Undo Transaction
                                    </div>
                                  </>
                                ) : (
                                  <div className="disabledBtn">
                                    Customer Not Found
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
        <div class="paginationPosition">
          {/* <Pagination
            totalNumOfClient={searched.length}
            onNextPage={handleNextPage}
            onPrevPage={handlePrevPage}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            pageSize={pageSize}
          /> */}
          <PaginationServerSide
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            maxPage={maxPage}
            token={token}
            items={items}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
