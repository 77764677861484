import React from "react";
import styles from "../../styles/SearchBar.module.css";
import preventEnterSubmit from "./util/preventEnterSubmit";
import { useNavigate } from "react-router-dom";

const SearchBar = ({
  onSearchChange,
  placeholder,
  setQuery,
  query,
  setChosenCustomer,
}) => {
  const navigate = useNavigate();
  const handleClear = () => {
    document.getElementById("search-input").value = "";
    setChosenCustomer("");
    setQuery("");
    navigate("/customer");
  };

  return (
    <div className={styles.container}>
      <div className={styles.searchBarContainer}>
        <div className="flex">
          <div className={styles.magnifyingGlassIcon}>&#xf002;</div>
          <input
            onKeyUp={(e) => preventEnterSubmit(e)}
            id="search-input"
            type="text"
            className={styles.searchInput}
            placeholder={placeholder}
            onChange={(event) => onSearchChange(event)}
            value={query}
          />
        </div>
        {query !== "" ? (
          <div className="flex">
            <div className={styles.clearBtn} onClick={() => handleClear()}>
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SearchBar;

{
  /* <div className={styles.clearBtn}>
            <input type="file" accept="image/*" capture="camera" />
          </div> */
}
