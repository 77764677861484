import React, { useState } from "react";
import { paginate } from "./util/paginate";
import Pagination from "./Pagination";
import SearchBar from "./SearchBar";
import _ from "lodash";
import PageSizeSelector from "./PageSizeSelector";
import { API } from "aws-amplify";
import { ToastContainer, toast } from "react-toastify";
import { updateCustomer } from "../../graphql/mutations";
import { deleteRecharge } from "../../graphql/mutations";
import addedHour from "./util/addedHour";
import styles from "../../styles/TransactionHistory.module.css";
import { NumericFormat } from "react-number-format";
import preventEnterSubmit from "./util/preventEnterSubmit";
import useRechargeData from "../../hooks/rechargeHooks";

export const RechargeHistory = ({
  // rechargeData,
  userData,
  expender,
  customerData,
  route,
}) => {
  const rechargeData = useRechargeData(route);

  const [currentPage, setCurrentPage] = useState(1); //default 1
  const [query, setQuery] = useState(""); //Search Feature
  const [pageSize, setPageSize] = useState(20); //set able 5, 10, 30
  const [sortColumn_path, setSortColumn_path] = useState("createdAt");
  const [sortColumn_order, setSortColumn_order] = useState("desc");
  const [cashierPin, setCashierPin] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [allUsers, setAllUsers] = useState([]);
  fetch(userData).then(() => setAllUsers(userData));
  const cashierNum = allUsers.map((e) => e.pin).indexOf(cashierPin);
  const inputedCashier =
    cashierNum > -1 ? allUsers[cashierNum].name : "notFound";

  const columns = [
    { path: "customerFirstName", label: "First Name" },
    { path: "customerLastName", label: "Last Name" },
    { path: "createdAt", label: "Recharged At" },
    { path: "cashier", label: "Cashier" },
  ];

  //TODO: Stuck point, and unfinished due to customerID repetition of being "new customer" when registering.
  //TODO: Need to recode the registration so that it is a multi step process. Submit Customer Info First, Then choose the plan and submit.
  //TODO: I need to do this because the customerID within the rechargeData cannot be called "New Registration"

  const undoTimeLimitInHour = 24; //TODO: Given amount of time to do UNDO action.
  const [undoChosenRecharge, setUndoChosenRecharge] = useState("");
  const [undoActive, setUndoActive] = useState(false);

  const customer = customerData.filter(
    (c) => c.id === undoChosenRecharge.customerID
  );

  console.log(undoChosenRecharge);
  console.log(customer);

  const customerChoser = (data) => {
    undoActive ? setUndoActive(false) : setUndoActive(true);
    setUndoChosenRecharge(data);
  };

  const updatedIce =
    customer.length > 0
      ? customer[0].ice - undoChosenRecharge.iceAmount
      : "customer not found";
  const updatedWater =
    customer.length > 0
      ? customer[0].water - undoChosenRecharge.waterAmount
      : "customer not found";
  const updatedAlkaline =
    customer.length > 0
      ? customer[0].alkaline - undoChosenRecharge.alkalineAmount
      : "customer not found";

  // const undoSubmit = () => {
  //   if (updatedIce < 0 || updatedWater < 0 || updatedAlkaline < 0) {
  //     toast.error(
  //       "Insufficient balance! Undoing added balance not possible as customer has used it."
  //     );
  //   } else if (
  //     customer.length > 0 &&
  //     updatedIce >= 0 &&
  //     updatedWater >= 0 &&
  //     updatedAlkaline >= 0
  //   ) {
  //     try {
  //       API.graphql({
  //         query: updateCustomer,
  //         variables: {
  //           input: {
  //             id: customer[0].id,
  //             ice: updatedIce,
  //             water: updatedWater,
  //             alkaline: updatedAlkaline,
  //           },
  //         },
  //       });
  //       API.graphql({
  //         query: deleteRecharge,
  //         variables: {
  //           input: {
  //             id: undoChosenRecharge.id,
  //           },
  //         },
  //       });
  //       toast.success("Undo Action successful");
  //     } catch (error) {
  //       console.error("error occured", error);
  //     }
  //   }
  // };

  //========================================================================================

  const undoSubmit = async () => {
    if (
      customer.length > 0 &&
      userData.length > 0 &&
      inputedCashier === "notFound"
    ) {
      toast.error("No Existing User Pin");
    } else if (updatedIce < 0 || updatedWater < 0 || updatedAlkaline < 0) {
      toast.error(
        "Insufficient balance! Undoing added balance not possible as customer has used it."
      );
    } else if (
      //for single users
      customer.length > 0 &&
      userData.length === 0 &&
      inputedCashier === "notFound" &&
      updatedIce >= 0 &&
      updatedWater >= 0 &&
      updatedAlkaline >= 0 &&
      isLoading === false
    ) {
      try {
        setIsLoading(true);
        API.graphql({
          query: updateCustomer,
          variables: {
            input: {
              id: customer[0].id,
              ice: updatedIce,
              water: updatedWater,
              alkaline: updatedAlkaline,
            },
          },
        });
        API.graphql({
          query: deleteRecharge,
          variables: {
            input: {
              id: undoChosenRecharge.id,
            },
          },
        });
        setIsLoading(false);
        toast.success("Undo Action successful");
      } catch (error) {
        console.error("error occured", error);
      }
    } else if (
      //for multi users
      customer.length > 0 &&
      inputedCashier !== "notFound" &&
      updatedIce >= 0 &&
      updatedWater >= 0 &&
      updatedAlkaline >= 0 &&
      isLoading === false
    ) {
      try {
        setIsLoading(true);
        API.graphql({
          query: updateCustomer,
          variables: {
            input: {
              id: customer[0].id,
              ice: updatedIce,
              water: updatedWater,
              alkaline: updatedAlkaline,
            },
          },
        });
        API.graphql({
          query: deleteRecharge,
          variables: {
            input: {
              id: undoChosenRecharge.id,
            },
          },
        });
        setIsLoading(false);
        toast.success("Undo Action successful");
      } catch (error) {
        console.error("error occured", error);
      }
    }
  };

  //========================================================================================

  //TODO: if the updatedBalance is in the negative, then error saying "not enough balance"

  const totalProductSale = (product) => {
    let arr = [];
    let sum = 0;
    if (product === "ice") {
      sorted.forEach((transaction) => arr.push(transaction.iceAmount));
    } else if (product === "water") {
      sorted.forEach((transaction) => arr.push(transaction.waterAmount));
    } else if (product === "alkaline") {
      sorted.forEach((transaction) => arr.push(transaction.alkalineAmount));
    }
    arr.forEach((item) => (sum += item));
    return sum;
  };

  const searched = rechargeData.filter((data) => {
    if (query === "") {
      return data;
    } else if (
      data.customerFirstName.toLowerCase().includes(query.toLowerCase()) ||
      data.customerLastName.toLowerCase().includes(query.toLowerCase()) ||
      data.customerPhoneNumber.includes(query)
    ) {
      return data;
    }
  });
  const sorted = _.orderBy(searched, [sortColumn_path], [sortColumn_order]);
  const paginated = paginate(sorted, currentPage, pageSize);

  const handleSearchChange = (event) => {
    setQuery(event.target.value);
    setCurrentPage(1);
  };
  const handleSort = (path) => {
    if (path === sortColumn_path) {
      setSortColumn_order(sortColumn_order === "asc" ? "desc" : "asc");
      setCurrentPage(1);
    } else {
      setSortColumn_path(path);
      setSortColumn_order("asc");
      renderSortIcon(path);
      setCurrentPage(1);
    }
  };
  const renderSortIcon = (column) => {
    if (column === sortColumn_path && sortColumn_order === "desc") {
      return <i class="fa-solid fa-caret-up"></i>;
    } else if (column === sortColumn_path && sortColumn_order === "asc") {
      return <i class="fa-solid fa-caret-down"></i>;
    } else {
      return <i class="fa-solid fa-sort"></i>;
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      return setCurrentPage(currentPage - 1);
    }
  };
  const handleNextPage = () => {
    if (currentPage < paginated.length) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div class={expender ? "contentWithSideBarThin" : "contentWithSideBar"}>
      <div class="pageTitle">
        <h1>Addition History</h1>
      </div>
      <div class="contentContainer">
        <div class="tableContainer">
          <SearchBar
            type="text"
            placeholder="Search name or number"
            onSearchChange={handleSearchChange}
            pageSize={pageSize}
            setPageSize={setPageSize}
            setQuery={setQuery}
          />
          <div class="badgesAndPageSize">
            <div class="totalAmountOfSale">
              <div>Total Recharge Amount:</div>
              {/* <div class="badgeIce">{`Ice: +${totalProductSale("ice")}lb`}</div> */}
              <div class="badgeWater">{`Water: +${totalProductSale(
                "water"
              )}gal`}</div>
              <div class="badgeAlkaline">{`Alkaline: +${totalProductSale(
                "alkaline"
              )}gal`}</div>
            </div>
            <div>
              <PageSizeSelector pageSize={pageSize} setPageSize={setPageSize} />
            </div>
          </div>
          <table>
            <tr class="tableRow">
              <th onClick={() => handleSort(columns[0].path)}>
                <div class="tableHead">
                  <div>
                    <div>First</div>
                    <div>Name</div>
                  </div>
                  <div class="sortIcon">{renderSortIcon(columns[0].path)}</div>
                </div>
              </th>
              <th onClick={() => handleSort(columns[1].path)}>
                <div class="tableHead">
                  <div>
                    <div>Last</div>
                    <div>Name</div>
                  </div>
                  <div class="sortIcon">{renderSortIcon(columns[1].path)}</div>
                </div>
              </th>
              <th class="hiddenForMobile">
                <div class="tableHead">Phone Number</div>
              </th>
              <th>
                <div class="tableHead">Amount</div>
              </th>
              <th
                onClick={() => handleSort(columns[2].path)}
                class="createdAtTableColumn"
              >
                <div class="tableHead">
                  <div>Time</div>
                  <div class="sortIcon">{renderSortIcon(columns[2].path)}</div>
                </div>
              </th>
              {userData.length > 0 ? (
                <th
                  onClick={() => handleSort(columns[3].path)}
                  class="createdAtTableColumn"
                >
                  <div class="tableHead">
                    <div>Cashier</div>
                    <div class="sortIcon">
                      {renderSortIcon(columns[3].path)}
                    </div>
                  </div>
                </th>
              ) : (
                ""
              )}
              <th>
                <div class="tableHead">
                  <div>Undo</div>
                </div>
              </th>
            </tr>
            {paginated.map((data) => {
              return (
                <tr class="tableRow">
                  <td class="wordBreak">{data.customerFirstName}</td>
                  <td class="wordBreak">{data.customerLastName}</td>
                  <td class="hiddenForMobile">{data.customerPhoneNumber}</td>
                  <td class="badgeColumn">
                    {data.iceAmount ? (
                      <div class="badgeIce">{`+${data.iceAmount}`}</div>
                    ) : (
                      ""
                    )}{" "}
                    {data.waterAmount ? (
                      <div class="badgeWater">{`+${data.waterAmount}`}</div>
                    ) : (
                      ""
                    )}{" "}
                    {data.alkalineAmount ? (
                      <div class="badgeAlkaline">{`+${data.alkalineAmount}`}</div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td class="createdAtTableColumn">
                    {/* {data.createdAt} */}
                    {new Date(data.createdAt).toLocaleString()}
                  </td>
                  {userData.length > 0 ? (
                    <td>
                      <div>{data.cashier}</div>
                    </td>
                  ) : (
                    ""
                  )}
                  <td>
                    {new Date().toISOString() >
                      addedHour(data.createdAt, undoTimeLimitInHour) ===
                    true ? (
                      ""
                    ) : (
                      <div>
                        <div
                          className={
                            undoActive === true &&
                            undoChosenRecharge.id === data.id
                              ? "badgePrimary"
                              : "badgeGray"
                          }
                          onClick={() => customerChoser(data)}
                        >
                          Undo
                        </div>
                        {undoActive === true &&
                        undoChosenRecharge.id === data.id ? (
                          <div className="relative">
                            <div className={styles.undoTransactionContainer}>
                              <div className={styles.undoTitle}>
                                Confirm Undo Action
                              </div>
                              <div>
                                Name: {data.customerFirstName}{" "}
                                {data.customerLastName}
                              </div>
                              <hr />
                              <div>Undo Amount:</div>
                              <div className="flex">
                                {data.iceAmount > 0 ? (
                                  <div className="flex">
                                    <div className="rightMargin">Ice:</div>
                                    <div className="badgeIce">
                                      {data.iceAmount}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {data.waterAmount > 0 ? (
                                  <div className="flex">
                                    <div className="rightMargin">Water:</div>
                                    <div className="badgeWater">
                                      {data.waterAmount}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {data.alkalineAmount > 0 ? (
                                  <div className="flex">
                                    <div className="rightMargin">Alkaline:</div>
                                    <div className="badgeAlkaline">
                                      {data.alkalineAmount}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className={styles.undoButtonContainer}>
                                {customer.length > 0 ? (
                                  <>
                                    {userData.length > 0 ? (
                                      <>
                                        <hr />
                                        <div
                                          className={styles.pinInputContainer}
                                        >
                                          <div>
                                            <i class="fa-solid fa-key"></i> Pin:
                                          </div>
                                          <div>
                                            {/* <input
                                              onChange={(e) => setCashierPin(e.target.value)}
                                              onKeyUp={(e) => preventEnterSubmit(e)}
                                              className={styles.pinInput}
                                              placeholder="****"
                                            /> */}
                                            <NumericFormat
                                              className={styles.pinInput}
                                              allowLeadingZeros
                                              onKeyUp={(e) =>
                                                preventEnterSubmit(e)
                                              }
                                              type="password"
                                              format="####"
                                              mask="_"
                                              placeholder="****"
                                              allowEmptyFormatting
                                              minlength="4"
                                              maxlength="8"
                                              autocomplete="new-password"
                                              onChange={(e) =>
                                                setCashierPin(e.target.value)
                                              }
                                              value={cashierPin}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <div
                                      className="deleteBtn"
                                      onClick={() => undoSubmit()}
                                    >
                                      Undo Transaction
                                    </div>
                                  </>
                                ) : (
                                  <div className="disabledBtn">
                                    Customer Not Found
                                  </div>
                                )}
                                {/* {customer.length < 1 ? (
                                  <div className="disabledBtn">
                                    Customer Not Found
                                  </div>
                                ) : (
                                  ""
                                )} */}
                                {updatedIce < 0 ||
                                  updatedWater < 0 ||
                                  updatedAlkaline < 0}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
        <div class="paginationPosition">
          <Pagination
            totalNumOfClient={searched.length}
            onNextPage={handleNextPage}
            onPrevPage={handlePrevPage}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
