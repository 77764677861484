/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      ice
      water
      alkaline
      iceBagType1
      iceBagType2
      iceBagType3
      iceBlockType1
      iceBlockType2
      iceBlockType3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        fullName
        phoneNumber
        ice
        water
        alkaline
        iceBagType1
        iceBagType2
        iceBagType3
        iceBlockType1
        iceBlockType2
        iceBlockType3
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCustomerTest = /* GraphQL */ `
  query GetCustomerTest($id: ID!) {
    getCustomerTest(id: $id) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      water
      alkaline
      iceBagType1
      iceBagType2
      iceBagType3
      iceBlockType1
      iceBlockType2
      iceBlockType3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCustomerTests = /* GraphQL */ `
  query ListCustomerTests(
    $filter: ModelCustomerTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        fullName
        phoneNumber
        water
        alkaline
        iceBagType1
        iceBagType2
        iceBagType3
        iceBlockType1
        iceBlockType2
        iceBlockType3
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPricing = /* GraphQL */ `
  query GetPricing($id: ID!) {
    getPricing(id: $id) {
      id
      type
      amount
      price
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPricings = /* GraphQL */ `
  query ListPricings(
    $filter: ModelPricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        amount
        price
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getIceProduct = /* GraphQL */ `
  query GetIceProduct($id: ID!) {
    getIceProduct(id: $id) {
      id
      iceBagSize1
      iceBagSize2
      iceBagSize3
      iceBlockSize1
      iceBlockSize2
      iceBlockSize3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listIceProducts = /* GraphQL */ `
  query ListIceProducts(
    $filter: ModelIceProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIceProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        iceBagSize1
        iceBagSize2
        iceBagSize3
        iceBlockSize1
        iceBlockSize2
        iceBlockSize3
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getIcePricing = /* GraphQL */ `
  query GetIcePricing($id: ID!) {
    getIcePricing(id: $id) {
      id
      type
      weight
      amount
      price
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listIcePricings = /* GraphQL */ `
  query ListIcePricings(
    $filter: ModelIcePricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIcePricings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        weight
        amount
        price
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRecharge = /* GraphQL */ `
  query GetRecharge($id: ID!) {
    getRecharge(id: $id) {
      id
      customerID
      customerFirstName
      customerLastName
      customerPhoneNumber
      iceAmount
      waterAmount
      alkalineAmount
      cashier
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRecharges = /* GraphQL */ `
  query ListRecharges(
    $filter: ModelRechargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecharges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        customerFirstName
        customerLastName
        customerPhoneNumber
        iceAmount
        waterAmount
        alkalineAmount
        cashier
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const rechargeByCustomerID = /* GraphQL */ `
  query RechargeByCustomerID(
    $customerID: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRechargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rechargeByCustomerID(
      customerID: $customerID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        customerFirstName
        customerLastName
        customerPhoneNumber
        iceAmount
        waterAmount
        alkalineAmount
        cashier
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      customerID
      customerFirstName
      customerLastName
      customerPhoneNumber
      iceAmount
      waterAmount
      alkalineAmount
      cashier
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerID
        customerFirstName
        customerLastName
        customerPhoneNumber
        iceAmount
        waterAmount
        alkalineAmount
        cashier
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const transactionByCustomerID = /* GraphQL */ `
  query TransactionByCustomerID(
    $customerID: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionByCustomerID(
      customerID: $customerID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerID
        customerFirstName
        customerLastName
        customerPhoneNumber
        iceAmount
        waterAmount
        alkalineAmount
        cashier
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getStore = /* GraphQL */ `
  query GetStore($id: ID!) {
    getStore(id: $id) {
      id
      ownerFirstName
      ownerLastName
      storeName
      storePhoneNumber
      storeEmail
      storeAddyLine1
      storeAddyLine2
      storeAddyCity
      storeAddyState
      storeAddyZipCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listStores = /* GraphQL */ `
  query ListStores(
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerFirstName
        ownerLastName
        storeName
        storePhoneNumber
        storeEmail
        storeAddyLine1
        storeAddyLine2
        storeAddyCity
        storeAddyState
        storeAddyZipCode
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      pin
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        pin
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
