import React from "react";
import CustomerRetention from "./charts/CustomerRetention";
import RechargeChart from "./charts/AdditionChart";
import TransactionChart from "./charts/SubtractionChart";
import CustomersGrowth from "./charts/CustomersGrowth";
import styles from "../../styles/Dashboard.module.css";
import TopCustomers from "./charts/TopCustomer";
import TotalCustomers from "./charts/TotalCustomers";
import PopularDaysOfWeek from "./charts/PopularDaysOfWeek";
import PopularTimesOfDay from "./charts/PopularTimesOfDay";
import TodaysVisits from "./charts/TodaysVisits";
import TodaysTransactions from "./charts/TodaysTransactions";
import ProductsSoldOvertime from "./charts/ProductsSoldOvertime";
import TotalTransactionsByMonth from "./charts/TotalTransactionsByMonth";
import TotalTransactionsByDay from "./charts/TotalTransactionsByDay";

export const DashboardExample = ({
  customerData,
  // transactionData,
  // rechargeData,
  storeData,
  expender,
  route,
}) => {
  const startOfTimePeriod = () => {
    const d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    //This is the current day midnight(very beginning of the day) 00:00:00. Mon May 01 2023 00:00:00 GMT-0700 (Mountain Standard Time)
    d.setDate(d.getDate());
    const time = d.toISOString(); // This is the yesterday's midnight in UTC format. 2023-04-30T07:00:00.249Z

    return time;
  };
  const endOfTimePeriod = () => {
    const d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    //This is the current day midnight(very beginning of the day) 00:00:00. Mon May 01 2023 00:00:00 GMT-0700 (Mountain Standard Time)
    d.setDate(d.getDate() + 1);
    const time = d.toISOString(); // This is the yesterday's midnight in UTC format. 2023-04-30T07:00:00.249Z

    return time;
  };

  const startTime = startOfTimePeriod();
  const endTime = endOfTimePeriod();

  const todaysCustomers = customerData.filter(
    (c) => c.updatedAt > startTime && c.updatedAt < endTime
  );

  const todaysNewCustomers = customerData.filter(
    (c) => c.createdAt > startTime && c.createdAt < endTime
  );

  return (
    <div class={expender ? "contentWithSideBarThin" : "contentWithSideBar"}>
      <div className="pageTitle">
        <h1>{storeData[0].storeName}'s Dashboard</h1>
      </div>
      <div className={styles.dashboardContainer}>
        <div className={styles.column}>
          {/* TODO:section start =============================== */}
          <div className={styles.sectionContainer}>
            <div className={styles.sectionTitle}>
              <h2>Today's Performance</h2>
            </div>
            <div className={styles.flexCenter}>
              <div className={styles.dashboardContentContainer}>
                <h3>Today's Visits: {todaysCustomers.length} </h3>
                <div>New or returning customers</div>
                <div className={styles.donutContainer}>
                  <TodaysVisits
                    todaysCustomers={todaysCustomers}
                    todaysNewCustomers={todaysNewCustomers}
                  />
                </div>
              </div>
              <div className={styles.dashboardContentContainer}>
                <div>
                  <h3>Today's Transactions</h3>
                  <div>Transaction in Amounts</div>
                </div>
                <TodaysTransactions
                  // rechargeData={rechargeData}
                  // transactionData={transactionData}
                  startTime={startTime}
                  endTime={endTime}
                  route={route}
                />
              </div>
            </div>
          </div>
          {/* TODO:section start =============================== */}
          {/* <div className={styles.sectionContainer}>
            <div className={styles.sectionTitle}>
              <h2>Overall Performance</h2>
            </div>
            <div className={styles.flexCenter}>
              <div className={styles.dashboardContentContainer}>
                <h3>Popular days of week</h3>
                <div>All transactions combined</div>
                <PopularDaysOfWeek
                  transactionData={transactionData}
                  rechargeData={rechargeData}
                />
              </div>

              <div className={styles.dashboardContentContainer}>
                <div>
                  <h3>Products sold overtime</h3>
                  <div>Total subtraction transactions combined</div>
                </div>
                <ProductsSoldOvertime
                  rechargeData={rechargeData}
                  transactionData={transactionData}
                />
              </div>
            </div>
          </div> */}
          {/* TODO:ection start =============================== */}
          {/* <div className={styles.sectionContainer}>
            <div className={styles.sectionTitle}>
              <h2>Customer Insights</h2>
            </div>
            <div className={styles.flexCenter}>
              <div className={styles.dashboardContentContainer}>
                <h3>Total Customers</h3>
                <TotalCustomers customerData={customerData} />
              </div>

              <div className={styles.dashboardContentContainer}>
                <div>
                  <h3>Customer Retention</h3>
                  <div>visit frequency of customers</div>
                </div>
                <div className="flex">
                  <CustomerRetention customerData={customerData} />
                </div>
              </div>
            </div>
            <div className={styles.flexCenter}>
              <div className={styles.dashboardContentContainer}>
                <h3>Top Customers</h3>
                <TopCustomers transactionData={transactionData} />
              </div>

              <div className={styles.dashboardContentContainer}>
                <div>
                  <h3>Customers Growth</h3>
                </div>
                <CustomersGrowth
                  rechargeData={rechargeData}
                  customerData={customerData}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.column}> */}
          {/* TODO:section start =============================== */}
          {/* <div className={styles.sectionContainer}>
            <div className={styles.sectionTitle}>
              <h2>Transaction Details</h2>
            </div>

            <div className={styles.dashboardContentContainer}>
              <div>
                <h3>Popular times of day</h3>
              </div>
              <PopularTimesOfDay
                transactionData={transactionData}
                rechargeData={rechargeData}
              />
            </div>
            <div className={styles.dashboardContentContainer}>
              <div>
                <h3>Total Transactions by month</h3>
                <div>Amount of products sold in each month</div>
              </div>
              <TotalTransactionsByMonth
                rechargeData={rechargeData}
                transactionData={transactionData}
              />
              <div className={styles.testGraphSize}></div>
            </div>

            <div className={styles.dashboardContentContainer}>
              <div>
                <h3>Total Transactions by day</h3>
                <div>Amount of products sold in each day</div>
              </div>
              <TotalTransactionsByDay
                rechargeData={rechargeData}
                transactionData={transactionData}
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
