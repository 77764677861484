import phone from "../../../asset/phone.svg";
import styles from "../../../styles/Feature.module.css";

const FeatureElevated = () => {
  return (
    <div className={styles.greenBg}>
      <div className={styles.flexContainer}>
        <div className={styles.sectionMainImg}>
          <img src={phone} alt="" />
        </div>
        <div class="contentContainer">
          <div class="relative">
            <div className={styles.premiumFeature}>
              <i class="fa-solid fa-award"></i>
              <div>Premium feature</div>
            </div>
          </div>
          <div className={styles.title}>
            <h2 class="featureCardIcon">
              <i class="fa-regular fa-face-smile-beam"></i>
            </h2>
            <h2>Elevated Customer Experiences</h2>
          </div>
          <div>
            <div className={styles.textContainerBig}>
              {/* Elevate your customers' experience with our automated text
              feature. Whenever a customer pre-pay or uses their remaining
              balance, they will receive a text receipt with their name, the
              action taken (recharge or usage), and their remaining balance.
              This not only helps to build trust with your customers by
              providing proof of their visit, but also sets your water and ice
              store apart from the competition. The added convenience and
              confidence boost can promote customer loyalty, helping your
              business to thrive. */}
              <div>
                Upon registering a new customer, a text message will be sent to
                your customer giving a unique QR code ID which can be scanned to
                pull up the customer’s balance info.With this feature, it is
                easier to look up a customer, without needing to type in their
                name on a search bar.
              </div>
              <br />
              <div>
                This feature not only helps to build trust with your customers
                by providing a digital form of ID, but also sets your Water and
                Ice store apart from the competition. The added convenience,
                security, and confidence boost can promote customer loyalty,
                helping your business to thrive.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureElevated;
