import styles from "../../styles/Dashboard.module.css";

const ProductBox = ({ type, amount, desc }) => {
  const icon = () => {
    if (type === "ice") {
      return <i class="fa-solid fa-dice-d6"></i>;
    } else if (type === "water" || type === "alkaline") {
      return <i class="fa-solid fa-droplet"></i>;
    } else {
      return "";
    }
  };

  const formatNumber = () => {
    if (amount > 1000) {
      return (amount / 1000).toFixed(1) + "K";
    } else {
      return amount.toString();
    }
  };

  const formatStyle = () => {
    if (type === "ice") {
      return styles.productBoxIce;
    } else if (type === "water") {
      return styles.productBoxWater;
    } else if (type === "alkaline") {
      return styles.productBoxAlkaline;
    } else {
      return "";
    }
  };

  const formatIconStyle = () => {
    if (type === "ice") {
      return styles.productIconIce;
    } else if (type === "water") {
      return styles.productIconWater;
    } else if (type === "alkaline") {
      return styles.productIconAlkaline;
    } else {
      return "";
    }
  };

  return (
    <div className={formatStyle()}>
      <div className={formatIconStyle()}>{icon()}</div>
      <div className={styles.amountContainer}>
        <div className={styles.productAmount}>{formatNumber()}</div>
        <div>{type === "ice" ? "lb" : "gal"}</div>
      </div>
      <div>{desc}</div>
      {/* <div>%</div> */}
    </div>
  );
};

export default ProductBox;
