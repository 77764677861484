import React, {useState} from "react";
import styles from '../../styles/PriceCard.module.css';
import _ from "lodash";
import preventEnterSubmit from "./util/preventEnterSubmit";
import { API } from "aws-amplify";
import { ToastContainer, toast } from "react-toastify";
import { deletePricing } from "../../graphql/mutations";
import { NumericFormat } from "react-number-format";

const PriceCard = ({givenData, userData, label }) => {
    const [adminPinInput, setAdminPinInput] = useState("");
    const [chosenPricing, setChosenPricing] = useState("");
    const [actionToggle, setActionToggle] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const adminNum = userData.map((e) => e.name).indexOf("Admin");
    const adminPin = adminNum > -1 ? userData[adminNum].pin : "notFound";

    const pricingChooser = (data) => {
        setChosenPricing(data);
        setAdminPinInput("");
        actionToggle === false ? setActionToggle(true) : setActionToggle(false);
    }

    const tableActionBtnStyles = (data) => {
        if (
          actionToggle === true &&
          chosenPricing.amount === data.amount && 
          chosenPricing.price === data.price
        ) {
          return "tableActionActive";
        } else {
          return "tableAction";
        }
      };

    const sortColumn_path = "amount"
    const sortColumn_order = "asc"

    const sorted = _.orderBy(givenData, [sortColumn_path], [sortColumn_order]);

    const badgeClass = () => {
        if(label === "Ice"){
            return "iconIce"
        } else if(label === "Water"){
            return "iconWater"
        } else if(label === "Alkaline"){
            return "iconAlkaline"
        }
    }

    const barColor = () => {
        if(label === "Ice"){
            return styles.barIce
        } else if(label === "Water"){
            return styles.barWater
        } else if(label === "Alkaline"){
            return styles.barAlkaline
        }
    }

    const type = () => {
        return label === "Ice" ? "lb" : "gal";
    }

    const icon = () => {
        return label === "Ice" ? "fa-solid fa-dice-d6" : "fa-solid fa-droplet";
    }

    const onDeletionSubmit = async () => {
        if(userData.length > 0 && adminPinInput !== adminPin){
            toast.error("Wrong Admin Pin");
        } else if(userData.length > 0 && adminPinInput === adminPin && isLoading === false){
            try {
                setIsLoading(true)
                await API.graphql({
                  query: deletePricing,
                  variables: {
                    input: {
                      id: chosenPricing.id,
                    },
                  },
                });
                setChosenPricing(false);
                setActionToggle(false);
                setIsLoading(false);
                toast.success("Pricing Deleted");
              } catch (error) {
                console.error("error occured", error);
              }
        } else if (userData.length === 0 && isLoading === false) {
            try {
                setIsLoading(true)
                await API.graphql({
                  query: deletePricing,
                  variables: {
                    input: {
                      id: chosenPricing.id,
                    },
                  },
                });
                setChosenPricing(false);
                setActionToggle(false);
                setIsLoading(false);
                toast.success("Pricing Deleted");
              } catch (error) {
                console.error("error occured", error);
              }
        }else {
            toast.error("Error");
        }
    }

    return (
        <div className={styles.contentContainer}>
            <div className={styles.content}>
                <div className={styles.cardTitle}>
                    <div className={styles.titleLeft}>
                        <div className={badgeClass()}><i class={icon()}></i></div>
                        <div className={styles.titleText}>
                            {label} Pricing
                        </div>
                    </div>
                </div>
                <table className={styles.cardTable}>
                    <tr className={styles.headContainer}>
                        <th>Amount</th>
                        <th>Price</th>
                        <th>edit</th>
                    </tr>
                {sorted.map((data) => {
                return (
                    <tr key={data.id}>
                        <td>
                            <div className={styles.cardTableLine}>
                                <div className={styles.amount}>
                                {data.amount}
                                </div>
                                <div className={styles.amountSign}>
                                {type()}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className={styles.cardTableLine}>
                                <div className={styles.type}>$</div>
                                <div className={styles.priceSign}>{data.price}.00</div>
                            </div>
                        </td>
                        <td>
                            {/* TODO: the pin input is getting hidden from the card container */}
                            <div class="relative">
                                <div>
                                    <div className={styles.cardTableLine}>
                                        <div class={tableActionBtnStyles(data)} onClick={() => pricingChooser(data)}>
                                        <i class="fa-solid fa-ellipsis"></i>
                                        </div>
                                    </div>
                                    {actionToggle && 
                                    chosenPricing.price === data.price && 
                                    chosenPricing.amount=== data.amount ? 
                                    <div class="deleteTab">
                                        {userData.length > 0 ?                                         
                                        <div class="pinInputContainer">
                                            <div>
                                            <i class="fa-solid fa-key"></i> Pin: 
                                            </div>
                                            <div>
                                                {/* <input
                                                    onChange={(e) => setAdminPinInput(e.target.value)}
                                                    onKeyUp={(e) => preventEnterSubmit(e)}
                                                    class="pinInput"
                                                    placeholder="****"
                                                /> */}
                                                <NumericFormat
                                                    className={styles.pinInput}
                                                    allowLeadingZeros
                                                    onKeyUp={(e) => preventEnterSubmit(e)}
                                                    type="password"
                                                    format="####"
                                                    mask="_"
                                                    placeholder="****"
                                                    allowEmptyFormatting
                                                    minlength="4"
                                                    maxlength="8"
                                                    autocomplete="new-password"
                                                    onChange={(e) => setAdminPinInput(e.target.value)}
                                                    value={adminPinInput}
                                                />
                                            </div>
                                        </div>
                                        : ""}
                                        {isLoading ? <div  className="deleteBtn">
                                      <div class="loaderDelete"></div>
                                    </div> : <div className="deleteBtn" onClick={() => onDeletionSubmit()}>Delete</div>}
                                    </div>
                                    : ""}
                                </div>
                            </div>
                        </td>
                    </tr>
                );
                })}
                </table>
            </div>
                <div className={barColor()}>
            </div>
            <ToastContainer/>
        </div>
     );
}

 
export default PriceCard;