import feature11 from "../../images/feature/feature11.png";
import styles from "../../../styles/Feature.module.css";

const FeatureCustomizable = () => {
  return (
    <div className={styles.blueBg}>
      <div className={styles.flexContainerFlipped}>
        <div className={styles.sectionMainImg}>
          <img src={feature11} alt="" />
        </div>
        <div>
          <div className={styles.title}>
            <h2 class="featureCardIcon">
              <i class="fa-solid fa-user-group"></i>
            </h2>
            <div className={styles.colorWhite}>Customizble Prepaid Plans</div>
          </div>
          <div className={styles.textContainerBig}>
            Set the prices of your products according to your business needs
            with Hydrocard's customizable prepaid plans. Choose from three
            product types: Ice, Water, and Alkaline Water, and create new
            prepaid plans by selecting the type, amount, and price for each
            item. With the freedom to create their own prepaid plans and prices,
            users can choose to implement bulk purchase discounts or not,
            allowing them to create pricing models that work best for their
            business. When using these cards to recharge a customer's balance,
            the prices will be shown at the bottom of the form as a reminder of
            the plan cost. With a limit of 10 pricing plans per product type,
            you can offer your customers the best deals without compromising
            your profits.
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCustomizable;
