import React, { useState } from "react";
import { API } from "aws-amplify";
import { updateCustomer } from "../../graphql/mutations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../../styles/Forms.module.css";
import toggle from "./util/toggle";
import preventEnterSubmit from "./util/preventEnterSubmit";
import { NumericFormat, PatternFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";

const EditCustomerUI = ({
  chosenCustomer,
  setChosenCustomer,
  editButtonUIActive,
  setEditButtonUIActive,
  userData,
}) => {
  const [adminPinInput, setAdminPinInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const adminNum = userData.map((e) => e.name).indexOf("Admin");
  const adminPin = adminNum > -1 ? userData[adminNum].pin : "notFound";
  const navigate = useNavigate();

  const customerID = chosenCustomer.id;
  const [editCustomerFirstName, setEditCustomerFirstName] = useState(
    chosenCustomer.firstName
  );
  const [editCustomerLastName, setEditCustomerLastName] = useState(
    chosenCustomer.lastName
  );
  const [editCustomerPhoneNumber, setEditCustomerPhoneNumber] = useState(
    chosenCustomer.phoneNumber
  );
  // const [firstDash, setFirstDash] = useState(false);
  // const [secondDash, setSecondDash] = useState(false);

  // const addDashforPhoneNumber = () => {
  //   if (editCustomerPhoneNumber.length === 3 && firstDash === false) {
  //     setEditCustomerPhoneNumber(editCustomerPhoneNumber + "-");
  //     setFirstDash(true);
  //   } else if (editCustomerPhoneNumber.length === 7 && secondDash === false) {
  //     setEditCustomerPhoneNumber(editCustomerPhoneNumber + "-");
  //     setSecondDash(true);
  //   }
  //   if (editCustomerPhoneNumber.length === 2 && firstDash === true) {
  //     setFirstDash(false);
  //   } else if (editCustomerPhoneNumber.length === 6 && secondDash === true) {
  //     setSecondDash(false);
  //   }
  //   return editCustomerPhoneNumber;
  // };

  const onReset = () => {
    setAdminPinInput("");
    setEditCustomerFirstName(chosenCustomer.firstName);
    setEditCustomerLastName(chosenCustomer.lastName);
    setEditCustomerPhoneNumber(chosenCustomer.phoneNumber);
  };

  const onSubmitEdit = async () => {
    if (userData.length > 0 && adminPinInput !== adminPin) {
      toast.error("Wrong Admin Pin");
    } else if (
      isLoading == false &&
      userData.length > 0 &&
      adminPinInput === adminPin &&
      (editCustomerFirstName !== chosenCustomer.firstName ||
        editCustomerLastName !== chosenCustomer.lastName ||
        editCustomerPhoneNumber !== chosenCustomer.phoneNumber)
    ) {
      try {
        setIsLoading(true);
        await API.graphql({
          query: updateCustomer,
          variables: {
            input: {
              id: customerID,
              firstName: editCustomerFirstName,
              lastName: editCustomerLastName,
              phoneNumber: editCustomerPhoneNumber,
            },
          },
        });
        setEditButtonUIActive(false);
        setIsLoading(false);
        toast.success("Customer Info Edit Success!");
      } catch (error) {
        alert("error occured", error);
      }
    } else if (
      isLoading == false &&
      userData.length === 0 &&
      (editCustomerFirstName !== chosenCustomer.firstName ||
        editCustomerLastName !== chosenCustomer.lastName ||
        editCustomerPhoneNumber !== chosenCustomer.phoneNumber)
    ) {
      try {
        setIsLoading(true);
        await API.graphql({
          query: updateCustomer,
          variables: {
            input: {
              id: customerID,
              firstName: editCustomerFirstName,
              lastName: editCustomerLastName,
              phoneNumber: editCustomerPhoneNumber,
            },
          },
        });
        setEditButtonUIActive(false);
        setIsLoading(false);
        toast.success("Customer Info Edit Success!");
      } catch (error) {
        alert("error occured", error);
      }
    } else {
      toast.error("No change has been made!");
    }
  };

  const handlePhoneChange = ({ value }) => {
    setEditCustomerPhoneNumber(value);
  };

  const onCancel = () => {
    toggle(editButtonUIActive, setEditButtonUIActive);
    setChosenCustomer("");
    navigate("/customer");
  };

  return (
    <>
      <div className={styles.heading}>
        <h3>Edit Customer</h3>
        <h3 class="button" onClick={() => onCancel()}>
          <i class="fa-solid fa-x"></i>
        </h3>
      </div>
      <form id="edit-customer-info">
        {/* customer Info ======================================*/}
        <div className={styles.customerInfo}>
          <div className={styles.label}>Customer</div>
          <div className={styles.label}>
            <div class="hiddenForMobile">Balance</div>
          </div>
        </div>
        <div className={styles.customerInfo}>
          <div>
            <h1>
              {chosenCustomer.firstName} {chosenCustomer.lastName}
            </h1>
          </div>
          <div className={styles.badgeHolder}>
            {/* <div class="badgeIce">Ice: {chosenCustomer.ice}</div> */}
            <div class="badgeWater">Water: {chosenCustomer.water}</div>
            <div class="badgeAlkaline">Alkaline: {chosenCustomer.alkaline}</div>
          </div>
        </div>
        {/* customer Edit Info ======================================*/}
        <div className={styles.divider}></div>
        <div>
          <h4>Current Customer Info</h4>
          <div className={styles.textInputContainer}>
            <div>First Name</div>
            <div>
              <input
                // placeholder={editCustomerFirstName}
                onChange={(e) => setEditCustomerFirstName(e.target.value)}
                value={editCustomerFirstName}
              />
            </div>
          </div>
          <div className={styles.textInputContainer}>
            <div>Last Name</div>
            <div>
              <input
                // placeholder={editCustomerLastName}
                onChange={(e) => setEditCustomerLastName(e.target.value)}
                value={editCustomerLastName}
              />
            </div>
          </div>
          <div className={styles.textInputContainer}>
            <div>Phone Number</div>
            <div>
              {/* <input
                type="tel"
                id="phone"
                maxlength="12"
                // placeholder={editCustomerPhoneNumber}
                onChange={(e) => setEditCustomerPhoneNumber(e.target.value)}
                value={addDashforPhoneNumber()}
              /> */}
              <PatternFormat
                onKeyUp={(e) => preventEnterSubmit(e)}
                value={`(${editCustomerPhoneNumber.slice(
                  0,
                  3
                )}) ${editCustomerPhoneNumber.slice(
                  3,
                  6
                )}-${editCustomerPhoneNumber.slice(6)}`}
                format="(%%%) %%%-%%%%"
                patternChar="%"
                onValueChange={handlePhoneChange}
              />
            </div>
          </div>
        </div>
        {/* customer Edited Summary ======================================*/}
        <div>
          <div className={styles.divider}></div>
          <h4>New Customer Info Review</h4>
          {editCustomerFirstName !== chosenCustomer.firstName ? (
            <>
              <div>First Name: {editCustomerFirstName}</div>
            </>
          ) : (
            <div>First Name: no change</div>
          )}
          {editCustomerLastName !== chosenCustomer.lastName ? (
            <>
              <div>Last Name: {editCustomerLastName}</div>
            </>
          ) : (
            <div>Last Name: no change</div>
          )}
          {editCustomerPhoneNumber !== chosenCustomer.phoneNumber ? (
            <>
              <div>Phone Number</div>
              <div>{`(${editCustomerPhoneNumber.slice(
                0,
                3
              )}) ${editCustomerPhoneNumber.slice(
                3,
                6
              )}-${editCustomerPhoneNumber.slice(6)}`}</div>
            </>
          ) : (
            <div>Phone Number: no change</div>
          )}
        </div>

        {userData.length > 0 ? (
          <>
            <div className={styles.divider}></div>
            <div className={styles.pinInputContainer}>
              <div>
                <i class="fa-solid fa-key"></i> Admin Pin:
              </div>
              <div>
                {/* <input
                  onChange={(e) => setAdminPinInput(e.target.value)}
                  onKeyUp={(e) => preventEnterSubmit(e)}
                  className={styles.pinInput}
                  placeholder="****"
                /> */}
                <NumericFormat
                  className={styles.pinInput}
                  allowLeadingZeros
                  onKeyUp={(e) => preventEnterSubmit(e)}
                  type="password"
                  format="####"
                  mask="_"
                  placeholder="****"
                  allowEmptyFormatting
                  minlength="4"
                  maxlength="8"
                  autocomplete="new-password"
                  onChange={(e) => setAdminPinInput(e.target.value)}
                  value={adminPinInput}
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </form>
      {/* Reset and Clear ==============================================*/}
      <div className={styles.divider}></div>
      <div className={styles.formBtnContainer}>
        {isLoading ? (
          ""
        ) : (
          <div className={styles.formBtnReset} onClick={() => onCancel()}>
            Cancel
          </div>
        )}
        {isLoading ? (
          ""
        ) : (
          <div className={styles.formBtnReset} onClick={() => onReset()}>
            Clear
          </div>
        )}
        {isLoading ? (
          <div className={styles.formBtnSubmitLoading}>
            <div class="loader"></div>
          </div>
        ) : (
          <div className={styles.formBtnSubmit} onClick={() => onSubmitEdit()}>
            Submit
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default EditCustomerUI;
