import { useState } from "react";
import styles from "../../../styles/Dashboard.module.css";
import ProductBox from "../DashboardProductBox";
import useTransactionData from "../../../hooks/transactionHooks";
import useRechargeData from "../../../hooks/rechargeHooks";

const TodaysTransactions = ({
  // rechargeData,
  // transactionData,
  startTime,
  endTime,
  route,
}) => {
  const transactionData = useTransactionData(route);
  const rechargeData = useRechargeData(route);

  const [transactionType, setTransactionType] = useState("added");

  const totalCalculator = (Data, usageType) => {
    let amount = 0;
    const todaysTransactions = Data.filter(
      (c) => c.updatedAt > startTime && c.updatedAt < endTime
    );
    for (let i = 0; i < todaysTransactions.length; i++) {
      if (todaysTransactions[i][`${usageType}Amount`] > 0) {
        amount = amount + todaysTransactions[i][`${usageType}Amount`];
      }
    }
    return amount;
  };

  return (
    <>
      <div className={styles.flexEnd}>
        <div
          className={transactionType === "added" ? "badgePrimary" : "badgeGray"}
          onClick={() => setTransactionType("added")}
        >
          Added
        </div>
        <div
          className={
            transactionType === "subtracted" ? "badgePrimary" : "badgeGray"
          }
          onClick={() => setTransactionType("subtracted")}
        >
          Subtracted
        </div>
      </div>
      {transactionType === "added" ? (
        <div className="flex">
          <ProductBox
            type={"ice"}
            amount={totalCalculator(rechargeData, "ice")}
            desc={"added"}
          />
          <ProductBox
            type={"water"}
            amount={totalCalculator(rechargeData, "water")}
            desc={"added"}
          />
          <ProductBox
            type={"alkaline"}
            amount={totalCalculator(rechargeData, "alkaline")}
            desc={"added"}
          />
        </div>
      ) : (
        <div className="flex">
          <ProductBox
            type={"ice"}
            amount={totalCalculator(transactionData, "ice")}
            desc={"subtracted"}
          />
          <ProductBox
            type={"water"}
            amount={totalCalculator(transactionData, "water")}
            desc={"subtracted"}
          />
          <ProductBox
            type={"alkaline"}
            amount={totalCalculator(transactionData, "alkaline")}
            desc={"subtracted"}
          />
        </div>
      )}
    </>
  );
};

export default TodaysTransactions;
