import React, { useState } from "react";
import { createPricing } from "../../graphql/mutations";
import { ToastContainer, toast } from "react-toastify";
import { API } from "aws-amplify";
import styles from "../../styles/Forms.module.css";
import preventEnterSubmit from "./util/preventEnterSubmit";
import { NumericFormat } from "react-number-format";

const PricingForm = ({
  icePricing,
  waterPricing,
  alkalinePricing,
  userData,
}) => {
  const [adminPinInput, setAdminPinInput] = useState("");
  const [productType, setProductType] = useState("");
  const [amount, setAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [formExpanded, setFormExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [allUsers, setAllUsers] = useState([]);
  fetch(userData).then(() => setAllUsers(userData));
  const adminNum = allUsers.map((e) => e.name).indexOf("Admin");
  const adminPin = adminNum > -1 ? allUsers[adminNum].pin : "notFound";

  const formExpendToggle = () => {
    setAdminPinInput("");
    setProductType("");
    setAmount(0);
    setPrice(0);
    formExpanded === true ? setFormExpanded(false) : setFormExpanded(true);
  };

  const typeName = () => {
    if (productType === "") {
      return "";
    } else if (productType === "ice") {
      return "(lb)";
    } else if (productType === "water" || productType === "alkaline") {
      return "(gal)";
    }
  };

  const numOfPlan = () => {
    if (productType === "ice") {
      return icePricing.length;
    } else if (productType === "water") {
      return waterPricing.length;
    } else if (productType === "alkaline") {
      return alkalinePricing.length;
    }
  };

  let planAmount = numOfPlan();

  const onClear = () => {
    document.getElementById("pricing-form").reset();
    setAdminPinInput("");
    setProductType("");
    setAmount(0);
    setPrice(0);
  };

  const onCancel = () => {
    onClear();
    setFormExpanded(false);
  };

  const onSubmit = async () => {
    if (
      allUsers.length > 0 &&
      adminPinInput === adminPin &&
      productType !== "" &&
      amount !== 0 &&
      price !== 0 &&
      planAmount >= 10
    ) {
      toast.error("limit of plans per type is 10");
    } else if (
      allUsers.length > 0 &&
      adminPinInput !== adminPin &&
      productType !== "" &&
      amount !== 0 &&
      price !== 0 &&
      planAmount <= 10
    ) {
      toast.error("Wrong Admin Pin");
    } else if (
      //for multi user, only admin pin can create new.
      allUsers.length > 0 &&
      adminPinInput === adminPin &&
      productType !== "" &&
      amount !== 0 &&
      price !== 0 &&
      planAmount <= 10 &&
      isLoading === false
    ) {
      try {
        setIsLoading(true);
        await API.graphql({
          query: createPricing,
          variables: {
            input: {
              type: productType,
              amount: amount,
              price: price,
            },
          },
        });
        onClear();
        setFormExpanded(false);
        setIsLoading(false);
        toast.success("New Card Created!");
      } catch (error) {
        alert("error occured", error);
      }
    } else if (
      //for single user
      allUsers.length === 0 &&
      productType !== "" &&
      amount !== 0 &&
      price !== 0 &&
      planAmount <= 10 &&
      isLoading === false
    ) {
      try {
        setIsLoading(true);
        await API.graphql({
          query: createPricing,
          variables: {
            input: {
              type: productType,
              amount: amount,
              price: price,
            },
          },
        });
        onClear();
        setFormExpanded(false);
        setIsLoading(false);
        toast.success("New Card Created!");
      } catch (error) {
        alert("error occured", error);
      }
    } else {
      toast.error("Please Select Type, Amount and Price");
    }
  };
  return (
    <div class="contentContainer">
      <div className={styles.expand} onClick={() => formExpendToggle()}>
        <div>
          <h3>Create New Prepaid Card</h3>
          <div>Limit, 10 pricing plans per type</div>
        </div>
        <div className="cursorPointer">
          <div className="iconPrimary">
            {formExpanded === true ? (
              <i class="fa-solid fa-angle-down"></i>
            ) : (
              <i class="fa-solid fa-angle-right"></i>
            )}
          </div>
        </div>
      </div>
      {formExpanded === true ? (
        <>
          <form id="pricing-form">
            <div className={styles.divider}></div>
            {/* Type Select ===================================*/}
            <div className={styles.InputContainer}>
              <div className={styles.labelContainer}>
                <div className="iconPrimary">
                  <i class="fa-solid fa-box"></i>
                </div>
                <div className={styles.iconDesc}>Product</div>
              </div>
              <div className={styles.planSelect}>
                <select onChange={(e) => setProductType(e.target.value)}>
                  <option value="" selected>
                    Select Type
                  </option>
                  {/* TODO: Planning to get rid of the ice option here for remake of the ice pricing in bags and block */}
                  {/* <option value="ice">Ice (lb)</option> */}
                  <option value="water">Water (gal)</option>
                  <option value="alkaline">Alkaline (gal)</option>
                </select>
              </div>
            </div>
            {/* Amount input ===================================*/}
            <div className={styles.InputContainer}>
              <div className={styles.labelContainer}>
                <div className="iconPrimary">
                  <i class="fa-solid fa-boxes-stacked"></i>
                </div>
                <div className={styles.iconDesc}>Amount</div>
              </div>
              <div className={styles.numberInput}>
                <div class="hiddenForMobile">{typeName()}</div>
                <div
                  className="iconPlusMinus"
                  onClick={() => setAmount(amount > 0 ? amount - 1 : 0)}
                >
                  <i class="fa-solid fa-minus"></i>
                </div>
                <input
                  type="number"
                  min="0"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  title="Non-negative integral number"
                  onKeyUp={(e) => preventEnterSubmit(e)}
                  value={amount === 0 ? "" : amount}
                  placeholder="0"
                  onChange={(e) => setAmount(Number(e.target.value))}
                />
                <div
                  className="iconPlusMinus"
                  onClick={() => setAmount(amount + 1)}
                >
                  <i class="fa-solid fa-plus"></i>
                </div>
              </div>
            </div>
            {/* Price input ===================================*/}
            <div className={styles.InputContainer}>
              <div className={styles.labelContainer}>
                <div className="iconPrimary">
                  <i class="fa-solid fa-dollar-sign"></i>
                </div>
                <div className={styles.iconDesc}>Price</div>
              </div>
              <div className={styles.numberInput}>
                <div>$</div>
                <div
                  className="iconPlusMinus"
                  onClick={() => setPrice(price > 0 ? price - 1 : 0)}
                >
                  <i class="fa-solid fa-minus"></i>
                </div>
                {/* <input
                  onKeyUp={(e) => preventEnterSubmit(e)}
                  min="0"
                  value={price}
                  onChange={(e) => setPrice(Number(e.target.value))}
                /> */}
                <input
                  type="number"
                  min="0"
                  inputmode="numeric"
                  pattern="^\d*(\.\d{0,2})?$"
                  title="Non-negative integral number"
                  onKeyUp={(e) => preventEnterSubmit(e)}
                  value={price === 0 ? "" : price}
                  placeholder="0"
                  onChange={(e) => setPrice(Number(e.target.value))}
                />
                <div
                  className="iconPlusMinus"
                  onClick={() => setPrice(price + 1)}
                >
                  <i class="fa-solid fa-plus"></i>
                </div>
              </div>
            </div>
            <div className={styles.divider}></div>
            {userData.length > 0 ? (
              <div className={styles.pinInputContainer}>
                <div>
                  <i class="fa-solid fa-key"></i> Pin:
                </div>
                <div>
                  {/* <input
                    onChange={(e) => setAdminPinInput(e.target.value)}
                    onKeyUp={(e) => preventEnterSubmit(e)}
                    className={styles.pinInput}
                    placeholder="****"
                  /> */}
                  <NumericFormat
                    className={styles.pinInput}
                    allowLeadingZeros
                    onKeyUp={(e) => preventEnterSubmit(e)}
                    type="password"
                    format="####"
                    mask="_"
                    placeholder="****"
                    allowEmptyFormatting
                    minlength="4"
                    maxlength="8"
                    autocomplete="new-password"
                    onChange={(e) => setAdminPinInput(e.target.value)}
                    value={adminPinInput}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </form>
          {/* Reset and Clear ==============================================*/}
          <div className={styles.formBtnContainer}>
            {isLoading ? (
              ""
            ) : (
              <div className={styles.formBtnReset} onClick={() => onCancel()}>
                Cancel
              </div>
            )}
            {isLoading ? (
              ""
            ) : (
              <div className={styles.formBtnReset} onClick={() => onClear()}>
                Clear
              </div>
            )}
            {isLoading ? (
              <div className={styles.formBtnSubmitLoading}>
                <div class="loader"></div>
              </div>
            ) : (
              <div className={styles.formBtnSubmit} onClick={() => onSubmit()}>
                Submit
              </div>
            )}
          </div>
        </>
      ) : (
        ""
      )}
      <ToastContainer />
    </div>
  );
};

export default PricingForm;
