import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listRecharges } from '../graphql/queries';
import { onCreateRecharge, onUpdateRecharge, onDeleteRecharge } from '../graphql/subscriptions';

const useRechargeData = (route) => {
  const [rechargeData, setRechargeData] = useState([]);

  useEffect(() => {
    const subscriptionFilter = { filter: {} };

    const fetchRecharges = async () => {
      try {
        const result = await API.graphql(
          graphqlOperation(listRecharges, { limit: 2000 })
        );
        setRechargeData(result.data.listRecharges.items);
      } catch (error) {
        console.error('Error fetching recharges:', error);
      }
    };

    fetchRecharges();

    const createRechargeSub = API.graphql(
      graphqlOperation(onCreateRecharge, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setRechargeData((prevRechargeData) => [
          ...prevRechargeData,
          value.data.onCreateRecharge,
        ]);
      },
    });

    const updateRechargeSub = API.graphql(
      graphqlOperation(onUpdateRecharge, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setRechargeData((prevRechargeData) => {
          const toUpdateIndex = prevRechargeData.findIndex(
            (item) => item.id === value.data.onUpdateRecharge.id
          );
          if (toUpdateIndex === -1) {
            return [...prevRechargeData, value.data.onUpdateRecharge];
          }
          return [
            ...prevRechargeData.slice(0, toUpdateIndex),
            value.data.onUpdateRecharge,
            ...prevRechargeData.slice(toUpdateIndex + 1),
          ];
        });
      },
    });

    const deleteRechargeSub = API.graphql(
      graphqlOperation(onDeleteRecharge, subscriptionFilter)
    ).subscribe({
      next: ({ value }) => {
        setRechargeData((prevRechargeData) => {
          const toDeleteIndex = prevRechargeData.findIndex(
            (item) => item.id === value.data.onDeleteRecharge.id
          );
          return [
            ...prevRechargeData.slice(0, toDeleteIndex),
            ...prevRechargeData.slice(toDeleteIndex + 1),
          ];
        });
      },
    });

    return () => {
      createRechargeSub.unsubscribe();
      updateRechargeSub.unsubscribe();
      deleteRechargeSub.unsubscribe();
    };
  }, [route]);

  return rechargeData;
};

export default useRechargeData;