import React, { useState } from "react";
import styles from "../styles/Forms.module.css";
import preventEnterSubmit from "./common/util/preventEnterSubmit";
import { ToastContainer, toast } from "react-toastify";
import { API, graphqlOperation } from "aws-amplify";
import { createStore } from "../graphql/mutations";
// import Stripe from "stripe";
import { useNavigate } from "react-router-dom";
import withAuth from "../withAuth";

const Setup = ({
  accEmail,
  cognitoID,
  // stripeKey,
  // stripeCustomer,
  // stripeProducts,
  // stripePrices,
  // fetchStripeCustomer,
}) => {
  const navigate = useNavigate();
  // const stripe = new Stripe(stripeKey);

  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [storeName, setStoreName] = useState("");
  const [storePhone, setStorePhone] = useState("");

  const [storeAddyLine1, setStoreAddyLine1] = useState("");
  const [storeAddyLine2, setStoreAddyLine2] = useState("");
  const [storeAddyCity, setStoreAddyCity] = useState("");
  const [storeAddyState, setStoreAddyState] = useState("");
  const [storeAddyZipCode, setStoreAddyZipCode] = useState("");

  console.log(storeAddyState);

  const [firstDash, setFirstDash] = useState(false);
  const [secondDash, setSecondDash] = useState(false);

  const addDashforPhoneNumber = () => {
    if (storePhone.length === 3 && firstDash === false) {
      setStorePhone(storePhone + "-");
      setFirstDash(true);
    } else if (storePhone.length === 7 && secondDash === false) {
      setStorePhone(storePhone + "-");
      setSecondDash(true);
    }
    if (storePhone.length === 2 && firstDash === true) {
      setFirstDash(false);
    } else if (storePhone.length === 6 && secondDash === true) {
      setSecondDash(false);
    }
    return storePhone;
  };

  const onClear = () => {
    document.getElementById("setup-form").reset();
    setFirstName("");
    setlastName("");
    setStoreName("");
    setStorePhone("");
    setStoreAddyLine1("");
    setStoreAddyLine2("");
    setStoreAddyCity("");
    setStoreAddyState("");
    setStoreAddyZipCode("");
  };

  const onSubmitSetup = async () => {
    if (
      firstName === "" ||
      lastName === "" ||
      storeName === "" ||
      storePhone === "" ||
      storeAddyLine1 === "" ||
      storeAddyCity === "" ||
      storeAddyState === "" ||
      storeAddyZipCode === ""
    ) {
      toast.error("fill in all input");
    } else if (
      firstName !== "" &&
      lastName !== "" &&
      storeName !== "" &&
      storePhone !== "" &&
      storeAddyLine1 !== "" &&
      storeAddyCity !== "" &&
      storeAddyState !== "" &&
      storeAddyZipCode !== ""
    ) {
      try {
        // stripe.customers.create({
        //   name: firstName + " " + lastName,
        //   phone: storePhone,
        //   email: accEmail,
        //   address: {
        //     line1: storeAddyLine1,
        //     line2: storeAddyLine2,
        //     city: storeAddyCity,
        //     state: storeAddyState,
        //     postal_code: storeAddyZipCode,
        //     country: "US",
        //   },
        //   metadata: {
        //     storeName: storeName,
        //     cognitoID: cognitoID,
        //   },
        // });
        API.graphql(
          graphqlOperation(createStore, {
            input: {
              ownerFirstName: firstName,
              ownerLastName: lastName,
              storeName: storeName,
              storeEmail: accEmail,
              storePhoneNumber: storePhone,
              storeAddyLine1: storeAddyLine1,
              storeAddyLine2: storeAddyLine2,
              storeAddyCity: storeAddyCity,
              storeAddyState: storeAddyState,
              storeAddyZipCode: storeAddyZipCode,
            },
          })
        );
        onClear();
        alert("Your account is now activated!");
        navigate("/dashboard");
      } catch (error) {
        console.error("error occured", error);
      }
    }
  };

  return (
    <>
      <div class="contentWithSideBar">
        <div class="pageTitle">
          <h1>Welcome to Hydrocard!</h1>
        </div>
        <div class="contentContainer">
          <div>We are thrilled to be partnered with your business!</div>
          <div>In order to get started, please fill in the following.</div>
          <form id="setup-form">
            <div>
              <div className={styles.textInputContainer}>
                <div>Account Email used for this Account</div>
                <div className={styles.accountEmail}>{accEmail}</div>
                {/* <div>{cognitoID}</div> */}
              </div>
              <div className={styles.textInputContainer}>
                <div>Owner First Name * </div>
                <div>
                  <input
                    onKeyUp={(e) => preventEnterSubmit(e)}
                    placeholder="John"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.textInputContainer}>
                <div>Owner Last Name *</div>
                <div>
                  <input
                    onKeyUp={(e) => preventEnterSubmit(e)}
                    placeholder="Doe"
                    onChange={(e) => setlastName(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.textInputContainer}>
                <div>Business Name *</div>
                <div>
                  <input
                    onKeyUp={(e) => preventEnterSubmit(e)}
                    placeholder="Water & Ice"
                    onChange={(e) => setStoreName(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.textInputContainer}>
                <div>Business Phone Number *</div>
                <div>
                  <input
                    onKeyUp={(e) => preventEnterSubmit(e)}
                    type="tel"
                    id="phone"
                    maxLength="12"
                    placeholder="123-456-7890"
                    onChange={(e) => setStorePhone(e.target.value)}
                    value={addDashforPhoneNumber()}
                  />
                </div>
              </div>
              <div className={styles.textInputContainer}>
                <div>Address Line 1 *</div>
                <div>
                  <input
                    onKeyUp={(e) => preventEnterSubmit(e)}
                    placeholder="123 Main Street"
                    onChange={(e) => setStoreAddyLine1(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.textInputContainer}>
                <div>Address Line 2</div>
                <div>
                  <input
                    onKeyUp={(e) => preventEnterSubmit(e)}
                    placeholder="apt, suite, etc"
                    onChange={(e) => setStoreAddyLine2(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.textInputContainer}>
                <div>City *</div>
                <div>
                  <input
                    onKeyUp={(e) => preventEnterSubmit(e)}
                    placeholder="AnyCity"
                    onChange={(e) => setStoreAddyCity(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.textInputContainer}>
                <div>State *</div>
                <div>
                  {/* <input
                    onKeyUp={(e) => preventEnterSubmit(e)}
                    placeholder="AZ"
                    maxLength="2"
                    type="text"
                    onChange={(e) => setStoreAddyState(e.target.value)}
                  /> */}
                  <select
                    onKeyUp={(e) => preventEnterSubmit(e)}
                    onChange={(e) => setStoreAddyState(e.target.value)}
                  >
                    <option value="AL">AL</option>
                    <option value="AK">AK</option>
                    <option value="AR">AR</option>
                    <option value="AZ">AZ</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DC">DC</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">HI</option>
                    <option value="IA">IA</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="MA">MA</option>
                    <option value="MD">MD</option>
                    <option value="ME">ME</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MO">MO</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="NC">NC</option>
                    <option value="NE">NE</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NV">NV</option>
                    <option value="NY">NY</option>
                    <option value="ND">ND</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VT">VT</option>
                    <option value="VA">VA</option>
                    <option value="WA">WA</option>
                    <option value="WI">WI</option>
                    <option value="WV">WV</option>
                    <option value="WY">WY</option>
                  </select>
                </div>
              </div>
              <div className={styles.textInputContainer}>
                <div>Zip Code *</div>
                <div>
                  <input
                    onKeyUp={(e) => preventEnterSubmit(e)}
                    placeholder="12345"
                    onChange={(e) => setStoreAddyZipCode(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </form>
          <div className={styles.divider}></div>
          <div className={styles.formBtnContainer}>
            <div className={styles.formBtnReset} onClick={() => onClear()}>
              Reset
            </div>
            <button
              className={styles.formBtnSubmit}
              onClick={() => onSubmitSetup()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default withAuth(Setup);
