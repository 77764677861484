/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      ice
      water
      alkaline
      iceBagType1
      iceBagType2
      iceBagType3
      iceBlockType1
      iceBlockType2
      iceBlockType3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      ice
      water
      alkaline
      iceBagType1
      iceBagType2
      iceBagType3
      iceBlockType1
      iceBlockType2
      iceBlockType3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      ice
      water
      alkaline
      iceBagType1
      iceBagType2
      iceBagType3
      iceBlockType1
      iceBlockType2
      iceBlockType3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCustomerTest = /* GraphQL */ `
  mutation CreateCustomerTest(
    $input: CreateCustomerTestInput!
    $condition: ModelCustomerTestConditionInput
  ) {
    createCustomerTest(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      water
      alkaline
      iceBagType1
      iceBagType2
      iceBagType3
      iceBlockType1
      iceBlockType2
      iceBlockType3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCustomerTest = /* GraphQL */ `
  mutation UpdateCustomerTest(
    $input: UpdateCustomerTestInput!
    $condition: ModelCustomerTestConditionInput
  ) {
    updateCustomerTest(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      water
      alkaline
      iceBagType1
      iceBagType2
      iceBagType3
      iceBlockType1
      iceBlockType2
      iceBlockType3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCustomerTest = /* GraphQL */ `
  mutation DeleteCustomerTest(
    $input: DeleteCustomerTestInput!
    $condition: ModelCustomerTestConditionInput
  ) {
    deleteCustomerTest(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fullName
      phoneNumber
      water
      alkaline
      iceBagType1
      iceBagType2
      iceBagType3
      iceBlockType1
      iceBlockType2
      iceBlockType3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPricing = /* GraphQL */ `
  mutation CreatePricing(
    $input: CreatePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    createPricing(input: $input, condition: $condition) {
      id
      type
      amount
      price
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePricing = /* GraphQL */ `
  mutation UpdatePricing(
    $input: UpdatePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    updatePricing(input: $input, condition: $condition) {
      id
      type
      amount
      price
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePricing = /* GraphQL */ `
  mutation DeletePricing(
    $input: DeletePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    deletePricing(input: $input, condition: $condition) {
      id
      type
      amount
      price
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createIceProduct = /* GraphQL */ `
  mutation CreateIceProduct(
    $input: CreateIceProductInput!
    $condition: ModelIceProductConditionInput
  ) {
    createIceProduct(input: $input, condition: $condition) {
      id
      iceBagSize1
      iceBagSize2
      iceBagSize3
      iceBlockSize1
      iceBlockSize2
      iceBlockSize3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateIceProduct = /* GraphQL */ `
  mutation UpdateIceProduct(
    $input: UpdateIceProductInput!
    $condition: ModelIceProductConditionInput
  ) {
    updateIceProduct(input: $input, condition: $condition) {
      id
      iceBagSize1
      iceBagSize2
      iceBagSize3
      iceBlockSize1
      iceBlockSize2
      iceBlockSize3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteIceProduct = /* GraphQL */ `
  mutation DeleteIceProduct(
    $input: DeleteIceProductInput!
    $condition: ModelIceProductConditionInput
  ) {
    deleteIceProduct(input: $input, condition: $condition) {
      id
      iceBagSize1
      iceBagSize2
      iceBagSize3
      iceBlockSize1
      iceBlockSize2
      iceBlockSize3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createIcePricing = /* GraphQL */ `
  mutation CreateIcePricing(
    $input: CreateIcePricingInput!
    $condition: ModelIcePricingConditionInput
  ) {
    createIcePricing(input: $input, condition: $condition) {
      id
      type
      weight
      amount
      price
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateIcePricing = /* GraphQL */ `
  mutation UpdateIcePricing(
    $input: UpdateIcePricingInput!
    $condition: ModelIcePricingConditionInput
  ) {
    updateIcePricing(input: $input, condition: $condition) {
      id
      type
      weight
      amount
      price
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteIcePricing = /* GraphQL */ `
  mutation DeleteIcePricing(
    $input: DeleteIcePricingInput!
    $condition: ModelIcePricingConditionInput
  ) {
    deleteIcePricing(input: $input, condition: $condition) {
      id
      type
      weight
      amount
      price
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRecharge = /* GraphQL */ `
  mutation CreateRecharge(
    $input: CreateRechargeInput!
    $condition: ModelRechargeConditionInput
  ) {
    createRecharge(input: $input, condition: $condition) {
      id
      customerID
      customerFirstName
      customerLastName
      customerPhoneNumber
      iceAmount
      waterAmount
      alkalineAmount
      cashier
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRecharge = /* GraphQL */ `
  mutation UpdateRecharge(
    $input: UpdateRechargeInput!
    $condition: ModelRechargeConditionInput
  ) {
    updateRecharge(input: $input, condition: $condition) {
      id
      customerID
      customerFirstName
      customerLastName
      customerPhoneNumber
      iceAmount
      waterAmount
      alkalineAmount
      cashier
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRecharge = /* GraphQL */ `
  mutation DeleteRecharge(
    $input: DeleteRechargeInput!
    $condition: ModelRechargeConditionInput
  ) {
    deleteRecharge(input: $input, condition: $condition) {
      id
      customerID
      customerFirstName
      customerLastName
      customerPhoneNumber
      iceAmount
      waterAmount
      alkalineAmount
      cashier
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      customerID
      customerFirstName
      customerLastName
      customerPhoneNumber
      iceAmount
      waterAmount
      alkalineAmount
      cashier
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      customerID
      customerFirstName
      customerLastName
      customerPhoneNumber
      iceAmount
      waterAmount
      alkalineAmount
      cashier
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      customerID
      customerFirstName
      customerLastName
      customerPhoneNumber
      iceAmount
      waterAmount
      alkalineAmount
      cashier
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createStore = /* GraphQL */ `
  mutation CreateStore(
    $input: CreateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    createStore(input: $input, condition: $condition) {
      id
      ownerFirstName
      ownerLastName
      storeName
      storePhoneNumber
      storeEmail
      storeAddyLine1
      storeAddyLine2
      storeAddyCity
      storeAddyState
      storeAddyZipCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateStore = /* GraphQL */ `
  mutation UpdateStore(
    $input: UpdateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    updateStore(input: $input, condition: $condition) {
      id
      ownerFirstName
      ownerLastName
      storeName
      storePhoneNumber
      storeEmail
      storeAddyLine1
      storeAddyLine2
      storeAddyCity
      storeAddyState
      storeAddyZipCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteStore = /* GraphQL */ `
  mutation DeleteStore(
    $input: DeleteStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    deleteStore(input: $input, condition: $condition) {
      id
      ownerFirstName
      ownerLastName
      storeName
      storePhoneNumber
      storeEmail
      storeAddyLine1
      storeAddyLine2
      storeAddyCity
      storeAddyState
      storeAddyZipCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      pin
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      pin
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      pin
      createdAt
      updatedAt
      owner
    }
  }
`;
