import feature10 from "../../images/feature/feature10.png";
import styles from "../../../styles/Feature.module.css";

const FeatureMulti = () => {
  return (
    <div className={styles.bg}>
      <div className={styles.flexContainer}>
        <div>
          <div className={styles.title}>
            <h2 class="featureCardIcon">
              <i class="fa-solid fa-user-group"></i>
            </h2>
            <h2>Multi User Mode</h2>
          </div>
          <div className={styles.textContainerBig}>
            Our multi-user mode allows businesses with multiple employees to
            easily manage their Hydrocard account. The owner of the account can
            activate this mode by setting a 4-digit admin PIN number. Once
            activated, employees can set up their own PIN numbers and names for
            secure access. Admin users have full access to all functionalities
            of the application, including creating or editing product plans,
            deleting customers, and changing account settings. On the other
            hand, employee users have limited access to some functionalities,
            such as new/existing customer registration, recharging and paying
            customer balances, and editing customer information. Admin users can
            also delete employee users with their PIN number. This feature
            allows for easy and secure management of the Hydrocard account for
            businesses with multiple employees.
          </div>
        </div>
        <div className={styles.sectionMainImg}>
          <img src={feature10} alt="" />
        </div>
      </div>
    </div>
  );
};

export default FeatureMulti;
