import styles from "../../../styles/Home.module.css";

const FeatureCard = ({ icon, title, desc }) => {
  return (
    <div className={styles.featureCardContainer}>
      <div class="featureCardIcon">
        <i class={icon}></i>
      </div>
      <div className={styles.featureCardTitle}>{title}</div>
      <div>{desc}</div>
    </div>
  );
};

export default FeatureCard;
