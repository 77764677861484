import styles from "../../../styles/Home.module.css";
import { useNavigate } from "react-router-dom";

const HomeFooter = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerContact}>
        <div className={styles.footerTextContainer}>
          <div className={styles.footerTitle}>Contact</div>
          <div>hjldevelopment@gmail.com</div>
        </div>
        <div className={styles.footerTextContainer}>
          <div className={styles.footerTitle}>Additional</div>
          <div>Privacy Policy</div>
          <div>Terms and Conditions</div>
        </div>
      </div>
      <div>
        <div className={styles.footerSignUpTitle}>Join and Grow with us!</div>
        <div className={styles.buttonSignUp} onClick={() => navigate("/login")}>
          Sign Up
        </div>
      </div>
    </div>
  );
};

export default HomeFooter;
