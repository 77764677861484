import React from "react";
import styles from "../../styles/PageSizeSelector.module.css";

const PageSizeSelector = ({ pageSize, setPageSize, setCurrentPage }) => {
  const pageSizeSelectBtnStyles = (sizeNumber) => {
    return pageSize === sizeNumber
      ? styles.pageSizeSelectBtnActive
      : styles.pageSizeSelectBtn;
  };

  const onPageSizeClick = (num) => {
    setPageSize(num);
    setCurrentPage(1);
  };

  return (
    <div className={styles.container}>
      <div
        className={pageSizeSelectBtnStyles(5)}
        onClick={() => onPageSizeClick(5)}
      >
        5
      </div>
      <div
        className={pageSizeSelectBtnStyles(10)}
        onClick={() => onPageSizeClick(10)}
      >
        10
      </div>
      <div
        className={pageSizeSelectBtnStyles(20)}
        onClick={() => onPageSizeClick(20)}
      >
        20
      </div>
    </div>
  );
};

export default PageSizeSelector;
