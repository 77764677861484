import React from 'react';
import { useAuthenticator } from "@aws-amplify/ui-react";
import styles from '../styles/Home.module.css'
import HomeHero from './common/hompage/HomeHero';
import HomeAbout from './common/hompage/HomeAbout';
import HomeFeature from './common/hompage/HomeFeature';
import HomePricing from './common/hompage/HomePricing';
import HomeFooter from './common/hompage/HomeFooter';
import Animation from './common/Animation';
import ReactDOM from 'react-dom';
import {QRCodeCanvas} from 'qrcode.react';
import { renderToString } from 'react-dom/server';

export const Home = ({expender}) => {
  const { route } = useAuthenticator((context) => [context.route]);
  const hasSidebar = () => {
    if(route === "authenticated" && expender === true){
      return "contentWithSideBarThin"
    } else if (route === "authenticated" && expender === false){
      return "contentWithSideBar"
    } else {
      return ""
    }
  }

  return (
    <div class={hasSidebar()}>
      <Animation/>
      <div className={styles.hero}>
        <HomeHero/>
        <HomeAbout/>
      </div>
      <div className={styles.feature}>
      <HomeFeature/>
      </div>
      {/* <HomePricing/> */}
      <HomeFooter/>
    </div>
  );
}