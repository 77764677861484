import feature9 from "../../images/feature/feature9.png";
import styles from "../../../styles/Feature.module.css";

const FeatureEffortless = () => {
  return (
    <div className={styles.whiteBg}>
      <div className={styles.flexContainerFlipped}>
        <div className={styles.sectionMainImg}>
          <img src={feature9} alt="" />
        </div>
        <div>
          <div className={styles.title}>
            <h2 class="featureCardIcon">
              <i class="fa-solid fa-user-group"></i>
            </h2>
            <h2>Effortless Transaction Review</h2>
          </div>
          <div className={styles.textContainerBig}>
            One of the benefits of using our service is the ability to
            effortlessly review past transactions made by users, including
            recharge and balance pay history. You can easily filter these
            history tables by name or phone number to isolate the history for a
            particular customer. With multi-user mode activated, you will also
            be able to see which employee user conducted the transaction.
            Keeping track of this data for review can help you quickly navigate
            back to any point of sale in case of any discrepancies. This data is
            also used to generate a dashboard that provides a quick overview of
            your business. We understand that some users prefer to keep their
            data in a physical format as a backup, so you can download your
            business data as an Excel spreadsheet at any time under the Account
            tab. The downloadable data includes customer information, recharge
            history, and transaction history.
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureEffortless;
