import React, {useState} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import logo from '../asset/logo.svg'
import styles from '../styles/Navbar.module.css'
import Stripe from "stripe";

export const Navbar = ({storeData, stripeCustomer, stripePrices, stripeKey, expender, setExpender }) => {
  const stripe = new Stripe(stripeKey);
  // const [expender, setExpender] = useState(false);
  const [burger, setBurger] = useState(false);
  const [currentPage, setCurrentPage] = useState(window.location.pathname);
  const burgerToggle = () => {
    burger === true ? setBurger(false) : setBurger(true);
  }

  const expenderStyle = () => {
    return expender === false ? styles.sideBar : styles.sideBarThin
  }
  const sideBarNavBtnStyle = () => {
    return expender === false ? styles.sideBarNavBtn : styles.sideBarNavBtnThin
  }
  const expandArrowContainerStyle = () => {
    return expender === false ? styles.expandArrowContainerThin : styles.expandArrowContainer
  }

  const subscribeCustomer = () => {
    stripe.subscriptions.create({
      customer: stripeCustomer.id,
      items: [{ price: stripePrices[1].id }],
      trial_period_days: 30,
    });
  }

  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate('/login');
  }

  const logOutBurger = () => {
    signOut();
    navigate('/login');
    burgerToggle();
  }

  const navigateBurger = (dest) => {
    navigate(dest);
    burgerToggle();
    document.getElementById("burgerIcon").checked = false;
  }

  const navigateSideBar = (dest) => {
    navigate(dest);
    setCurrentPage(dest);
  }

  const currentPageStyle = (page) => {
    if(currentPage === page && expender === false){
      return styles.activePage;
    } else if (currentPage === page && expender === true){
      return styles.activePageThin;
    } else {
      return styles.notActivePage;
    }
  }

  const iconColor = (page) => {
    if(currentPage === page){
      return styles.iconActive;
    } else {
      return styles.iconNotActive;
    }
  }

  return (
    <>
      {route === 'authenticated' ? (
      // NAVBAR FOR AUTHENTICATED USERS ============================================
      <>
        <div className={expandArrowContainerStyle()} onClick={() => expender ? setExpender(false) : setExpender(true)}>
          <div className={styles.expendArrow}>
            {expender ? <i class="fa-solid fa-angle-right"></i> : <i class="fa-solid fa-angle-left"></i>}
          </div>
        </div>
        {/* Profile Desktop */}
        <div className={expenderStyle()}>
          <div>
              {/* {expender ? <div className={styles.logoContainerSideBar}></div> :  <div className={styles.logoContainerSideBar} onClick={() => navigateSideBar('/home')}>
                <img src={logo} alt="logo" className='logo'/>
                <div className={styles.logoText}>HydroCard</div>
              </div>} */}
              <div className={styles.logoContainerSideBar} onClick={() => navigateSideBar('/home')}>
                <img src={logo} alt="logo" className='logo'/>
                {expender ? "" : <div className={styles.logoText}>HydroCard</div>}
              </div>
          {storeData.length > 0 ? (<>
            <div className={currentPageStyle('/dashboard')}>
              <div className={sideBarNavBtnStyle()} onClick={() => navigateSideBar('/dashboard')}>
                <div className={iconColor('/dashboard')}>
                  <i className="fa-solid fa-chart-column"></i>
                </div>
                
                {expender ? "" : <div className={styles.sideBarNavBtnTitle}>Dashboard</div>}
              </div>
            </div>
            <div className={currentPageStyle('/customer')}>
              <div className={sideBarNavBtnStyle()} onClick={() => navigateSideBar('/customer')}>
                <div className={iconColor('/customer')}>
                  <i className="fa-solid fa-users"></i>
                </div>
                {expender ? "" : <div className={styles.sideBarNavBtnTitle}>Customer List</div>}
              </div>
            </div>
            <div className={currentPageStyle('/register')}>
              <div className={sideBarNavBtnStyle()} onClick={() => navigateSideBar('/register')}>
                <div className={iconColor('/register')}>
                  <i className="fa-solid fa-address-card"></i>
                </div>
                {expender ? "" : 
                <div className={styles.sideBarNavBtnTitle}>Register Customer</div>
                
                }
              </div>
            </div>
            <div className={currentPageStyle('/rechargehistory')}>
              <div className={sideBarNavBtnStyle()} onClick={() => navigateSideBar('/rechargehistory')}>
                <div className={iconColor('/rechargehistory')}>
                  <i className="fa-solid fa-folder-plus"></i>
                </div>
                {expender ? "" : 
                <div className={styles.sideBarNavBtnTitle}>Addition History</div>
                
              }
              </div>
            </div>
            <div className={currentPageStyle('/transactionhistory')}>
              <div className={sideBarNavBtnStyle()} onClick={() => navigateSideBar('/transactionhistory')}>
                <div className={iconColor('/transactionhistory')}>
                  <i className="fa-solid fa-folder-minus"></i>
                </div>
                {expender ? "" : 
                <div className={styles.sideBarNavBtnTitle}>Subtraction History</div>
                
              }
              </div>
            </div>
            <div className={currentPageStyle('/productpricing')}>
              <div className={sideBarNavBtnStyle()} onClick={() => navigateSideBar('/productpricing')}>
                <div className={iconColor('/productpricing')}>
                  <i className="fa-solid fa-tags"></i>
                </div>
                {expender ? "" : 
                <div className={styles.sideBarNavBtnTitle}>Prepaid Card Prices</div>
                
              }
              </div>
            </div>
            <div className={currentPageStyle('/account')}>
              <div className={sideBarNavBtnStyle()} onClick={() => navigateSideBar('/account')}>
                <div className={iconColor('/account')}>
                <i className="fa-solid fa-gear"></i>
                </div>
                {expender ? "" : 
                <div className={styles.sideBarNavBtnTitle}>Account Setting</div>
                
              }
              </div>
            </div>
          </>) : (
          
            <div className={currentPageStyle('/setup')}>
              <div className={sideBarNavBtnStyle()} onClick={() => navigateSideBar('/setup')}>
                <div className={iconColor('/setup')}>
                <i className="fa-solid fa-shop"></i>
                </div>
                {expender ? "" : 
                
                <div className={styles.sideBarNavBtnTitle}>Setup</div>
              }
              </div>
            </div>
          )}
          </div>
          <div>
            <div className={styles.refreshContainer} onClick={() => window.location.reload()}>
                <div className={styles.logoutIcon}>
                <i class="fa-solid fa-rotate-right"></i>
                </div>
                {expender ? "" : 
                <div className={styles.sideBarNavBtnTitle}>Refresh</div>
              }
              </div>
            <div className={styles.logOutContainer} onClick={() => logOut()}>
                <div className={styles.logoutIcon}>
                <i className="fa-solid fa-right-from-bracket"></i>
                </div>
                {expender ? "" : 
                <div className={styles.sideBarNavBtnTitle}>Log Out</div>
              }
              </div>

          </div>
        </div>
        <div className={styles.profileNavbarContainer}>
          {/* Profile Mobile */}
          <div className={styles.mobileNavbar}>
            <div className={styles.logoContainer} onClick={() => navigate('/home')}>
              <img src={logo} alt="logo" className='logo'/>
              <div className={styles.logoText}>HydroCard</div>
            </div>
            <div>
              <input id='burgerIcon' type="checkbox" className={styles.toggler} onClick={()=> burgerToggle()}></input>
              <div className={styles.hamburger}>
                <div></div>
              </div>
            </div>
            {burger ? 
            <div className={styles.burger}>
              <div className={styles.burgerNavList}>
                <div className={styles.burgerBtn} onClick={() => navigateBurger('/dashboard')}>
                  <div>Dashboard</div>
                  <div className={styles.burgerBtnIcon}><i className="fa-solid fa-chart-column"></i></div>
                </div>
                <div className={styles.burgerBtn} onClick={() => navigateBurger('/customer')}>
                  <div>Customer</div>
                  <div className={styles.burgerBtnIcon}><i className="fa-solid fa-users"></i></div>
                </div>
                <div className={styles.burgerBtn} onClick={() => navigateBurger('/register')}>
                  <div>Register</div>
                  <div className={styles.burgerBtnIcon}><i className="fa-solid fa-address-card"></i></div>
                </div>
                <div className={styles.burgerBtn} onClick={() => navigateBurger('/rechargehistory')}>
                  <div>Recharge History</div>
                  <div className={styles.burgerBtnIcon}><i className="fa-solid fa-folder-plus"></i></div>
                </div>
                <div className={styles.burgerBtn} onClick={() => navigateBurger('/transactionhistory')}>
                  <div>Transaction History</div>
                  <div className={styles.burgerBtnIcon}><i className="fa-solid fa-folder-minus"></i></div>
                </div>
                <div className={styles.burgerBtn} onClick={() => navigateBurger('/productpricing')}>
                  <div>Product Pricing</div>
                  <div className={styles.burgerBtnIcon}><i className="fa-solid fa-tags"></i></div>
                </div>
                <div className={styles.burgerBtn} onClick={() => navigateBurger('/account')}>
                  <div>Account Setting</div>
                  <div className={styles.burgerBtnIcon}><i className="fa-solid fa-circle-user"></i></div>
                </div>
                <div className={styles.burgerBtn} onClick={() => navigateBurger('/setup')}>
                  <div>Setup</div>
                  <div className={styles.burgerBtnIcon}><i className="fa-solid fa-shop"></i></div>
                </div>
                {/* <div className={styles.burgerBtn} onClick={() => navigateBurger('/support')}>Support</div> */}
                <div className={styles.burgerBtn} onClick={() => logOutBurger()}>
                  <div>Logout</div>
                  <div className={styles.burgerBtnIcon}><i className="fa-solid fa-right-from-bracket"></i></div>
                </div>
              </div>
            </div>
            : ""}
          </div>
        </div>
      </>
      ) : (
        // NAVBAR FOR NOT AUTHENTICATED USERS ============================================
        <div className={styles.publicNavbarContainer}>
          {/* Public Desktop */}
          <div className={styles.logoContainer} onClick={() => navigate('/')}>
            <img src={logo} alt="logo" className='logo'/>
            <div className={styles.logoText}>HydroCard</div>
          </div>
          <div className={styles.publicNavbar}>
            <div className={styles.navList}>
              <div className={styles.navListBtn} onClick={() => navigate('/')}>Home</div>
              <div className={styles.navListBtn} onClick={() => navigate('/feature')}>Feature</div>
              {/* <div className={styles.navListBtn} onClick={() => navigate('/subscriptionpricing')}>Pricing</div>
              <div className={styles.navListBtn} onClick={() => navigate('/support')}>Support</div> */}
              <div className={styles.loginBtn} onClick={() => navigate('/login')}>Login</div>
            </div>
          </div>
          {/* Public Mobile */}
          <div className={styles.mobileNavbar}>
            <div>
              <input id='burgerIcon' type="checkbox" className={styles.toggler} onClick={()=> burgerToggle()}></input>
              <div className={styles.hamburger}>
                <div></div>
              </div>
            </div>
            <div className={styles.loginBtn} onClick={() => navigate('/login')}>Login</div>
            {burger ? 
            <div className={styles.burger}>
              <div className={styles.burgerNavList}>
                <div className={styles.burgerBtn} onClick={() => navigateBurger('/')}>Home</div>
                <div className={styles.burgerBtn} onClick={() => navigateBurger('/feature')}>Feature</div>
                {/* <div className={styles.burgerBtn} onClick={() => navigateBurger('/subscriptionpricing')}>Pricing</div>
                <div className={styles.burgerBtn} onClick={() => navigateBurger('/support')}>Support</div> */}
              </div>
            </div>
            : ""}
          </div>
        </div>
      )}
      <Outlet />
    </>
  );
};