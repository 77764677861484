import React from "react";
import styles from "../../styles/SearchBar.module.css";
import preventEnterSubmit from "./util/preventEnterSubmit";

const SearchBarNew = ({ state, setState, placeholder }) => {
  const handleClear = () => {
    setState("");
  };
  return (
    <div className={styles.container}>
      <div className={styles.searchBarContainer}>
        <div className="flex">
          <div className={styles.magnifyingGlassIcon}>&#xf002;</div>
          <input
            onKeyUp={(e) => preventEnterSubmit(e)}
            id="search-input"
            type="text"
            className={styles.searchInput}
            placeholder={placeholder}
            onChange={(e) => setState(e.target.value)}
            value={state}
          />
        </div>
        {state !== "" ? (
          <div className="flex">
            <div className={styles.clearBtn} onClick={() => handleClear()}>
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SearchBarNew;
