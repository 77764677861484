import styles from "../../../styles/Home.module.css";
import FeatureCard from "./FeatureCard";
import { useNavigate } from "react-router-dom";

const HomeFeature = () => {
  const navigate = useNavigate();

  const CardContent = [
    {
      icon: "fa-solid fa-chart-column",
      title: "Insights and Reports",
      desc: "Gain valuable insights into your sales data through an intuitive and visually appealing dashboard, allowing you to better understand your customers actions and optimize your business strategy.",
    },
    {
      icon: "fa-regular fa-face-smile-beam",
      title: "Elevated Customer Experiences  ",
      desc: "Provide an exceptional customer experience with the convenience of a quick checkout and text receipts, helping to build trust and promote loyalty.",
    },
    {
      icon: "fa-solid fa-list-check",
      title: "Simple Balance Management",
      desc: "Add or subtract from a customer's balance with just a few clicks, allowing for seamless customer account management.",
    },
    {
      icon: "fa-solid fa-database",
      title: "Effortless Transaction Review",
      desc: "Instantly pull customer transactions and details, ensuring accountability and accuracy, through a clean and organized digital interface accessible across multiple devices for added convenience.",
    },
    {
      icon: "fa-solid fa-user-group",
      title: "Multi User Mode",
      desc: "Easy integration of access control for store with employee users for security and transparency.",
    },
    {
      icon: "fa-solid fa-money-check-dollar",
      title: "Customizable Prepaid Plans",
      desc: "Tailor your prepaid plans to fit your store's unique needs and pricing, ensuring you never lose track of your current plans per product.",
    },
  ];

  return (
    <>
      <div className={styles.featureContainer}>
        <div className={styles.sectionHeader}>Explore our features</div>
        <div className={styles.featureCards}>
          <FeatureCard
            icon={CardContent[0].icon}
            title={CardContent[0].title}
            desc={CardContent[0].desc}
          />
          <FeatureCard
            icon={CardContent[1].icon}
            title={CardContent[1].title}
            desc={CardContent[1].desc}
          />
          <FeatureCard
            icon={CardContent[2].icon}
            title={CardContent[2].title}
            desc={CardContent[2].desc}
          />
          <FeatureCard
            icon={CardContent[3].icon}
            title={CardContent[3].title}
            desc={CardContent[3].desc}
          />
          <FeatureCard
            icon={CardContent[4].icon}
            title={CardContent[4].title}
            desc={CardContent[4].desc}
          />
          <FeatureCard
            icon={CardContent[5].icon}
            title={CardContent[5].title}
            desc={CardContent[5].desc}
          />
        </div>
        <div className={styles.sectionFooter}>
          <div
            className={styles.buttonHomePage}
            onClick={() => navigate("/feature")}
          >
            View Feature Details
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeFeature;
