import React, { useState } from "react";
import PriceCard from "./PriceCard";
import styles from "../../styles/Pricing.module.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PricingForm from "./PricingForm";

export const PricingExample = ({ pricingData, userData, expender }) => {
  const icePricing = pricingData.filter((price) => price.type === "ice");
  const waterPricing = pricingData.filter((price) => price.type === "water");
  const alkalinePricing = pricingData.filter(
    (price) => price.type === "alkaline"
  );

  return (
    <div class={expender ? "contentWithSideBarThin" : "contentWithSideBar"}>
      <div class="pageTitle">
        <h1>Prepaid Card Prices</h1>
      </div>
      <div class="pageTitle">
        <div class="titleWithIcon">
          <h2 class="iconInTitle">
            <i class="fa-solid fa-file-circle-plus"></i>
          </h2>
          <h2>Product Form</h2>
        </div>
      </div>
      <PricingForm
        icePricing={icePricing}
        waterPricing={waterPricing}
        alkalinePricing={alkalinePricing}
        userData={userData}
      />
      <div class="pageTitle">
        {icePricing.length > 0 ||
        waterPricing.length > 0 ||
        alkalinePricing.length > 0 ? (
          <div class="titleWithIcon">
            <h2 class="iconInTitle">
              <i class="fa-solid fa-table-list"></i>
            </h2>
            <h2>Existing Plans</h2>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={styles.pricesContainer}>
        {icePricing.length > 0 ? (
          <div className={styles.priceCard}>
            <PriceCard givenData={icePricing} userData={userData} label="Ice" />
          </div>
        ) : (
          ""
        )}
        {waterPricing.length > 0 ? (
          <div className={styles.priceCard}>
            <PriceCard
              givenData={waterPricing}
              userData={userData}
              label="Water"
            />
          </div>
        ) : (
          ""
        )}
        {alkalinePricing.length > 0 ? (
          <div className={styles.priceCard}>
            <PriceCard
              givenData={alkalinePricing}
              userData={userData}
              label="Alkaline"
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <ToastContainer />
    </div>
  );
};
