import styles from "../../../styles/Home.module.css";
import hero from "../../../asset/hero.svg";
import test1 from "../../images/testImage1.png";
import test2 from "../../images/testImage2.png";
import test3 from "../../images/testImage3.png";
import { useNavigate } from "react-router-dom";

const HomeHero = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.heroContainer}>
        <div className={styles.heroPic}>
          <img src={hero} alt="" />
        </div>
        <div className={styles.heroTextContainer}>
          <div className={styles.heroText1}>We help you</div>
          <div className={styles.heroTitleContainer}>
            <div className={styles.heroTitle}>Manage</div>
            <div className={styles.heroTitle}>Prepaid Balance</div>
            <div className={styles.heroTitle}>Transactions</div>
          </div>
          <div className={styles.heroText2}>
            Visualize your business growth with our customer's prepaid balance
            management software designed exclusively for water and Ice stores.
            We connect your customers and products with ease, unlocking the full
            potential of your store!
          </div>
          <div className={styles.heroFooter}>
            <div
              className={styles.buttonHomePage}
              onClick={() => navigate("/login")}
            >
              Try for Free
            </div>
          </div>
        </div>
      </div>
      <div className={styles.screenshotContainer}>
        <div class="relative">
          <div className={styles.screenshot1}>
            <img src={test1} alt="" />
          </div>
          <div className={styles.screenshot2}>
            <img src={test2} alt="" />
          </div>
          <div className={styles.screenshot3}>
            <img src={test3} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeHero;
