import React from 'react';
import FeatureInsights from './common/feature/FeatureInsights';
import FeatureElevated from './common/feature/FeatureElevated';
import FeatureSimple from './common/feature/FeatureSimple';
import FeatureEffortless from './common/feature/FeatureEffortless';
import FeatureMulti from './common/feature/FeatureMulti';
import FeatureCustomizable from './common/feature/FeatureCustomizable';
import HomeFooter from './common/hompage/HomeFooter';
import styles from "../styles/Feature.module.css";

export const Feature = () => {
  return (
    <div className={styles.feature}>
      <div className={styles.blueBg}>
        <div className={styles.flexTitle}>
          <h1>Feature Details</h1>
        </div>
      </div>
      <FeatureInsights/>
      <FeatureElevated/>
      <FeatureSimple/>
      <FeatureEffortless/>
      <FeatureMulti/>
      <FeatureCustomizable/>
      <HomeFooter/>
    </div>
  );
}