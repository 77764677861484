import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

const withAuth = (Component) => {
  const WithAuth = (props) => {
    const [authState, setAuthState] = useState(null);

    useEffect(() => {
      const checkAuthState = async () => {
        try {
          await Auth.currentAuthenticatedUser();
          setAuthState('signedin');
        } catch (error) {
          setAuthState('signedout');
        }
      };

      checkAuthState();
    }, []);

    return <Component {...props} authState={authState} />;
  };

  return WithAuth;
};

export default withAuth;