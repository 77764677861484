import feature1 from "../../images/feature/feature1.png";
import feature2 from "../../images/feature/feature2.png";
import feature3 from "../../images/feature/feature3.png";
import feature4 from "../../images/feature/feature4.png";
import feature5 from "../../images/feature/feature5.png";
import FeatureInsightCard from "./FeatureInsightCard";
import styles from "../../../styles/Feature.module.css";

const FeatureInsights = () => {
  const insights = [
    {
      title: "Sales Performance",
      desc: "Keep track of your total sales over a selected time period with our intuitive line or bar graph display, allowing you to better understand your business performance and make data-driven decisions to grow your revenue.",
      img: feature2,
    },
    {
      title: "Product Sales Breakdown",
      desc: "Get a detailed breakdown of your sales based on your product types with our visually appealing graphs, allowing you to quickly identify your top-performing products and adjust your strategy accordingly.",
      img: feature3,
    },
    {
      title: "Customer Retention",
      desc: "Understand your customer retention rates by comparing the number of returning customers to all registered customers with an outstanding product balance.",
      img: feature4,
    },
    {
      title: "Visitor Metrics",
      desc: "Track the number of customers that visited your store over a selected time period with our easy-to-read line or bar graphs.",
      img: feature5,
    },
  ];

  return (
    <div className={styles.bg}>
      {/* first section */}
      <div className={styles.flexContainer}>
        <div>
          <div className={styles.title}>
            <h2 class="featureCardIcon">
              <i class="fa-solid fa-chart-column"></i>
            </h2>
            <h2>Insights and Reports</h2>
          </div>
          <div className={styles.textContainerBig}>
            Unlock the power of your sales data with Hydrocard - the must-have
            tool for water and ice business owners. Our intuitive and visually
            appealing dashboard provides valuable insights into your customers'
            actions, allowing you to optimize your business strategy and drive
            growth. With Hydrocard, you can effortlessly collect and analyze
            customer data to make informed decisions that benefit your business.
            Our visually appealing graphs and charts help you understand key
            metrics such as product usage and sales performance. Here are just a
            few of the valuable reports you can access with our dashboard:
          </div>
        </div>
        <div className={styles.sectionMainImg}>
          <img src={feature1} alt="" />
        </div>
      </div>
      {/* second section */}
      <div className={styles.flexContainer}>
        <FeatureInsightCard
          title={insights[0].title}
          desc={insights[0].desc}
          img={insights[0].img}
        />
        <FeatureInsightCard
          title={insights[1].title}
          desc={insights[1].desc}
          img={insights[1].img}
        />
        <FeatureInsightCard
          title={insights[2].title}
          desc={insights[2].desc}
          img={insights[2].img}
        />
        <FeatureInsightCard
          title={insights[3].title}
          desc={insights[3].desc}
          img={insights[3].img}
        />
      </div>
    </div>
  );
};

export default FeatureInsights;
