import feature6 from "../../images/feature/feature6.png";
import feature7and8 from "../../images/feature/feature7and8.png";
// import feature7 from "../../images/feature/feature7.png";
// import feature8 from "../../images/feature/feature8.png";
import styles from "../../../styles/Feature.module.css";

const FeatureSimple = () => {
  return (
    <div className={styles.bg}>
      <div className={styles.flexContainer}>
        <div>
          <div className={styles.title}>
            <h2 class="featureCardIcon">
              <i class="fa-solid fa-list-check"></i>
            </h2>
            <h2>Simple Balance Management</h2>
          </div>
          <div className={styles.textContainerBig}>
            <div>
              With Hydrocard, managing customer balances has never been easier.{" "}
            </div>
            <div className={styles.secondaryTitle}>
              <h3>Customer Table:</h3>
            </div>
            <div>
              See a complete list of your customers in a table that's both
              searchable and filterable.
            </div>
            <div className={styles.checkIcon}>
              <i class="fa-solid fa-check"></i>
              <div>
                Search for finding customer easily and quickly with sort and
                filter within few seconds.
              </div>
            </div>
            <div className={styles.checkIcon}>
              <i class="fa-solid fa-check"></i>
              <div>
                Fast and Smooth transaction actions available upon search
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sectionMainImg}>
          <img src={feature6} alt="" />
        </div>
      </div>
      <div className={styles.flexContainer}>
        <div>
          <div className={styles.textContainerBig}>
            After customer has been chosen, it requires only a few clicks to
            initiate transactions. This expedited process is complemented by the
            assurance of security through the implementation of PIN
            authorization (optional). Additional details regarding the
            Multi-user mode can be found for further clarification. Each
            transaction is systematically recorded, facilitating a comprehensive
            visualization of your business operations. With this in mind, your
            actions can be executed efficiently and reliably. Our platform
            boasts a user-friendly interface optimized for mobile usage,
            allowing for seamless business management regardless of location or
            device.
          </div>
          <div className={styles.secondaryTitle}>
            <h3>Types of actions</h3>
          </div>
          <div className={styles.checkIcon}>
            <i class="fa-solid fa-check"></i>
            <div>Edit customers information on the spot</div>
          </div>
          <div className={styles.checkIcon}>
            <i class="fa-solid fa-check"></i>
            <div>Recharge or deduct balance quickly and efficiently</div>
          </div>
          <div className={styles.checkIcon}>
            <i class="fa-solid fa-check"></i>
            <div>Accident preventing action design</div>
          </div>
          <div className={styles.checkIcon}>
            <i class="fa-solid fa-check"></i>
            <div>Keep Track of Every Transaction</div>
          </div>
          <div className={styles.checkIcon}>
            <i class="fa-solid fa-check"></i>
            <div>Cross-platform Compatibility across all web devices</div>
          </div>
        </div>
        {/* <div className={styles.stackedImgs}>
          <div>
            <img src={feature8} alt="" />
          </div>
          <div className={styles.stackingImg}>
            <img src={feature7} alt="" />
          </div>
        </div> */}
        <div className={styles.sectionMainImg}>
          <img src={feature7and8} alt="" />
        </div>
      </div>
    </div>
  );
};

export default FeatureSimple;
