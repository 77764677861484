import styles from "../../../styles/Feature.module.css";

const FeatureInsightCard = ({ title, desc, img }) => {
  return (
    <div className={styles.flexContainerAligned}>
      <div className={styles.sectionExtra}>
        <h3>{title}</h3>
        <div className={styles.textContainer}>{desc}</div>
      </div>
      <div className={styles.sectionExtra}>
        <img src={img} alt="" />
      </div>
    </div>
  );
};

export default FeatureInsightCard;
